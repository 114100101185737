import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { getUserData } from "src/app/selectors";
import IndividualKPIDialog from "@icarius-pages/kpiIndicators/components/individualKPIDialog";

const gridStyle = { height: 75, padding: "0px 10px" };

const QueriesDialog = (props) => {

  const { open, loading, employeeCode, queries, kpiData, handleClose } = props;
  const [kpiSelected, setKpiSelected] = useState(null);
  const [querySelected, setQuerySelected] = useState('');
  const [kpiDialogIsOpen, setKpiDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(getUserData);

  const submit = () => {
    if (!querySelected) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar una consulta', severity: "error", duration: 10000 }));
      return;
    }

    const query = queries.find(item => item.code === querySelected);

    switch (query.type) {
      case 'query':
        handleClose();
        history.push({
          pathname: userData.level !== "E" ? paths.myQueries : paths.queries,
          search: `?group=${query.group}&queryCode=${query.code}&employeeCode=${employeeCode}`,
        });
        break;
      case 'geoquery':
        handleClose();
        history.push({
          pathname: paths.geoanalysis,
          search: `?group=${query.group}&queryCode=${query.code}&employeeCode=${employeeCode}`,
        });
        break;
      case 'kpi':
        const kpi = kpiData[query.group].find(item => item.code === query.code);
        if (!kpi) {
          dispatch(openSnackbarAction({ msg: 'No se obtuvieron datos', severity: "warning", duration: 10000 }));
          break;
        }

        setKpiDialogIsOpen(true);
        setKpiSelected(kpi);
        break;
      default:
        break;
    }
  }

  const handleCloseKpiDialog = () => {
    setKpiSelected(null);
    setKpiDialogIsOpen(false);
  }

  if (loading) return null;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {"Consultas"}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent>
            <Grid container>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-query`}>{"Consulta"}</InputLabel>
                  <Select
                    value={querySelected}
                    labelId={`label-query`}
                    id={`select-query`}
                    onChange={(e) => setQuerySelected(e.target.value)}
                    margin={"none"}
                  >
                    {
                      queries.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.code}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
            <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
          </DialogActions>
        </div>
      </Dialog>
      {
        kpiDialogIsOpen &&
        <IndividualKPIDialog
          open={kpiDialogIsOpen}
          data={kpiSelected}
          employeeCode={employeeCode}
          handleClose={handleCloseKpiDialog}
        />
      }
    </>
  );
}

export default QueriesDialog;
