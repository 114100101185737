import React from "react";
import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { likeTypes } from "@icarius-utils/constants";
import {
  getMyPeopleAPI,
  likeEmployeeAPI,
  getTemplatesAPI,
  createTemplateAPI,
  deleteTemplateAPI,
  updateTemplateAPI,
  getUserLocationDataAPI,
  updateEmployeeAPI,
  createEmployeeAPI,
  getEmployeeReceiptsAPI,
  getProvinces,
  getCounties,
  getRegions,
  uploadProfileImageAPI,
  getManagementDivisionsAPI,
  getDepartmentsByManagementAPI,
  getSectionsByDepartmentAPI,
  sendMessageAPI,
  getEmployeeEditableDataAPI,
  massiveUploadMyPeoplePhotosApi,
  massiveSalaryChangeApi,
  massiveTerminationApi,
  massiveWorkingDayApi,
  massiveContractApi,
  massivePayrollChangeApi,
  massiveTurnChangeApi,
  massiveMarkingSystemChangeApi,
  massiveRehiringChangeApi,
  updateIndividualPayrollChangeApi,
  getIndividualPayrollChangeApi,
  uploadDistributionCostExcelApi,
  downloadDistributionCostExcelApi,
  uploadFamilyGroupExcelApi,
  downloadFamilyGroupExcelApi,
  getQueriesGivenLevelApi,
  massiveStructureChangeApi,
  massiveWorkOrChoreChangeApi,
  massivePositionChangeApi,
  massivePositionRecomposeChangeApi,
  employeeCodeChangeApi,
  massiveCostCenterApi,
  getMassiveOnboardingAssignmentDataAPI,
  massiveOnboardingAssignmentAPI,
} from "@icarius-connection/api";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { getOpinion } from "@icarius-utils/like";
import { openDialogAction } from "@icarius-common/dialog/actions";

const errorHandler = (status, error, dispatch) => {
  let errorString = "";
  switch (status) {
    case "VALIDATION_ERROR":
      if (error === "INVALID_CODES_TO_ROLE") {
        errorString = getLocalizedString("filesMustStartWithCode");
      }
      if (error === "INVALID_EXTENSION") {
        errorString = getLocalizedString("filesMustBePdf");
      }
      break;
    default:
      errorString = getLocalizedErrorString("defaultException");
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: null }));
}

export const getMyPeopleAction = (isEmployer) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PEOPLE_ROWS });
  try {
    let response = await getMyPeopleAPI(`?editable=${isEmployer}`);
    let employees = response.data && response.data.colaborators;
    let sectionsToHide = response.data && response.data.sectionsToHide;
    let fieldsToHide = response.data && response.data.fieldsToHide;
    let assumeEntryDate = response.data && response.data.assumeEntryDate;
    let dateFormat = response.data.date_format;
    let locale = response.data.currency_localization;
    let editableFields = response.data.editable_fields;
    let additionalValidationData = response.data.additional_validation_data;
    let payrollList = response.data.payrollList;
    let managementsList = response.data.managementsList;

    dispatch({
      type: actionTypes.GET_PEOPLE_ROWS_FULFILLED,
      payload: {
        sectionsToHide,
        fieldsToHide,
        assumeEntryDate,
        editableFields,
        locale,
        dateFormat,
        additionalValidationData,
        payrollList,
        managementsList,
        employees: employees.map((employee) => ({
          ...employee,
          OPINION: getOpinion(employee),
        })),
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_PEOPLE_ROWS_REJECTED, payload: e });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getLocationFromServer = employeeCode => async (dispatch) => {
  dispatch({ type: actionTypes.GET_LOCATION });
  try {
    let response = await getUserLocationDataAPI(`?user=${employeeCode}`);

    let counties = response.data && response.data.result.counties;
    let provinces = response.data && response.data.result.provinces;
    let regions = response.data && response.data.result.regions;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_LOCATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_LOCATION_FULFILLED,
      payload: {
        counties: counties,
        provinces: provinces,
        regions: regions,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_LOCATION_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const likeEmployeeAction = (id, like, oldValue) => async (dispatch) => {
  try {
    let response = await likeEmployeeAPI({
      colaboratorCode: id,
      likeOrDislike: oldValue ? likeTypes.unset : like,
    });

    dispatch({
      type: actionTypes.LIKE_REQUEST,
      payload: { id, like }
    });
    dispatch({ type: actionTypes.LIKE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.LIKE_REQUEST_END });
  }
};

export const getTemplateRows = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TEMPLATES });
  try {
    let response = await getTemplatesAPI("");
    dispatch({
      type: actionTypes.GET_TEMPLATES_FULFILLED,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_TEMPLATES_REJECTED, payload: e });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createNewTemplate = (rol, nombre, estados) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_TEMPLATE_REQUEST });
  try {
    let response = await createTemplateAPI({
      U_funcion: "Mi PERSONAL",
      U_template: nombre,
      U_rol: rol,
      U_columnstate: estados.column,
      U_groupstate: estados.group,
      U_filterstate: estados.filter,
      U_pivotstate: estados.pivot,
      U_selstate: estados.showCurrentEmployees,
      U_graphicstate: estados.chart,
      overwrite: false
    });
    if (response.data && (response.data.status === "OK" || response.data.status !== "ERROR_DUPLICADO")) {
      dispatch({ type: actionTypes.ADD_TEMPLATE, payload: response.data.result });
    }

    dispatch({ type: actionTypes.CREATE_TEMPLATE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_TEMPLATE_REQUEST_END });
  }
};

export const createNewTemplateWithJSON = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_TEMPLATE_REQUEST });
  try {
    let response = await createTemplateAPI({
      U_funcion: "Mi PERSONAL",
      U_template: data["U_template"],
      U_rol: data["U_rol"],
      U_columnstate: data["U_columnstate"] !== "" ? JSON.parse(data["U_columnstate"]) : "",
      U_groupstate: data["U_groupstate"] !== "" ? JSON.parse(data["U_groupstate"]) : "",
      U_filterstate: data["U_filterstate"] !== "" ? JSON.parse(data["U_filterstate"]) : "",
      U_pivotstate: data["U_pivotstate"],
      U_selstate: data["U_selstate"],
      U_graphicstate: data["U_graphicstate"] !== "" ? JSON.parse(data["U_graphicstate"]) : "",
      overwrite: true
    });
    if (response.data && (response.data.status === "OK" || response.data.status !== "ERROR_DUPLICADO")) {
      dispatch({ type: actionTypes.ADD_TEMPLATE, payload: response.data.result });
    }

    dispatch({ type: actionTypes.CREATE_TEMPLATE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_TEMPLATE_REQUEST_END });
    dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
  }
};

export const deleteATemplate = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_TEMPLATE_REQUEST });
  try {
    let response = await deleteTemplateAPI({
      code: code,
    });

    dispatch({ type: actionTypes.DELETE_TEMPLATE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_TEMPLATE_REQUEST_END });
  }
};

export const eliminarTemplate = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.REMOVE_TEMPLATE, payload: code });
};

export const createUpdateTemplate = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_TEMPLATE_REQUEST });
  try {
    let response = await updateTemplateAPI(data);

    if (response.data && response.data.status === "OK") {
      dispatch({ type: actionTypes.UPDATE_TEMPLATE, payload: data });
    }
    dispatch({ type: actionTypes.UPDATE_TEMPLATE_REQUEST_END });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_TEMPLATE_REQUEST_END });
  }
};

///////////////EMPLOYEE MASTER

export const updateEmployeeInServer = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_EMPLOYEE });
  try {
    let response = await updateEmployeeAPI(data);
    let status = response.data && response.data.status;
    let employeeReturn = response.data.result;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_EMPLOYEE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: getLocalizedString("updateOK"), severity: "success" }));
    dispatch({
      type: actionTypes.UPDATE_EMPLOYEE_FULFILLED,
      payload: {
        employee: {
          ...employeeReturn,
          OPINION: getOpinion(employeeReturn),
        }
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_EMPLOYEE_REJECTED });
    if (e?.response?.data?.status === 'DUPLICATED_INTERNAL_NUMBER') {
      const nextInternalNumber = e?.response?.data?.data.nextInternalNumber;
      dispatch(openSnackbarAction({ msg: `El Número Interno ya está siendo utilizado, por favor asigne otro o déjelo vacío. El próximo disponible para utilizar es ${nextInternalNumber}`, severity: "error" }));
      return e?.response;
    }
    if (e?.response?.data?.status === 'IN_USE_MAIL') {
      dispatch(openSnackbarAction({ msg: 'El email personal ingresado ya existe para otro colaborador, debe cambiarlo', severity: "error" }));
      return;
    }
    if (e?.response?.data?.status === 'IN_USE_SOCIETY_MAIL') {
      dispatch(openSnackbarAction({ msg: 'El email corporativo ingresado ya existe para otro colaborador, debe cambiarlo', severity: "error" }));
      return;
    }
    if (e?.response?.data?.status === 'NO_POSITIONS') {
      dispatch(openSnackbarAction({ msg: 'No es posible asignar el Cargo ingresado porque no tiene puestos libres', severity: "error" }));
      return e?.response;
    }
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e?.response;
  }
};

export const createEmployeeInServer = (employee) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_EMPLOYEE });
  try {
    let response = await createEmployeeAPI(employee);
    let status = response.data && response.data.status;
    let employeeReturn = response.data.result;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_EMPLOYEE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: getLocalizedString("createdOK"), severity: "success" }));
    dispatch({
      type: actionTypes.CREATE_EMPLOYEE_FULFILLED,
      payload: {
        employee: {
          ...employeeReturn,
          OPINION: getOpinion(employeeReturn),
        }
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_EMPLOYEE_REJECTED });
    if (e?.response?.data?.status === 'IN_USE_MAIL') {
      dispatch(openSnackbarAction({ msg: 'El email personal ingresado ya existe para otro colaborador, debe cambiarlo', severity: "error" }));
      return;
    }
    if (e?.response?.data?.status === 'IN_USE_SOCIETY_MAIL') {
      dispatch(openSnackbarAction({ msg: 'El email corporativo ingresado ya existe para otro colaborador, debe cambiarlo', severity: "error" }));
      return;
    }
    if (e?.response?.data?.status === 'DUPLICATED_CODE') {
      dispatch(openSnackbarAction({ msg: 'El código de empleado ya existe, no es posible crear al nuevo empleado con ese código', severity: "error" }));
      return;
    }
    if (e?.response?.data?.status === 'DUPLICATED_INTERNAL_NUMBER') {
      const nextInternalNumber = e?.response?.data?.data.nextInternalNumber;
      dispatch(openSnackbarAction({ msg: `El Número Interno ya está siendo utilizado, por favor asigne otro o déjelo vacío. El próximo disponible para utilizar es ${nextInternalNumber}`, severity: "error" }));
      return;
    }
    if (e?.response?.data?.status === 'NO_POSITIONS') {
      dispatch(openSnackbarAction({ msg: 'No es posible asignar el Cargo ingresado porque no tiene puestos libres', severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getAvailableReceiptsForEmployee = (employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_RECEIPTS_EMPLOYEE });
  try {
    let response = await getEmployeeReceiptsAPI(`?code=${employeeCode}`);

    let receipts = response.data && response.data.receipts;
    let calculation_processes = response.data && response.data.calculation_processes;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_RECEIPTS_EMPLOYEE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_RECEIPTS_EMPLOYEE_FULFILLED,
      payload: {
        receipts: receipts,
        calculation_processes: calculation_processes,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_RECEIPTS_EMPLOYEE_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

//para el formulario, dependiendo del pais
export const getRegionByCountry = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_REGIONS_BY_COUNTRY });
  try {
    let query = `?id=${code}`;
    let response = await getRegions(query);

    dispatch({
      type: actionTypes.GET_REGIONS_BY_COUNTRY_FULFILLED,
      payload: response.data.result,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REGIONS_BY_COUNTRY_REJECTED });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
export const getProvinceByRegion = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PROVINCES_BY_REGION });
  try {
    let query = `?id=${code}`;
    let response = await getProvinces(query);
    dispatch({
      type: actionTypes.GET_PROVINCES_BY_REGION_FULFILLED,
      payload: response.data.result,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_PROVINCES_BY_REGION_REJECTED });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
export const getCountiesByProvince = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COUNTIES_BY_PROVINCE });
  try {
    let query = `?id=${code}`;
    let response = await getCounties(query);
    dispatch({
      type: actionTypes.GET_COUNTIES_BY_PROVINCE_FULFILLED,
      payload: response.data.result,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COUNTIES_BY_PROVINCE_REJECTED });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const uploadProfileImageAction = (data, usercode) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY_PHOTO });
  try {
    let response = await uploadProfileImageAPI({ image: data, usercode });

    dispatch({ type: actionTypes.MODIFY_PHOTO_FULFILLED, payload: response.data });
    dispatch(
      openSnackbarAction({
        msg: getLocalizedString("correctlyModifiedPhoto"),
        severity: "success",
      })
    );

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_PHOTO_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getManagementDivisionsFromServer = (employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MANAGEMENT_DIVISION });
  try {
    let response = await getManagementDivisionsAPI(`?user=${employeeCode}`);

    let departments = response.data && response.data.result.departments;
    let sections = response.data && response.data.result.sections;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MANAGEMENT_DIVISION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MANAGEMENT_DIVISION_FULFILLED,
      payload: {
        departments: departments,
        sections: sections,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MANAGEMENT_DIVISION_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getDepartmentsByManagement = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DEPARTMENTS_BY_MANAGEMENT });
  try {
    let query = `?id=${code}`;
    let response = await getDepartmentsByManagementAPI(query);

    dispatch({
      type: actionTypes.GET_DEPARTMENTS_BY_MANAGEMENT_FULFILLED,
      payload: response.data.result,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DEPARTMENTS_BY_MANAGEMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getSectionsByDepartment = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SECTIONS_BY_DEPARTMENT });
  try {
    let query = `?id=${code}`;
    let response = await getSectionsByDepartmentAPI(query);
    dispatch({
      type: actionTypes.GET_SECTIONS_BY_DEPARTMENT_FULFILLED,
      payload: response.data.result,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_SECTIONS_BY_DEPARTMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const sendMailAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_MAIL });
  try {
    let response = await sendMessageAPI(data);

    dispatch({ type: actionTypes.SEND_MAIL_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("mailMyPeopleSent"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEND_MAIL_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getEmployeeDataAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_EMPLOYEE_DATA });
  try {
    let response = await getEmployeeEditableDataAPI(`?code=${code}`);
    let colaborator = response.data && response.data.colaborator;

    colaborator = {
      ...colaborator,
      OPINION: getOpinion(colaborator),
    }

    dispatch({
      type: actionTypes.GET_EMPLOYEE_DATA_FULFILLED,
      payload: { colaborator },
    });

    dispatch({
      type: actionTypes.SET_EMPLOYEE_OPINION,
      payload: { opinion: colaborator.OPINION },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_EMPLOYEE_DATA_REJECTED, payload: e });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const massiveUploadMyPeoplePhotosAction = (files) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let formData = new FormData();
    files.forEach(file => {
      formData.append('FILES', file);
    })

    let response = await massiveUploadMyPeoplePhotosApi(formData);

    let status = response?.data?.status;
    let error = response?.data?.error;

    if (status !== "OK") {
      errorHandler(status, error, dispatch);
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      return;
    }

    dispatch({ type: actionTypes.MASSIVE_CHANGE_FULFILLED });
    return response;
  } catch (e) {
    if (e.response.data.error === "INVALID_CODES") {
      dispatch(openSnackbarAction({ msg: 'Hay archivos con código incorrecto', severity: "error" }));
      dispatch({
        type: actionTypes.MASSIVE_CHANGE_REJECTED,
      });
      return e.response;
    }

    if (e.response.data.error === "INVALID_EXTENSION") {
      dispatch(openSnackbarAction({ msg: 'Hay archivos con extensión incorrecta', severity: "error" }));
      dispatch({
        type: actionTypes.MASSIVE_CHANGE_REJECTED,
      });
      return e.response;
    }

    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    return e.response;
  }
};

export const massiveSalaryChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveSalaryChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: 'La modificación masiva de Sueldos o salarios ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massiveTerminationAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveTerminationApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'Las desvinculaciones masivas ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    return e.response;
  }
};

export const massiveWorkingDayAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveWorkingDayApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'Los datos de la jornada laboral han sido modificados con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massiveContractAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveContractApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'Los datos contractuales han sido modificados con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massivePayrollChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massivePayrollChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: 'La habilitación de nóminas masiva ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massiveTurnChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveTurnChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: 'El cambio de turnos masivo ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massiveMarkingSystemChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveMarkingSystemChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: 'El cambio del sistema de marcaje ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massiveRehiringChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveRehiringChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch(openSnackbarAction({ msg: 'La recontratación masiva ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const updateIndividualPayrollChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await updateIndividualPayrollChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: 'La habilitación de nóminas ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const getIndividualPayrollChangeAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await getIndividualPayrollChangeApi(`?code=${code}`);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};


export const downloadStructureAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadDistributionCostExcelApi({ empty: false });
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importSpreadsheetAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD });
  try {
    let formData = new FormData();
    formData.append('file', dataToSend.file);

    let response = await uploadDistributionCostExcelApi(formData);
    let title = response.headers["x-content-namefile"];
    let hasErrors = title && title.includes("Errores-");
    if (title) {
      title = title.replace("Errores-", "");
    }
    if (response.status !== 200) {
      dispatch({ type: actionTypes.UPLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_FULFILLED,
    });

    if (title) {
      downloadBlob(response.data, getFileExtension(title), getFileName(title));
      dispatch(openSnackbarAction({
        msg: 'Importación finalizada, verifique el archivo para ver el resultado',
        severity: !hasErrors ? "success" : "warning",
        duration: null
      }));
      return response;
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadFamilyGroupAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_FAMILY_GROUP });
  try {
    let response = await downloadFamilyGroupExcelApi({ empty: false });
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_FAMILY_GROUP_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FAMILY_GROUP_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_FAMILY_GROUP_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importFamilyGroupSpreadsheetAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_FAMILY_GROUP });
  try {
    let formData = new FormData();
    formData.append('file', dataToSend.file);

    let response = await uploadFamilyGroupExcelApi(formData);
    let title = response.headers["x-content-namefile"];
    let hasErrors = title && title.includes("Errores-");
    if (title) {
      title = title.replace("Errores-", "");
    }
    if (response.status !== 200) {
      dispatch({ type: actionTypes.UPLOAD_FAMILY_GROUP_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_FAMILY_GROUP_FULFILLED,
    });

    if (title) {
      downloadBlob(response.data, getFileExtension(title), getFileName(title));
      dispatch(openSnackbarAction({
        msg: 'Importación finalizada, verifique el archivo para ver el resultado',
        severity: !hasErrors ? "success" : "warning",
        duration: null
      }));
      return response;
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_FAMILY_GROUP_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getQueriesGivenLevelAction = (code, path) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUERIES });
  try {
    let response = await getQueriesGivenLevelApi(`?code=${code}&path=${path}`);
    let data = response.data && response.data.data;
    let kpiData = response.data && response.data.kpi;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_QUERIES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_QUERIES_FULFILLED,
      payload: { data, kpiData }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_QUERIES_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massiveStructureChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveStructureChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: 'El cambio masivo de estructura departamental ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massiveWorkOrChoreChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveWorkOrChoreChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'La obra o faena ha sido modificada con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const massivePositionChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massivePositionChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'El cargo ha sido modificado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    if (e.response.status !== 422) {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    return e.response;
  }
};

export const massivePositionRecomposeChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massivePositionRecomposeChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'El cargo ha sido modificado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const employeeCodeChangeAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await employeeCodeChangeApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'El código de empleado ha sido modificado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    if (e?.response?.data?.status === 'DUPLICATED_CODE') {
      dispatch(openSnackbarAction({ msg: 'El código de empleado ya existe, no es posible actualizar al empleado con ese código', severity: "error" }));
    } else if (e?.response?.data?.status === 'DUPLICATED_INTERNAL_NUMBER') {
      dispatch(openSnackbarAction({ msg: `El Número Interno ya está siendo utilizado, por favor asigne otro.`, severity: "error" }));
    } else if (e?.response?.data?.status === 'NO_POSITIONS') {
      dispatch(openSnackbarAction({ msg: 'No es posible asignar el Cargo ingresado porque no tiene puestos libres', severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    return e.response;
  }
};

export const massiveCostCenterAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveCostCenterApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'La actualización masiva de los centros de costo ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    return e.response;
  }
};

export const getMassiveOnboardingAssignmentDataAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ONBOARDING_ASSIGNMENT });
  try {
    let response = await getMassiveOnboardingAssignmentDataAPI();
    let onboardingProcesses = response.data && response.data.onboardingProcesses;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_ONBOARDING_ASSIGNMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }

    dispatch({
      type: actionTypes.GET_ONBOARDING_ASSIGNMENT_FULFILLED,
      payload: { onboardingProcesses }
    });

    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: actionTypes.GET_ONBOARDING_ASSIGNMENT_REJECTED });
    return e.response;
  }
};

export const massiveOnboardingAssignmentAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MASSIVE_CHANGE });
  try {
    let response = await massiveOnboardingAssignmentAPI(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response;
    }
    dispatch(openSnackbarAction({ msg: 'La asignación masiva al proceso de onboarding ha finalizado con éxito', severity: "success" }));
    dispatch({
      type: actionTypes.MASSIVE_CHANGE_FULFILLED
    });

    return response;
  } catch (e) {
    if (e.response?.data?.status === "IN_USE_ONBOARDING") {
      dispatch(openDialogAction({
        maxWidth: "md",
        fullWidth: true,
        acceptOnly: true,
        title: "Atención",
        msg: (
          <>
            La asignación no se ha realizado.
            <br />
            Ha seleccionado empleados que ya se encuentran en un proceso actual de onboarding.
            <br />
            Debe omitirlos de la lista seleccionada:
            <br />
            {
              e.response?.data?.users?.map((item) => {
                return (
                  <span key={item.key}>
                    {`${item.key} ${item.value} - ${item.processName}`}
                    <br />
                  </span>
                )
              })
            }
          </>
        )
      }))
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.MASSIVE_CHANGE_REJECTED });
    return e.response;
  }
};