import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stepper, Step, StepButton, useMediaQuery } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { SaveIcon } from "@icarius-icons/index";
import {
  getCounties,
  getRegions,
  getProvinces,
  getEditableFields,
  getDepartments,
  getSections,
  getSectionsToHide,
  getFieldsToHide,
  getAssumeEntryDate,
} from "@icarius-pages/myPeople/selectors";
import { myPeopleAndPeopleMasterFields } from "@icarius-pages/myPeople/constants";
import { getCountry, getGeographicalDivisions } from "src/app/selectors";
import { getRegionByCountry } from "@icarius-pages/myPeople/actions";
import useHandleStepper from "@icarius-utils/hooks/useHandleStepper";
import { MD_DEVICE_WIDTH_BREAKPOINT, TABS } from "@icarius-utils/constants";
import Personal from "./steps/personal";
import Location from "./steps/location";
import Contract from "./steps/contract";
import Remuneration from "./steps/remuneration";
import Time from "./steps/time";
import SpecialClassification from "./steps/specialClassification";
import Others from "./steps/others";
import useHandleForm from "./useHandleForm";

export const getFieldsBySectionToHide = (sectionsToHide) => {
  let fieldsToHideBySection = [];

  sectionsToHide.forEach((section) => {
    fieldsToHideBySection = [
      ...fieldsToHideBySection,
      ...myPeopleAndPeopleMasterFields.filter((item) => item.tab === TABS[section]),
    ];
  });

  return fieldsToHideBySection;
}

const UserForm = (props) => {

  const {
    data,
    isDuplicate,
    handleCreateEmployee,
    handleUpdateEmployee,
  } = props;

  const dispatch = useDispatch();
  const geographicalDivisions = useSelector(getGeographicalDivisions);
  const editableFields = useSelector(getEditableFields);
  const sectionsToHide = useSelector(getSectionsToHide);
  const fieldsToHide = useSelector(getFieldsToHide);
  const assumeEntryDate = useSelector(getAssumeEntryDate);
  const counties = useSelector(getCounties);
  const provinces = useSelector(getProvinces);
  const regions = useSelector(getRegions);
  const departments = useSelector(getDepartments);
  const sections = useSelector(getSections);
  const country = useSelector(getCountry);

  const isVerticalStepper = useMediaQuery(`(max-width:${MD_DEVICE_WIDTH_BREAKPOINT - 1}px)`);

  useEffect(() => {
    if (!data) {
      dispatch(getRegionByCountry(country));
    }
  }, [dispatch, data, country])

  const myPeopleAndPeopleMasterFieldsToUse = useMemo(() => {
    let auxMyPeopleAndPeopleMasterFields = [...myPeopleAndPeopleMasterFields];

    const firstLevelField = auxMyPeopleAndPeopleMasterFields.find((item) => item.name === "REGION");
    firstLevelField.label = geographicalDivisions.firstLevel;

    const secondLevelField = auxMyPeopleAndPeopleMasterFields.find((item) => item.name === "PROVINCIA");
    secondLevelField.label = geographicalDivisions.secondLevel;

    const thirdLevelField = auxMyPeopleAndPeopleMasterFields.find((item) => item.name === "COMUNA");
    thirdLevelField.label = geographicalDivisions.thirdLevel;

    return auxMyPeopleAndPeopleMasterFields;
  }, [geographicalDivisions])

  const editableFieldsToUse = useMemo(() => {
    if (editableFields) {
      const auxEditableFields = [...editableFields];

      // agregarle las opciones de paises a PAIS (reutiliza las de nacionalidad)
      const nationalityOptionList = auxEditableFields.find((item) => item.name === "NACIONALIDAD")?.options;
      auxEditableFields.find((item) => item.name === "PAIS").options = [...nationalityOptionList]; // PAIS no tiene options, asi que no hay que validar si existe, se hardcodea

      // asociar las regiones, comunas y provincias (que se traen de fetchs dinamicos) con el editableFields
      let countyField = auxEditableFields.find((item) => item.name === "COMUNA");
      let provincesField = auxEditableFields.find((item) => item.name === "PROVINCIA");
      let regionsField = auxEditableFields.find((item) => item.name === "REGION");

      if (countyField) countyField.options = counties;
      if (provincesField) provincesField.options = provinces;
      if (regionsField) regionsField.options = regions;

      // asociar los departamentos y secciones (que se traen de fetchs dinamicos) con el editableFields
      let departmentsField = auxEditableFields.find((item) => item.name === "DEPARTAMENTO");
      let sectionsField = auxEditableFields.find((item) => item.name === "SECCION")

      if (departmentsField) departmentsField.options = departments;
      if (sectionsField) sectionsField.options = sections;

      // si es crear o duplicar que CODIGO DE EMPLEADO sea editable y obligatorio
      if (!data || isDuplicate) {
        const userCodeField = auxEditableFields.find((item) => item.name === "CODIGO DE EMPLEADO");
        userCodeField.editable = true;
        userCodeField.required = true;
      }

      // hacer que los campos de las secciones ocultas no sean required
      const sectionFields = getFieldsBySectionToHide(sectionsToHide);

      sectionFields.forEach((sectionField) => {
        const fieldToEdit = auxEditableFields.find((item) => item.name === sectionField.name);
        if (fieldToEdit) {
          fieldToEdit.required = false;
        }
      });

      return auxEditableFields;
    }

    return null;
  }, [editableFields, isDuplicate, counties, provinces, regions, departments, sections, data, sectionsToHide])

  // si no tiene la config de campos editables, es readOnly (o sea, myPeople, no peopleMaster)
  const isReadOnly = !editableFieldsToUse;

  const create = (dataToSend) => {
    handleCreateEmployee(dataToSend);
  }

  const modify = (dataToSend) => {
    handleUpdateEmployee(dataToSend);
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepIsValid,
    getIsRequired,
    fieldsWithError,
  } = useHandleForm(data, create, modify, openValidationError, editableFieldsToUse, isDuplicate, assumeEntryDate);

  const steps = [
    {
      name: 'Personales',
      tab: TABS.personal,
      render: () => {
        return (
          <Personal
            formData={formData}
            setFormValue={setFormValue}
            getIsRequired={getIsRequired}
            editableFields={editableFieldsToUse}
            fieldsToUse={myPeopleAndPeopleMasterFieldsToUse}
            fieldsWithError={fieldsWithError}
            fieldsToHide={fieldsToHide}
          />
        )
      }
    },
    {
      name: 'Ubicación y datos organizacionales',
      tab: TABS.location,
      render: () => {
        return (
          <Location
            formData={formData}
            setFormValue={setFormValue}
            getIsRequired={getIsRequired}
            editableFields={editableFieldsToUse}
            fieldsToUse={myPeopleAndPeopleMasterFieldsToUse}
            fieldsWithError={fieldsWithError}
            fieldsToHide={fieldsToHide}
          />
        )
      }
    },
    {
      name: 'Contrato',
      tab: TABS.contract,
      render: () => {
        return (
          <Contract
            formData={formData}
            setFormValue={setFormValue}
            getIsRequired={getIsRequired}
            editableFields={editableFieldsToUse}
            fieldsToUse={myPeopleAndPeopleMasterFieldsToUse}
            fieldsWithError={fieldsWithError}
            fieldsToHide={fieldsToHide}
          />
        )
      },
    },
    {
      name: 'Remuneracion',
      tab: TABS.remuneration,
      render: () => {
        return (
          <Remuneration
            formData={formData}
            setFormValue={setFormValue}
            getIsRequired={getIsRequired}
            editableFields={editableFieldsToUse}
            fieldsToUse={myPeopleAndPeopleMasterFieldsToUse}
            fieldsWithError={fieldsWithError}
            fieldsToHide={fieldsToHide}
          />
        )
      },
    },
    {
      name: 'Tiempos',
      tab: TABS.time,
      render: () => {
        return (
          <Time
            formData={formData}
            setFormValue={setFormValue}
            getIsRequired={getIsRequired}
            editableFields={editableFieldsToUse}
            fieldsToUse={myPeopleAndPeopleMasterFieldsToUse}
            fieldsWithError={fieldsWithError}
            fieldsToHide={fieldsToHide}
          />
        )
      }
    },
    {
      name: 'Clasificaciones especiales',
      tab: TABS.specialClassification,
      render: () => {
        return (
          <SpecialClassification
            formData={formData}
            setFormValue={setFormValue}
            getIsRequired={getIsRequired}
            editableFields={editableFieldsToUse}
            fieldsToUse={myPeopleAndPeopleMasterFieldsToUse}
            fieldsWithError={fieldsWithError}
            fieldsToHide={fieldsToHide}
          />
        )
      }
    },
    {
      name: 'Otros',
      tab: TABS.others,
      render: () => {
        return (
          <Others
            formData={formData}
            setFormValue={setFormValue}
            getIsRequired={getIsRequired}
            editableFields={editableFieldsToUse}
            fieldsWithError={fieldsWithError}
          />
        )
      }
    },
  ].filter((item) => {
    if (item.tab === TABS.others && !editableFieldsToUse?.some((editableField) => editableField.is_user_field)) return false;

    // en myPeople no va este
    if (isReadOnly && item.tab === TABS.specialClassification) return false;

    if (item.tab === TABS.contract && sectionsToHide.includes("contract")) return false;
    if (item.tab === TABS.remuneration && sectionsToHide.includes("remuneration")) return false;
    if (item.tab === TABS.time && sectionsToHide.includes("time")) return false;
    if (item.tab === TABS.specialClassification && sectionsToHide.includes("specialClassification")) return false;

    return true;
  })

  const scrollToStepperHeader = () => {
    const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
    const element = document.getElementById('stepperHeader');
    const posi = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: posi, behavior: 'smooth' });
  }

  const {
    currentStep,
    setCurrentStep,
    setNextStep,
    setPreviousStep,
    renderStep,
  } = useHandleStepper(steps, scrollToStepperHeader);

  const handleSubmit = () => {
    scrollToStepperHeader();
    submit();
  }

  const handleSetNextStep = () => {
    if (isReadOnly) {
      setNextStep();
      return;
    }

    const tab = steps[currentStep].tab;
    let isValid = stepIsValid(tab); // uso el tab asi si oculto un step, la validacion sigue siendo la del paso correcto
    if (isValid) {
      setNextStep();
    }
  }

  const handleSetPreviousStep = () => {
    if (isReadOnly) {
      setPreviousStep();
      return;
    }

    const tab = steps[currentStep].tab;
    let isValid = stepIsValid(tab); // uso el tab asi si oculto un step, la validacion sigue siendo la del paso correcto
    if (isValid) {
      setPreviousStep();
    }
  }

  const handleSetNonLinearStep = (index) => {
    // funcion para pasar a cualquier paso (no necesariamente el siguiente o anterior directo)
    // antes de pasar al paso que hice click, valido los datos del actual
    if (isReadOnly) {
      setCurrentStep(index);
      return;
    }

    const tab = steps[currentStep].tab;
    let isValid = stepIsValid(tab); // uso el tab asi si oculto un step, la validacion sigue siendo la del paso correcto
    if (isValid) {
      setCurrentStep(index);
    }
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12} justify="center" id="stepperHeader">
          <Stepper
            activeStep={currentStep}
            nonLinear={!isCreate && !isDuplicate}
            orientation={isVerticalStepper ? "vertical" : "horizontal"}
            alternativeLabel={!isVerticalStepper}
            style={{ width: '100%' }}
          >
            {
              steps.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepButton onClick={() => handleSetNonLinearStep(index)}>{step.name}</StepButton>
                  </Step>
                );
              })
            }
          </Stepper>
        </Grid>
        <Grid container item xs={12}>
          {renderStep()}
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="center">
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingRight: 10 }}>
          {
            (currentStep !== 0) &&
            <div style={{ marginRight: 10 }}>
              <ButtonDialogAction isAccept onClick={handleSetPreviousStep} text={'Anterior'} />
            </div>
          }
          {
            // si no es el último paso
            currentStep !== (steps.length - 1) &&
            <ButtonDialogAction isAccept onClick={handleSetNextStep} text={'Siguiente'} />
          }
          {
            // si no es readOnly, es el ultimo paso, o es el editar (se puede guardar en cualquier momento)
            (currentStep === (steps.length - 1) || (!isCreate && !isDuplicate)) && !isReadOnly &&
            <div style={{ marginLeft: 16 }}>
              <ButtonDialogAction isAccept onClick={handleSubmit} text={getLocalizedString("save")} startIcon={<SaveIcon />} />
            </div>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default UserForm;