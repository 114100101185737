import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "0px 10px" };

const FilterDialog = (props) => {

  const {
    open,
    handleClose,
    workplaceFilter,
    setWorkplaceFilter,
    turnFilter,
    setTurnFilter,
    extrasFilter,
    setExtrasFilter,
    userFilter,
    setUserFilter,
    requestFilter,
    setRequestFilter,
    scheduleFilter,
    setScheduleFilter,
    employees,
    workplaces,
    turns,
    schedules,
    openCalendars,
  } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Filtros"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container item xs={12}>            
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink id={`label-turn`}>{"Horas extra por autorizar"}</InputLabel>
                <Select
                  value={extrasFilter}
                  labelId={`label-turn`}
                  id={`select-turn`}
                  onChange={(e) => {
                    setExtrasFilter(e.target.value);
                    setRequestFilter('');
                  }}
                  margin={"none"}
                  displayEmpty
                >
                  <MenuItem className={"whiteText"} value={''}>{'Todos'}</MenuItem>
                  {
                    openCalendars?.map((item) => {
                      return (
                        <MenuItem key={item.key} className={"whiteText"} value={item.key}>{item.value}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink id={`label-turn`}>{"Solicitudes de cambio de horario"}</InputLabel>
                <Select
                  value={requestFilter}
                  labelId={`label-turn`}
                  id={`select-turn`}
                  onChange={(e) => {
                    setRequestFilter(e.target.value);
                    setExtrasFilter('');
                  }}
                  margin={"none"}
                  displayEmpty
                >
                  <MenuItem className={"whiteText"} value={''}>{'Todos'}</MenuItem>
                  {
                    openCalendars?.map((item) => {
                      return (
                        <MenuItem key={item.key} className={"whiteText"} value={item.key}>{item.value}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink id={`label-workplace`}>{"Lugar de trabajo"}</InputLabel>
                <Select
                  value={workplaceFilter}
                  labelId={`label-workplace`}
                  id={`select-workplace`}
                  onChange={(e) => setWorkplaceFilter(e.target.value)}
                  margin={"none"}
                  displayEmpty
                >
                  <MenuItem className={"whiteText"} value={''}>{'Todos'}</MenuItem>
                  {
                    workplaces.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink id={`label-turn`}>{"Turno"}</InputLabel>
                <Select
                  value={turnFilter}
                  labelId={`label-turn`}
                  id={`select-turn`}
                  onChange={(e) => setTurnFilter(e.target.value)}
                  margin={"none"}
                  displayEmpty
                >
                  <MenuItem className={"whiteText"} value={''}>{'Todos'}</MenuItem>
                  {
                    turns.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink id={`label-schedule`}>{"Horario"}</InputLabel>
                <Select
                  value={scheduleFilter}
                  labelId={`label-schedule`}
                  id={`select-schedule`}
                  onChange={(e) => setScheduleFilter(e.target.value)}
                  margin={"none"}
                  displayEmpty
                >
                  <MenuItem className={"whiteText"} value={''}>{'Todos'}</MenuItem>
                  {
                    schedules.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <Autocomplete
                style={{ width: '100%' }}
                options={employees}
                value={userFilter}
                onChange={(event, newUser) => {
                  setUserFilter(newUser);
                }}
                clearOnBlur
                clearText={getLocalizedString("clear")}
                noOptionsText={''}
                getOptionLabel={(user) => user.value}
                renderInput={(params) => <TextField {...params} label={'Colaborador'} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default FilterDialog;