import NAME from "./constants";

export const RESET_PEOPLE_ROWS = NAME + "/RESET_PEOPLE_ROWS";

export const GET_PEOPLE_ROWS = NAME + "/GET_PEOPLE_ROWS";
export const GET_PEOPLE_ROWS_FULFILLED = NAME + "/GET_PEOPLE_ROWS_FULFILLED";
export const GET_PEOPLE_ROWS_REJECTED = NAME + "/GET_PEOPLE_ROWS_REJECTED";

export const LIKE_REQUEST = NAME + "/LIKE_REQUEST";
export const LIKE_REQUEST_END = NAME + "/LIKE_REQUEST_END";

export const GET_LOCATION = NAME + "/GET_LOCATION";
export const GET_LOCATION_FULFILLED = NAME + "/GET_LOCATION_FULFILLED";
export const GET_LOCATION_REJECTED = NAME + "/GET_LOCATION_REJECTED";

export const UPDATE_EMPLOYEE = NAME + "/UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_FULFILLED = NAME + "/UPDATE_EMPLOYEE_FULFILLED";
export const UPDATE_EMPLOYEE_REJECTED = NAME + "/UPDATE_EMPLOYEE_REJECTED";

export const GET_RECEIPTS_EMPLOYEE = NAME + "/GET_RECEIPTS_EMPLOYEE";
export const GET_RECEIPTS_EMPLOYEE_FULFILLED = NAME + "/GET_RECEIPTS_EMPLOYEE_FULFILLED";
export const GET_RECEIPTS_EMPLOYEE_REJECTED = NAME + "/GET_RECEIPTS_EMPLOYEE_REJECTED";

export const CREATE_EMPLOYEE = NAME + "/CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE_FULFILLED = NAME + "/CREATE_EMPLOYEE_FULFILLED";
export const CREATE_EMPLOYEE_REJECTED = NAME + "/CREATE_EMPLOYEE_REJECTED";

export const GET_REGIONS_BY_COUNTRY = NAME + "/GET_REGIONS_BY_COUNTRY";
export const GET_REGIONS_BY_COUNTRY_REJECTED = NAME + "/GET_REGIONS_BY_COUNTRY_REJECTED";
export const GET_REGIONS_BY_COUNTRY_FULFILLED = NAME + "/GET_REGIONS_BY_COUNTRY_FULFILLED";

export const GET_PROVINCES_BY_REGION = NAME + "/GET_PROVINCES_BY_REGION";
export const GET_PROVINCES_BY_REGION_REJECTED = NAME + "/GET_PROVINCES_BY_REGION_REJECTED";
export const GET_PROVINCES_BY_REGION_FULFILLED = NAME + "/GET_PROVINCES_BY_REGION_FULFILLED";

export const GET_COUNTIES_BY_PROVINCE = NAME + "/GET_COUNTIES_BY_PROVINCE";
export const GET_COUNTIES_BY_PROVINCE_FULFILLED = NAME + "/GET_COUNTIES_BY_PROVINCE_FULFILLED";
export const GET_COUNTIES_BY_PROVINCE_REJECTED = NAME + "/GET_COUNTIES_BY_PROVINCE_REJECTED";

export const MODIFY_PHOTO = NAME + "/MODIFY_PHOTO";
export const MODIFY_PHOTO_REJECTED = NAME + "/MODIFY_PHOTO_REJECTED";
export const MODIFY_PHOTO_FULFILLED = NAME + "/MODIFY_PHOTO_FULFILLED";

export const GET_MANAGEMENT_DIVISION = NAME + "/GET_MANAGEMENT_DIVISION";
export const GET_MANAGEMENT_DIVISION_REJECTED = NAME + "/GET_MANAGEMENT_DIVISION_REJECTED";
export const GET_MANAGEMENT_DIVISION_FULFILLED = NAME + "/GET_MANAGEMENT_DIVISION_FULFILLED";

export const GET_DEPARTMENTS_BY_MANAGEMENT = NAME + "/GET_DEPARTMENTS_BY_MANAGEMENT";
export const GET_DEPARTMENTS_BY_MANAGEMENT_REJECTED = NAME + "/GET_DEPARTMENTS_BY_MANAGEMENT_REJECTED";
export const GET_DEPARTMENTS_BY_MANAGEMENT_FULFILLED = NAME + "/GET_DEPARTMENTS_BY_MANAGEMENT_FULFILLED";

export const GET_SECTIONS_BY_DEPARTMENT = NAME + "/GET_SECTIONS_BY_DEPARTMENT";
export const GET_SECTIONS_BY_DEPARTMENT_REJECTED = NAME + "/GET_SECTIONS_BY_DEPARTMENT_REJECTED";
export const GET_SECTIONS_BY_DEPARTMENT_FULFILLED = NAME + "/GET_SECTIONS_BY_DEPARTMENT_FULFILLED";

export const RESET_PHOTO = NAME + "/RESET_PHOTO";

export const SEND_MAIL = NAME + "/SEND_MAIL";
export const SEND_MAIL_REJECTED = NAME + "/SEND_MAIL_REJECTED";
export const SEND_MAIL_FULFILLED = NAME + "/SEND_MAIL_FULFILLED";

export const GET_EMPLOYEE_DATA = NAME + "/GET_EMPLOYEE_DATA";
export const GET_EMPLOYEE_DATA_FULFILLED = NAME + "/GET_EMPLOYEE_DATA_FULFILLED";
export const GET_EMPLOYEE_DATA_REJECTED = NAME + "/GET_EMPLOYEE_DATA_REJECTED";
export const SET_EMPLOYEE_OPINION = NAME + "/SET_EMPLOYEE_OPINION";
export const RESET_EMPLOYEE_DATA = NAME + "/RESET_EMPLOYEE_DATA";

export const MASSIVE_CHANGE = NAME + "/MASSIVE_CHANGE";
export const MASSIVE_CHANGE_FULFILLED = NAME + "/MASSIVE_CHANGE_FULFILLED";
export const MASSIVE_CHANGE_REJECTED = NAME + "/MASSIVE_CHANGE_REJECTED";

export const UPLOAD = NAME + "/UPLOAD_FILE";
export const UPLOAD_REJECTED = NAME + "/UPLOAD_REJECTED";
export const UPLOAD_FULFILLED = NAME + "/UPLOAD_FULFILLED";

export const DOWNLOAD = NAME + "/DOWNLOAD_FILE";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";

export const UPLOAD_FAMILY_GROUP = NAME + "/UPLOAD_FAMILY_GROUP_FILE";
export const UPLOAD_FAMILY_GROUP_REJECTED = NAME + "/UPLOAD_FAMILY_GROUP_REJECTED";
export const UPLOAD_FAMILY_GROUP_FULFILLED = NAME + "/UPLOAD_FAMILY_GROUP_FULFILLED";

export const DOWNLOAD_FAMILY_GROUP = NAME + "/DOWNLOAD_FAMILY_GROUP_FILE";
export const DOWNLOAD_FAMILY_GROUP_REJECTED = NAME + "/DOWNLOAD_FAMILY_GROUP_REJECTED";
export const DOWNLOAD_FAMILY_GROUP_FULFILLED = NAME + "/DOWNLOAD_FAMILY_GROUP_FULFILLED";

export const GET_QUERIES = NAME + "/GET_QUERIES";
export const GET_QUERIES_REJECTED = NAME + "/GET_QUERIES_REJECTED";
export const GET_QUERIES_FULFILLED = NAME + "/GET_QUERIES_FULFILLED";

export const GET_ONBOARDING_ASSIGNMENT = NAME + "/GET_ONBOARDING_ASSIGNMENT";
export const GET_ONBOARDING_ASSIGNMENT_REJECTED = NAME + "/GET_ONBOARDING_ASSIGNMENT_REJECTED";
export const GET_ONBOARDING_ASSIGNMENT_FULFILLED = NAME + "/GET_ONBOARDING_ASSIGNMENT_FULFILLED";

// templates
export const CREATE_TEMPLATE_REQUEST = NAME + "/CREATE_TEMPLATE_REQUEST";
export const CREATE_TEMPLATE_REQUEST_END = NAME + "/CREATE_TEMPLATE_REQUEST_END";

export const GET_TEMPLATES = NAME + "/GET_TEMPLATES";
export const GET_TEMPLATES_FULFILLED = NAME + "/GET_TEMPLATES_FULFILLED";
export const GET_TEMPLATES_REJECTED = NAME + "/GET_TEMPLATES_REJECTED";

export const DELETE_TEMPLATE_REQUEST = NAME + "/DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_REQUEST_END = NAME + "/DELETE_TEMPLATE_REQUEST_END";

export const UPDATE_TEMPLATE_REQUEST = NAME + "/UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_REQUEST_END = NAME + "/UPDATE_TEMPLATE_REQUEST_END";

export const ADD_TEMPLATE = NAME + "/ADD_TEMPLATE";
export const REMOVE_TEMPLATE = NAME + "/REMOVE_TEMPLATE";
export const UPDATE_TEMPLATE = NAME + "/UPDATE_TEMPLATE";
