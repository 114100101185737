import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveTurnChangeAction } from "@icarius-pages/myPeople/actions";
import { getEditableFields } from "../../selectors";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { formatDateYYYYMMDD } from "@icarius-utils/date";

const gridStyle = { height: 75, padding: "0px 10px" };

const addNoChangeOption = (array) => {
  return [{ key: "-", value: 'No aplicar cambios' }, ...array];
}

const TurnsDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    fieldsToHide,
    handleAfterSubmit,
    handleClose,
  } = props;

  const [turn, setTurn] = useState('');
  const [assignationDate, setAssignationDate] = useState(new Date());
  const [calendarType, setCalendarType] = useState('');
  const [timeControlType, setTimeControlType] = useState('');
  const [extraHours, setExtraHours] = useState('-');
  const [mobileMark, setMobileMark] = useState('-');
  const [icariusMark, setIcariusMark] = useState('-');
  const [markWithAlert, setMarkWithAlert] = useState('-');

  const editableFields = useSelector(getEditableFields);

  const turnsArray = editableFields.find(item => item.name === 'TURNO').options
    .map((item) => ({ key: item.id, value: item.value }))
    .filter(item => item.key !== "");

  const calendarTypeArray = useMemo(() => {
    const auxArr = editableFields.find(item => item.name === 'TIPO DE CALENDARIO').options.map((item) => ({ key: item.id, value: item.value }));

    if (turn !== '-') {
      return auxArr.filter(item => item.key !== '-');
    }

    return auxArr;
  }, [editableFields, turn])

  const controlTypeArray = useMemo(() => {
    const auxArr = editableFields.find(item => item.name === 'TIPO DE CONTROL HORARIO').options.map((item) => ({ key: item.id, value: item.value }));

    if (turn !== '-') {
      return auxArr.filter(item => item.key !== '-');
    }

    return auxArr;
  }, [editableFields, turn])

  const extraHoursArray = useMemo(() => {
    const auxArr = addNoChangeOption(editableFields.find(item => item.name === 'HORAS EXTRAS LIBERADAS').options.map((item) => ({ key: item.id, value: item.value })));

    if (turn !== '-') {
      return auxArr.filter(item => item.key !== 'S');
    }

    return auxArr;
  }, [editableFields, turn])

  const mobileArray = useMemo(() => {
    const auxArr = addNoChangeOption(editableFields.find(item => item.name === 'PUEDE MARCAR DESDE MOVILES').options.map((item) => ({ key: item.id, value: item.value })));

    if (turn !== '-') {
      return auxArr.filter(item => item.key !== 'S');
    }

    return auxArr;
  }, [editableFields, turn])

  const icariusArray = useMemo(() => {
    const auxArr = addNoChangeOption(editableFields.find(item => item.name === 'PUEDE MARCAR DESDE ICARIUS').options.map((item) => ({ key: item.id, value: item.value })));

    if (turn !== '-') {
      return auxArr.filter(item => item.key !== 'S');
    }

    return auxArr;
  }, [editableFields, turn])

  const markArray = addNoChangeOption(editableFields.find(item => item.name === 'MARCAR CON AVISO PROGRAMADO').options.map((item) => ({ key: item.id, value: item.value })));

  const dispatch = useDispatch();

  const handleTurnChange = (value) => {
    setTurn(value);
    if (value === '-') {
      setCalendarType('-');
      setTimeControlType('-');
      setExtraHours('N');
      setMobileMark('N');
      setIcariusMark('N');
    } else {
      setCalendarType('');
      setTimeControlType('');
    }
  }

  const handleSubmit = () => {
    if (assignationDate && calendarType && timeControlType) {
      dispatch(massiveTurnChangeAction({
        codes: employees.map(item => item.code),
        turn,
        assignationDate: formatDateYYYYMMDD(assignationDate),
        calendarType,
        timeControlType,
        extraHours,
        mobileMark,
        icariusMark,
        captureGeoposition: 'N',
        markWithAlert,
      }))
        .then((resp) => {
          if (resp.status === 200) {
            handleAfterSubmit(resp?.data?.showAlert);
          }
          handleClose();
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Hay datos inválidos o incompletos', severity: "warning" }));
    }
  };

  const hasTurn = turn !== '-';

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio de turnos masivo"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-turn`}>{"Turno"}</InputLabel>
                <Select
                  value={turn}
                  labelId={`label-turn`}
                  id={`select-turn`}
                  onChange={(e) => handleTurnChange(e.target.value)}
                  margin={"none"}
                >
                  {
                    turnsArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha asignación turno'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=''
                  format="dd/MM/yyyy"
                  margin="none"
                  value={assignationDate}
                  onChange={(e) => setAssignationDate(e)}
                  minDate={new Date()}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de calendario"}</InputLabel>
                <Select
                  disabled={!hasTurn}
                  value={calendarType}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setCalendarType(e.target.value)}
                  margin={"none"}
                >
                  {
                    calendarTypeArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type2`}>{"Tipo de control horario"}</InputLabel>
                <Select
                  disabled={!hasTurn}
                  value={timeControlType}
                  labelId={`label-type2`}
                  id={`select-type2`}
                  onChange={(e) => setTimeControlType(e.target.value)}
                  margin={"none"}
                >
                  {
                    controlTypeArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-extraHours`}>{"Horas extras liberadas"}</InputLabel>
                <Select
                  disabled={!hasTurn}
                  value={extraHours}
                  labelId={`label-extraHours`}
                  id={`select-extraHours`}
                  onChange={(e) => setExtraHours(e.target.value)}
                  margin={"none"}
                >
                  {
                    extraHoursArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-mobile`}>{"Puede marcar desde móviles"}</InputLabel>
                <Select
                  disabled={!hasTurn}
                  value={mobileMark}
                  labelId={`label-mobile`}
                  id={`select-mobile`}
                  onChange={(e) => setMobileMark(e.target.value)}
                  margin={"none"}
                >
                  {
                    mobileArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-ICARIUS`}>{"Puede marcar desde ICARIUS"}</InputLabel>
                <Select
                  disabled={!hasTurn}
                  value={icariusMark}
                  labelId={`label-ICARIUS`}
                  id={`select-ICARIUS`}
                  onChange={(e) => setIcariusMark(e.target.value)}
                  margin={"none"}
                >
                  {
                    icariusArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              !fieldsToHide.includes("MARCAR CON AVISO PROGRAMADO") &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-mark`}>{"Marcar con aviso programado"}</InputLabel>
                  <Select
                    value={markWithAlert}
                    labelId={`label-mark`}
                    id={`select-mark`}
                    onChange={(e) => setMarkWithAlert(e.target.value)}
                    margin={"none"}
                  >
                    {
                      markArray.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {`¿Está seguro que quiere cambiar los datos de turnos para ${employees.length} colaborador/es?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default TurnsDialog;
