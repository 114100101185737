import { CloseIcon } from "@icarius-icons";
import React from "react";
import { connect } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Typography,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable"; 
import DialogTransition from "@icarius-common/dialogTransition";
import { deleteATemplate, eliminarTemplate } from "@icarius-pages/myPeople/actions";

class TemplateDialog extends React.Component {
  render() {
    const { template } = this.props;
    let open = false;

    if (typeof this.props.open !== "undefined") {
      open = this.props.open;
    }
    return (
      <Dialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}>
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {getLocalizedString("atention")}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={this.handleCloseDialog} />
          <DialogContent>
            <Typography variant={"h6"} className={"whiteText"}>
              {getLocalizedString("confirmDelete")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction
              onClick={this.handleCloseDialog}
              text={getLocalizedString("disagree")}
            />
            <ButtonDialogAction
              onClick={() => this.handleConfirm(template)}
              isAccept
              text={getLocalizedString("agree")}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  handleConfirm = template => {
    const code = template.Code;
    this.props
      .dispatch(deleteATemplate(code))
      .then(response => this.eliminarDeView(response, code));
  };

  eliminarDeView = (response, code) => {
    if (response.data && response.data.status === "OK") {
      this.props.handleConfirmDelete();
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("templateDelete"), severity: "success" }));
      this.props.dispatch(eliminarTemplate(code)).then(this.handleCloseDialog);
    }
  };

  handleCloseDialog = () => {
    this.props.handleCloseDialog();
  };
}

export default connect()(TemplateDialog);
