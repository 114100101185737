import React, { useEffect, useMemo, useState } from "react";
import {
  getMyPeopleAction,
  getTemplateRows,
  getLocationFromServer,
  getManagementDivisionsFromServer,
  getEmployeeDataAction,
  downloadStructureAction,
  downloadFamilyGroupAction,
} from "../../actions";
import {
  getEmployees,
  getIsLoading,
  getDateFormat,
  getLocale,
  getSectionsToHide,
  getFieldsToHide,
} from "../../selectors";
import {
  getIsExportingGrid,
  getIsBlockedSocial,
  getIsSocialEnabled,
  getGeographicalDivisions,
} from "src/app/selectors";
import { applyGeographicalDivision } from "@icarius-table/utils";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getFeedInitialDataAction,
} from "@icarius-pages/feed/actions";
import {
  getGroups,
  getIsLoadingFeed,
  getIsLoadingInitialData,
  getPeople,
  getPolls,
  getTasks,
} from "@icarius-pages/feed/selectors";
import { useDispatch, useSelector } from "react-redux";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getFieldsBySectionToHide } from "../userPage/userForm";
import { numberFormatterDecimalsCustom } from "@icarius-table/number";

const useGridScreen = (isPeopleMaster, handleOpenUserPage, handleOpenDuplicateUserPage) => {

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [massiveUploadMenuAnchor, setMassiveUploadMenuAnchor] = useState(null);
  const [mailDialogIsOpen, setMailDialogIsOpen] = useState(false);
  const [photosDialogIsOpen, setPhotosDialogIsOpen] = useState(false);
  const [uploadDistributionCostDialogIsOpen, setUploadDistributionCostDialogIsOpen] = useState(false);
  const [uploadFamilyGroupDialogIsOpen, setUploadFamilyGroupDialogIsOpen] = useState(false);
  const [massiveMenuAnchor, setMassiveMenuAnchor] = useState(null);
  const [salaryDialogIsOpen, setSalaryDialogIsOpen] = useState(false);
  const [terminationDialogIsOpen, setTerminationDialogIsOpen] = useState(false);
  const [onboardingAssignmentDialogIsOpen, setOnboardingAssignmentDialogIsOpen] = useState(false);
  const [workingDayDialogIsOpen, setWorkingDayDialogIsOpen] = useState(false);
  const [contractDialogIsOpen, setContractDialogIsOpen] = useState(false);
  const [payrollDialogIsOpen, setPayrollDialogIsOpen] = useState(false);
  const [turnsDialogIsOpen, setTurnsDialogIsOpen] = useState(false);
  const [rehiringDialogIsOpen, setRehiringDialogIsOpen] = useState(false);
  const [structureDialogIsOpen, setStructureDialogIsOpen] = useState(false);
  const [workOrChoreDialogIsOpen, setWorkOrChoreDialogIsOpen] = useState(false);
  const [positionDialogIsOpen, setPositionDialogIsOpen] = useState(false);
  const [costCenterDialogIsOpen, setCostCenterDialogIsOpen] = useState(false);
  const [photoGridDialogIsOpen, setPhotoGridDialogIsOpen] = useState(false);
  const [codeDialogIsOpen, setCodeDialogIsOpen] = useState(false);
  const [socialDialogIsOpen, setSocialDialogIsOpen] = useState(false);
  const [markingSystemDialogIsOpen, setMarkingSystemDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const isExportingGrid = useSelector(getIsExportingGrid);
  const isLoading = useSelector(getIsLoading);
  const isLoadingFeedInitialData = useSelector(getIsLoadingInitialData);
  const isLoadingFeed = useSelector(getIsLoadingFeed);
  const polls = useSelector(getPolls);
  const groups = useSelector(getGroups);
  const tasks = useSelector(getTasks);
  const people = useSelector(getPeople);
  const employees = useSelector(getEmployees);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const isBlockedSocial = useSelector(getIsBlockedSocial);
  const isSocialEnabled = useSelector(getIsSocialEnabled);
  const sectionsToHide = useSelector(getSectionsToHide);
  const fieldsToHide = useSelector(getFieldsToHide);
  const geographicalDivisions = useSelector(getGeographicalDivisions);

  useEffect(() => {
    dispatch(getMyPeopleAction(isPeopleMaster));
  }, [dispatch, isPeopleMaster])

  const handleRowClick = (employeeRow) => {
    const code = employeeRow.data["CODIGO DE EMPLEADO"];
    const employee = employees.find((employee) => employee["CODIGO DE EMPLEADO"] === employeeRow.data["CODIGO DE EMPLEADO"]);

    if (isPeopleMaster) {
      dispatch(getLocationFromServer(code));
      dispatch(getManagementDivisionsFromServer(code));
      dispatch(getEmployeeDataAction(code))
        .then((resp) => {
          if (resp.status === 200) {
            handleOpenUserPage(null);
          }
        })
    } else {
      handleOpenUserPage(employee);
    }
  };

  const handleDuplicateClick = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un empleado', severity: "warning" }));
    } else {
      const code = selectedRows[0]["CODIGO DE EMPLEADO"];
      const employee = employees.find(employee => employee["CODIGO DE EMPLEADO"] === selectedRows[0]["CODIGO DE EMPLEADO"]);

      dispatch(getLocationFromServer(code));
      dispatch(getManagementDivisionsFromServer(code));

      if (isPeopleMaster) {
        dispatch(getEmployeeDataAction(code))
          .then((resp) => {
            if (resp.status === 200) {
              handleOpenDuplicateUserPage(employee);
            }
          })
      } else {
        handleOpenDuplicateUserPage(employee);
      }
    }
  };

  const handleDownloadDistributionCostExcel = () => {
    dispatch(downloadStructureAction());
    setMassiveUploadMenuAnchor(null);
  }

  const handleDownloadFamilyGroupExcel = () => {
    dispatch(downloadFamilyGroupAction());
    setMassiveUploadMenuAnchor(null);
  }

  const handleOpenPhotosDialog = () => {
    setPhotosDialogIsOpen(true);
    setMassiveUploadMenuAnchor(null);
  }

  const handleOpenUploadDistributionCostExcel = () => {
    setUploadDistributionCostDialogIsOpen(true);
    setMassiveUploadMenuAnchor(null);
  }
  const handleOpenUploadFamilyGroupExcel = () => {
    setUploadFamilyGroupDialogIsOpen(true);
    setMassiveUploadMenuAnchor(null);
  }

  const handleCloseUploadDistributionCostExcel = () => {
    setUploadDistributionCostDialogIsOpen(false);
  }

  const handleCloseUploadFamilyGroupExcel = () => {
    setUploadFamilyGroupDialogIsOpen(false);
  }

  const handleClosePhotosDialog = () => {
    setPhotosDialogIsOpen(false);
  }

  const handleOpenSalaryDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      if (row['MONEDA'] !== selectedRows[0]['MONEDA']) return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio masivo de sueldos o salarios debe seleccionar a colaboradores solo vigentes y con el mismo tipo de moneda',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setSalaryDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
      coin: employee["MONEDA"],
    })));
  }

  const handleCloseSalaryDialog = () => {
    setSalaryDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenPayrollDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para la habilitación masiva en nóminas debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setPayrollDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleClosePayrollDialog = () => {
    setPayrollDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenTurnsDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio de turnos masivo debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setTurnsDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleOpenMarkingSystemDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio de sistema de marcaje masivo debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setMarkingSystemDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleCloseTurnsDialog = () => {
    setTurnsDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleCloseMarkingSystemDialog = () => {
    setMarkingSystemDialogIsOpen(false);
    setSelectedEmployees([]);
  }


  const handleOpenCodeDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un único empleado', severity: "warning" }));
      return;
    }

    setCodeDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
      country: employee["PAIS"],
    })));
  }

  const handleCloseCodeDialog = () => {
    setCodeDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenWorkingDayDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio masivo de jornada laboral debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setWorkingDayDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleOpenOnboardingAssignmentDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para la asignación debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setOnboardingAssignmentDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
      position: employee["CARGO"],
    })));
  }

  const handleCloseOnboardingAssignmentDialog = () => {
    setOnboardingAssignmentDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenPositionDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    const rowsAreSamePosition = selectedRows.every(row => {
      if (row['CARGO'] !== selectedRows[0]['CARGO']) return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio masivo de cargo debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    if (!rowsAreSamePosition) {
      dispatch(openSnackbarAction({
        msg: 'Para poder cambiar cargos masivamente, todos los colaboradores seleccionados deben tener el mismo cargo',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setPositionDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
      position: employee["CARGO"],
    })));
  }

  const handleCloseWorkingDayDialog = () => {
    setWorkingDayDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenContractDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio masivo de contratos debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setContractDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleOpenWorkOrChoreDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio masivo de obra o faena debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setWorkOrChoreDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleOpenStructureDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio masivo de estructura departamental debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setStructureDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleOpenCostCenterDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para el cambio masivo de centros de costo debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setCostCenterDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleOpenPhotoGridDialog = (gridRef) => {
    let selectedRows = [];
    gridRef.api.forEachNodeAfterFilterAndSort(node => {
      if (node.selected) {
        selectedRows.push(node.data)
      }
    });

    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    if (selectedRows.length > 500) {
      dispatch(openSnackbarAction({ msg: "Se pueden seleccionar como máximo 500 colaboradores", severity: "warning" }));
      return;
    }

    setPhotoGridDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      name: employee["APELLIDO Y NOMBRE"],
      position: employee["CARGO"],
      function: employee["FUNCION"],
      image: employee["FOTOGRAFIA"] && employee["FOTOGRAFIA"] !== "-" ? employee["FOTOGRAFIA"] : "",
      managementCenter: employee["CENTRO DE COSTO"]
    })));
  }

  const handleClosePhotoGridDialog = () => {
    setPhotoGridDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleCloseCostCenterDialog = () => {
    setCostCenterDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleCloseStructureDialog = () => {
    setStructureDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleCloseContractDialog = () => {
    setContractDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleCloseWorkOrChoreDialog = () => {
    setWorkOrChoreDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleClosePositionDialog = () => {
    setPositionDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenSocialDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    setMassiveMenuAnchor(null);

    dispatch(getFeedInitialDataAction())
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedEmployees(selectedRows.map((employee) => employee["CODIGO DE EMPLEADO"]));
          setSocialDialogIsOpen(true);
        }
      });
  }

  const handleCloseSocialDialog = () => {
    setSocialDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenRehiringDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] === 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para la recontratación masiva de colaboradores solo puede seleccionar a colaboradores no vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setRehiringDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleCloseRehiringDialog = () => {
    setRehiringDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleOpenTerminationDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const rowsAreValid = selectedRows.every(row => {
      if (row['ESTADO'] !== 'Vigente') return false;
      return true;
    })

    if (!rowsAreValid) {
      dispatch(openSnackbarAction({
        msg: 'Para las desvinculaciones masivas debe seleccionar solo a colaboradores vigentes',
        severity: "warning",
        duration: null
      }));
      return;
    }

    setTerminationDialogIsOpen(true);
    setMassiveMenuAnchor(null);
    setSelectedEmployees(selectedRows.map((employee) => ({
      code: employee["CODIGO DE EMPLEADO"],
    })));
  }

  const handleSetMassiveMenuAnchor = (value) => setMassiveMenuAnchor(value);
  const handleSetMassiveUploadMenuAnchor = (value) => setMassiveUploadMenuAnchor(value);

  const handleCloseTerminationDialog = () => {
    setTerminationDialogIsOpen(false);
    setSelectedEmployees([]);
  }

  const handleAfterMassiveChange = (showAlert) => {
    dispatch(getMyPeopleAction(isPeopleMaster));
    dispatch(getTemplateRows());

    if (showAlert) {
      dispatch(openDialogAction({
        title: "Atención",
        msg: <>
          <div>Ha cambiado datos que tienen relación con la habilitación de los Colaboradores en los procesos de cálculo.</div>
          <div style={{ marginTop: 10 }}>Acceda a los procesos de cálculo actuales y actualice la lista de colaboradores que están seleccionados para los procesos, porque en función de los datos que ha cambiado, la lista de colaboradores seleccionados pudo haber variado.</div>
          <div style={{ marginTop: 10 }}>Posterior a la revisión de los procesos, es muy recomendable que ejecute un proceso de cálculo total.</div>
        </>,
        acceptOnly: true,
      }))
    }
  }

  const handleMailButton = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
    } else {
      selectedRows = selectedRows.map((employee) => (
        {
          name: employee["APELLIDO Y NOMBRE"],
          key: employee["CODIGO DE EMPLEADO"],
          image: employee["FOTOGRAFIA"],
        }
      ));

      setSelectedEmployees(selectedRows);
      setMailDialogIsOpen(true);
      setMassiveMenuAnchor(null);
    }
  }

  const handleCloseMailDialog = () => {
    setSelectedEmployees([]);
    setMailDialogIsOpen(false);
  }

  const ownColumnDef = useMemo(() => {
    // Primero me fijo si ya tengo columnas asignadas
    let columnDefs = applyGeographicalDivision(getColumnDefByPage(paths.myPeople), geographicalDivisions);

    (employees[0]?.userFields || []).forEach((field) => {
      const hasDecimals = Object.hasOwn(field, "decimals");
      columnDefs.push({
        headerName: field.key,
        field: field.key,
        filter: "agSetColumnFilter",
        valueFormatter: (p) => hasDecimals ? numberFormatterDecimalsCustom(p, field.decimals) : null,
      });
    });

    let fieldsToHideBySection = getFieldsBySectionToHide(sectionsToHide).map((item) => item.name);

    return columnDefs.filter((item) => !fieldsToHide.includes(item.field) && !fieldsToHideBySection.includes(item.field));
  }, [employees, sectionsToHide, fieldsToHide, geographicalDivisions])

  const gridData = employees.map((item) => {
    const data = { ...item };
    (item?.userFields || []).forEach((field) => {
      data[field.key] = field.value;
    })

    return data;
  });

  const state = {
    mailDialogIsOpen,
    photosDialogIsOpen,
    uploadDistributionCostDialogIsOpen,
    uploadFamilyGroupDialogIsOpen,
    massiveMenuAnchor,
    salaryDialogIsOpen,
    terminationDialogIsOpen,
    workingDayDialogIsOpen,
    contractDialogIsOpen,
    payrollDialogIsOpen,
    turnsDialogIsOpen,
    rehiringDialogIsOpen,
    structureDialogIsOpen,
    workOrChoreDialogIsOpen,
    positionDialogIsOpen,
    costCenterDialogIsOpen,
    photoGridDialogIsOpen,
    codeDialogIsOpen,
    socialDialogIsOpen,
    markingSystemDialogIsOpen,
    selectedEmployees,
    massiveUploadMenuAnchor,
    onboardingAssignmentDialogIsOpen,
  }

  const handlers = {
    handleRowClick,
    handleDuplicateClick,
    handleDownloadDistributionCostExcel,
    handleDownloadFamilyGroupExcel,
    handleOpenPhotosDialog,
    handleOpenUploadDistributionCostExcel,
    handleOpenUploadFamilyGroupExcel,
    handleCloseUploadDistributionCostExcel,
    handleCloseUploadFamilyGroupExcel,
    handleClosePhotosDialog,
    handleOpenSalaryDialog,
    handleCloseSalaryDialog,
    handleOpenPayrollDialog,
    handleClosePayrollDialog,
    handleOpenTurnsDialog,
    handleCloseTurnsDialog,
    handleOpenCodeDialog,
    handleOpenMarkingSystemDialog,
    handleCloseMarkingSystemDialog,
    handleCloseCodeDialog,
    handleOpenWorkingDayDialog,
    handleOpenPositionDialog,
    handleCloseWorkingDayDialog,
    handleOpenContractDialog,
    handleOpenWorkOrChoreDialog,
    handleOpenStructureDialog,
    handleOpenCostCenterDialog,
    handleOpenPhotoGridDialog,
    handleClosePhotoGridDialog,
    handleCloseCostCenterDialog,
    handleCloseStructureDialog,
    handleCloseContractDialog,
    handleCloseWorkOrChoreDialog,
    handleClosePositionDialog,
    handleOpenSocialDialog,
    handleCloseSocialDialog,
    handleOpenRehiringDialog,
    handleCloseRehiringDialog,
    handleOpenTerminationDialog,
    handleCloseTerminationDialog,
    handleAfterMassiveChange,
    handleMailButton,
    handleCloseMailDialog,
    handleOpenOnboardingAssignmentDialog,
    handleCloseOnboardingAssignmentDialog,
    handleSetMassiveMenuAnchor,
    handleSetMassiveUploadMenuAnchor,
  }

  return {
    gridData,
    ownColumnDef,
    isExportingGrid,
    isLoading,
    isLoadingFeedInitialData,
    isLoadingFeed,
    polls,
    groups,
    tasks,
    people,
    dateFormat,
    locale,
    isBlockedSocial,
    isSocialEnabled,
    fieldsToHide,
    sectionsToHide,
    state,
    handlers,
  }
}

export default useGridScreen;