import React from "react";
import { getAppColor } from "src/app/selectors";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString } from "@icarius-localization/strings";
import { MenuItem, Select, FormControl, IconButton, Tooltip } from "@material-ui/core";
import { DeleteIcon, SaveIcon, AddIcon, PublishIcon, GetAppIcon, ExcelIcon } from "@icarius-icons";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import SendMailDialog from "@icarius-common/mailDialog/sendMailDialog";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import StyledHelpMenu from "@icarius-common/styledMenu";
import PeopleIcon from '@material-ui/icons/People';
import FeedFormDialog from "@icarius-pages/feed/components/form/formDialog";
import {
  createFeedPublicationAction,
} from "@icarius-pages/feed/actions";
import { useSelector } from "react-redux";
import { SendIcon } from "@icarius-icons/index";
import TemplateDialog from "./templateDialogs/templateDialog";
import UpdateTemplate from "./templateDialogs/updateDialog";
import SaveTemplate from "./templateDialogs/saveTemplate";
import UploadFilesDialog from "../dialog/uploadFilesDialog";
import SalaryDialog from "../dialog/salaryDialog";
import TerminationDialog from "../dialog/terminationDialog";
import WorkingDayDialog from "../dialog/workingDayDialog";
import ContractDialog from "../dialog/contractDialog/contractDialog";
import WorkOrChoreDialog from "../dialog/workOrChoreDialog/workOrChoreDialog";
import PayrollDialog from "../dialog/payrollDialog";
import TurnsDialog from "../dialog/turnsDialog";
import CodeDialog from "../dialog/codeDialog";
import MarkingSystemDialog from "../dialog/markingSystemDialog";
import RehiringDialog from "../dialog/rehiringDialog/rehiringDialog";
import UploadDistributionCostDialog from "../dialog/uploadDistributionCostDialog"
import UploadFamilyGroupDialog from "../dialog/uploadFamilyGroupDialog"
import StructureDialog from "../dialog/structureDialog";
import PositionDialog from "../dialog/positionDialog/positionDialog";
import OnboardingAssignmentDialog from "../dialog/onboardingAssignmentDialog/onboardingAssignmentDialog";
import CostCenterDialog from "../dialog/costCenterDialog";
import PhotoGridDialog from "../dialog/photoGridDialog";
import useGridScreen from "./useGridScreen";
import useTemplates from "./useTemplates";
import "@icarius-assets/css/grid-dark.scss";
import "@icarius-assets/css/grid-light.scss";
import useForceRender from "@icarius-utils/hooks/useForceRender";

const CustomSelect = withStyles({
  select: {
    width: 350,
  },
})(Select);

const GridScreen = (props) => {

  const {
    isPeopleMaster,
    handleOpenUserPage,
    handleOpenDuplicateUserPage,
  } = props;

  const {
    gridData,
    ownColumnDef,
    isExportingGrid,
    isLoading,
    isLoadingFeedInitialData,
    isLoadingFeed,
    polls,
    groups,
    tasks,
    people,
    dateFormat,
    locale,
    isBlockedSocial,
    isSocialEnabled,
    fieldsToHide,
    sectionsToHide,
    state,
    handlers,
  } = useGridScreen(isPeopleMaster, handleOpenUserPage, handleOpenDuplicateUserPage);

  const {
    user,
    templates,
    state: templatesState,
    handlers: templatesHandlers,
  } = useTemplates();

  const key = useForceRender(isPeopleMaster);

  const color = useSelector(getAppColor);

  const selectPlantilla = (gridRef) =>
    <>
      <div style={{ maxWidth: 375 }}>
        <FormControl>
          <CustomSelect
            value={templatesState.template}
            onChange={e => templatesHandlers.handleSelectPlanillaClick(e, templates, gridRef)}
            displayEmpty>
            <MenuItem value="">{getLocalizedString("selectTemplate")}</MenuItem>
            {
              Object.keys(templates).length > 0 &&
              templates.map((item, index) => (
                <MenuItem key={index} value={item.Code}>
                  {`${item.U_template} - ${templatesHandlers.getRole(item.U_rol)}`}
                </MenuItem>
              ))
            }
          </CustomSelect>
        </FormControl>
      </div>
      <Tooltip title={getLocalizedString("createTemplate")}>
        <IconButton aria-label="add" onClick={templatesHandlers.handleOpenCreateTemplateDialog}>
          <AddIcon htmlColor={color} />
        </IconButton>
      </Tooltip>
      <Tooltip title={getLocalizedString("importTemplate")}>
        <IconButton aria-label="import" onClick={() => templatesHandlers.handleImportTemplates(gridRef)}>
          <PublishIcon htmlColor={color} />
        </IconButton>
      </Tooltip>
      {
        templatesState.template !== "" && user && user.code === templatesState.templateSeleccionado.U_codemp &&
        <>
          <Tooltip title={getLocalizedString("deleteTemplate")}>
            <IconButton aria-label="delete" onClick={templatesHandlers.handleClickDeleteTemplate}>
              <DeleteIcon htmlColor={color} />
            </IconButton>
          </Tooltip>
          <Tooltip title={getLocalizedString("updateTemplate")}>
            <IconButton aria-label="update" onClick={templatesHandlers.handeClickUpdateTemplate}>
              <SaveIcon htmlColor={color} />
            </IconButton>
          </Tooltip>
          <Tooltip title={getLocalizedString("exportTemplate")}>
            <IconButton aria-label="export" onClick={templatesHandlers.handleExportTemplates}>
              <GetAppIcon htmlColor={color} />
            </IconButton>
          </Tooltip>
        </>
      }
    </>

  const newEmployeeItem = () =>
    <CustomIconButton
      title={getLocalizedString("newEmployee")}
      onClick={() => handleOpenUserPage(null)}
      type={"add"}
    />

  const duplicateEmployeeItem = (gridRef) =>
    <CustomIconButton
      title={'Duplicar empleado'}
      onClick={() => handlers.handleDuplicateClick(gridRef)}
      type={"duplicate"}
    />

  const mailItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("sendMessage")}
      onClick={() => handlers.handleMailButton(gridRef)}
      type={"mail"}
    />

  const socialItem = (gridRef) => isSocialEnabled && !isBlockedSocial && (
    <CustomIconButton
      title={'Publicar en muro'}
      onClick={() => handlers.handleOpenSocialDialog(gridRef)}
    >
      <SendIcon />
    </CustomIconButton>
  )

  const photoGridItem = (gridRef) =>
    <CustomIconButton
      title={"Generar imagen con grupo de personas"}
      onClick={() => handlers.handleOpenPhotoGridDialog(gridRef)}
      type={"group"}
    />

  const massiveActionsItem = (gridRef) =>
    <>
      <CustomIconButton
        title={'Acciones masivas/especiales'}
        onClick={(e) => handlers.handleSetMassiveMenuAnchor(e.currentTarget)}
      >
        <PeopleIcon />
      </CustomIconButton>
      <StyledHelpMenu
        anchorEl={state.massiveMenuAnchor}
        isOpen={Boolean(state.massiveMenuAnchor)}
        onClose={() => handlers.handleSetMassiveMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Asignar a proceso de onboarding'}
          onClick={() => handlers.handleOpenOnboardingAssignmentDialog(gridRef)}
        />
        <MenuItemWithIcon
          text={'Cambiar cargos'}
          onClick={() => handlers.handleOpenPositionDialog(gridRef)}
        />
        <MenuItemWithIcon
          text={'Cambiar centros de costo de gestión'}
          onClick={() => handlers.handleOpenCostCenterDialog(gridRef)}
        />
        <MenuItemWithIcon
          text={'Cambiar código de empleado'}
          onClick={() => handlers.handleOpenCodeDialog(gridRef)}
        />
        {
          !sectionsToHide.includes("contract") &&
          <MenuItemWithIcon
            text={'Cambiar contratos'}
            onClick={() => handlers.handleOpenContractDialog(gridRef)}
          />
        }
        <MenuItemWithIcon
          text={'Cambiar estructura departamental'}
          onClick={() => handlers.handleOpenStructureDialog(gridRef)}
        />
        {
          !sectionsToHide.includes("contract") &&
          <MenuItemWithIcon
            text={'Cambiar jornada laboral'}
            onClick={() => handlers.handleOpenWorkingDayDialog(gridRef)}
          />
        }
        <MenuItemWithIcon
          text={'Cambiar obra o faena'}
          onClick={() => handlers.handleOpenWorkOrChoreDialog(gridRef)}
        />
        {
          !sectionsToHide.includes("time") &&
          <MenuItemWithIcon
            text={'Cambiar turnos'}
            onClick={() => handlers.handleOpenTurnsDialog(gridRef)}
          />
        }
        {
          !sectionsToHide.includes("time") &&
          <MenuItemWithIcon
            text={'Cambiar sistema de marcaje'}
            onClick={() => handlers.handleOpenMarkingSystemDialog(gridRef)}
          />
        }
        {
          !sectionsToHide.includes("remuneration") &&
          <MenuItemWithIcon
            text={'Cambiar sueldos/salarios'}
            onClick={() => handlers.handleOpenSalaryDialog(gridRef)}
          />
        }
        {
          !sectionsToHide.includes("contract") &&
          <MenuItemWithIcon
            text={'Desvincular'}
            onClick={() => handlers.handleOpenTerminationDialog(gridRef)}
          />
        }
        <MenuItemWithIcon
          text={getLocalizedString("sendMessage")}
          onClick={() => handlers.handleMailButton(gridRef)}
        />
        {
          !sectionsToHide.includes("remuneration") &&
          <MenuItemWithIcon
            text={'Habilitar en nóminas'}
            onClick={() => handlers.handleOpenPayrollDialog(gridRef)}
          />
        }
        {
          !sectionsToHide.includes("contract") &&
          <MenuItemWithIcon
            text={'Recontratar'}
            onClick={() => handlers.handleOpenRehiringDialog(gridRef)}
          />
        }
        {
          isSocialEnabled && !isBlockedSocial &&
          <MenuItemWithIcon
            text={'Publicar en muro'}
            onClick={() => handlers.handleOpenSocialDialog(gridRef)}
          />
        }
      </StyledHelpMenu>
    </>

  const massiveUploadsItem = () =>
    <>
      <CustomIconButton
        title={'Cargas masivas'}
        onClick={(e) => handlers.handleSetMassiveUploadMenuAnchor(e.currentTarget)}
      >
        <ExcelIcon />
      </CustomIconButton>
      <StyledHelpMenu
        anchorEl={state.massiveUploadMenuAnchor}
        isOpen={Boolean(state.massiveUploadMenuAnchor)}
        onClose={() => handlers.handleSetMassiveUploadMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Cargar fotografías'}
          onClick={handlers.handleOpenPhotosDialog}
        />
        <MenuItemWithIcon
          text={'Exportar plantilla de distribución de costos'}
          onClick={() => handlers.handleDownloadDistributionCostExcel()}
        />
        <MenuItemWithIcon
          text={'Importar plantilla de distribución de costos'}
          onClick={handlers.handleOpenUploadDistributionCostExcel}
        />
        <MenuItemWithIcon
          text={'Exportar plantilla de grupo familiar'}
          onClick={() => handlers.handleDownloadFamilyGroupExcel()}
        />
        <MenuItemWithIcon
          text={'Importar plantilla de grupo familiar'}
          onClick={handlers.handleOpenUploadFamilyGroupExcel}
        />
      </StyledHelpMenu>
    </>

  const helpItems = [
    <MenuItemWithIcon
      key={"a"}
      value={"/resources/ICARIUS - Accesos segmentado por GRUPOS.pdf"}
      text={getLocalizedString("accessosSegmentadoPorGrupos")}
      type={"file"}
    />,
  ]

  const child = (gridRef) =>
    <>
      <TemplateDialog
        open={templatesState.templateDialogIsOpen && templatesState.templateSeleccionado}
        handleConfirmDelete={() => templatesHandlers.handleConfirmDelete(gridRef)}
        handleCloseDialog={templatesHandlers.handleCloseTemplateDialog}
        template={templatesState.templateSeleccionado}
      />
      <SaveTemplate
        open={templatesState.createTemplateDialogIsOpen}
        switchStatus={templatesState.showOnlyCurrentEmployees}
        level={user.level}
        color={color}
        handleSelectAfterCreate={templatesHandlers.handleSelectAfterCreate}
        handleCloseDialog={templatesHandlers.handleCloseCreateTemplateDialog}
        templates={templates}
        grid={gridRef}
      />
      <UpdateTemplate
        open={templatesState.updateDialogIsOpen && templatesState.templateSeleccionado}
        switchStatus={templatesState.showOnlyCurrentEmployees}
        color={color}
        template={templatesState.templateSeleccionado}
        handleCloseDialog={templatesHandlers.handleCloseUpdateDialog}
        grid={gridRef}
      />
      {
        state.mailDialogIsOpen && !isLoading &&
        <SendMailDialog
          open={state.mailDialogIsOpen}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseMailDialog}
          origin={isPeopleMaster ? "PeopleMaster" : "MyPeople"}
        />
      }
      {
        state.photosDialogIsOpen &&
        <UploadFilesDialog
          open={state.photosDialogIsOpen}
          handleClose={handlers.handleClosePhotosDialog}
          isLoading={isLoading}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.uploadDistributionCostDialogIsOpen &&
        <UploadDistributionCostDialog
          open={state.uploadDistributionCostDialogIsOpen}
          handleClose={handlers.handleCloseUploadDistributionCostExcel}
          isLoading={isLoading}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.uploadFamilyGroupDialogIsOpen &&
        <UploadFamilyGroupDialog
          open={state.uploadFamilyGroupDialogIsOpen}
          handleClose={handlers.handleCloseUploadFamilyGroupExcel}
          isLoading={isLoading}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.salaryDialogIsOpen &&
        <SalaryDialog
          open={state.salaryDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseSalaryDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.terminationDialogIsOpen &&
        <TerminationDialog
          open={state.terminationDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseTerminationDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.workingDayDialogIsOpen &&
        <WorkingDayDialog
          open={state.workingDayDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseWorkingDayDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.contractDialogIsOpen &&
        <ContractDialog
          open={state.contractDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseContractDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.workOrChoreDialogIsOpen &&
        <WorkOrChoreDialog
          open={state.workOrChoreDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseWorkOrChoreDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.positionDialogIsOpen &&
        <PositionDialog
          open={state.positionDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleClosePositionDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.onboardingAssignmentDialogIsOpen &&
        <OnboardingAssignmentDialog
          open={state.onboardingAssignmentDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseOnboardingAssignmentDialog}
        />
      }
      {
        state.payrollDialogIsOpen &&
        <PayrollDialog
          open={state.payrollDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleClosePayrollDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.turnsDialogIsOpen &&
        <TurnsDialog
          open={state.turnsDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          fieldsToHide={fieldsToHide}
          handleClose={handlers.handleCloseTurnsDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.markingSystemDialogIsOpen &&
        <MarkingSystemDialog
          open={state.markingSystemDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          fieldsToHide={fieldsToHide}
          handleClose={handlers.handleCloseMarkingSystemDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.codeDialogIsOpen &&
        <CodeDialog
          open={state.codeDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseCodeDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.rehiringDialogIsOpen &&
        <RehiringDialog
          open={state.rehiringDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseRehiringDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.structureDialogIsOpen &&
        <StructureDialog
          open={state.structureDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          fieldsToHide={fieldsToHide}
          handleClose={handlers.handleCloseStructureDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.costCenterDialogIsOpen &&
        <CostCenterDialog
          open={state.costCenterDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleCloseCostCenterDialog}
          handleAfterSubmit={handlers.handleAfterMassiveChange}
        />
      }
      {
        state.photoGridDialogIsOpen &&
        <PhotoGridDialog
          open={state.photoGridDialogIsOpen}
          isLoading={isLoading}
          employees={state.selectedEmployees}
          handleClose={handlers.handleClosePhotoGridDialog}
        />
      }
      {
        state.socialDialogIsOpen &&
        <FeedFormDialog
          open={state.socialDialogIsOpen}
          preselectedData={{ userCodes: state.selectedEmployees }}
          handleClose={handlers.handleCloseSocialDialog}
          polls={polls}
          groups={groups}
          tasks={tasks}
          people={people}
          isLoading={isLoadingFeed}
          createPublicationAction={(dataToSend) => createFeedPublicationAction(dataToSend, true)}
        />
      }
    </>

  return (
    <CommonPage
      key={key} // para que cuando se pase de maestro a myPeople y viceversa, no se rompa el switch de la grilla
      helpItems={isPeopleMaster ? helpItems : null}
      menuItems={isPeopleMaster ? [massiveActionsItem, massiveUploadsItem, newEmployeeItem, duplicateEmployeeItem] : [mailItem, socialItem, photoGridItem]}
      ownColumnDef={ownColumnDef}
      customHeader={selectPlantilla}
      columnDefPage={paths.myPeople}
      rowData={gridData}
      title={isPeopleMaster ? getLocalizedString("employeeMaster") : getLocalizedString("myPeopleTableTitle")}
      gridTitle={getLocalizedString("myPeopleTableTitle")}
      locale={locale}
      dateFormat={dateFormat}
      handleRowClick={handlers.handleRowClick}
      isLoading={isLoading || isExportingGrid || isLoadingFeedInitialData}
      hasSwitch
      hasHelp
      hasSelectAll
      hasExpand
      codeFieldName={'CODIGO DE EMPLEADO'}
    >
      {child}
    </CommonPage>
  );
}

export default GridScreen;