import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { importFamilyGroupSpreadsheetAction } from "@icarius-pages/myPeople/actions";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";

const UploadFilesDialog = (props) => {

  const {
    open,
    isLoading,
    handleClose,
    handleAfterSubmit,
  } = props;

  const [files, setFiles] = useState(null);
  const [failedDocuments, setFailedDocuments] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setFailedDocuments(null);
    if (dataIsValid()) {
      let dataToSend = {
        file: files[0],
      }
      dispatch(importFamilyGroupSpreadsheetAction(dataToSend))
        .then((resp) => {
          if (resp.status === 200) {
            handleAfterSubmit(resp?.data?.showAlert);
            handleClose();
          }
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos una plantilla de excel', severity: "warning" }));
    }
  };

  const dataIsValid = () => {
    if (files === null) return false;
    return true;
  };

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Carga masiva de grupo familiar"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <FileInput
            acceptedFormats={['.xlsx', '.xls']}
            handleChange={(files) => setFiles(files)}
            preSubmitCallback={() => setFailedDocuments(null)}
          />
          <FileList
            files={files}
            filesWithError={failedDocuments}
            handleClick={() => setFiles(null)}
          />
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("uploadButtonText")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default UploadFilesDialog;
