import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import {
  getMassiveOnboardingAssignmentDataAction,
  massiveOnboardingAssignmentAction,
} from "@icarius-pages/myPeople/actions";
import { getOnboardingProcesses } from "@icarius-pages/myPeople/selectors";
import useHandleForm from "./useHandleForm";
import { openDialogAction } from "@icarius-common/dialog/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const OnboardingAssignmentDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMassiveOnboardingAssignmentDataAction())
  }, [dispatch])

  const onboardingProcesses = useSelector(getOnboardingProcesses);

  const submitCallback = (dataToSend) => {
    const processSelected = onboardingProcesses.find((item) => item.key === dataToSend.processCode);

    if (!processSelected.canAssign) {
      dispatch(openDialogAction({
        title: "Atención",
        msg: (
          <>
            No es posible agregar a nuevos colaboradores a este proceso, porque ya hay actividades avanzadas para uno o más colaboradores.
            <br />
            Cree un nuevo proceso para incorporar a nuevos colaboradores.
          </>
        ),
        acceptOnly: true,
      }));
      return;
    }

    dispatch(massiveOnboardingAssignmentAction({
      codes: employees.map(item => item.code),
      ...dataToSend,
    }));
    handleClose();
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { formData, setFormValue, submit } = useHandleForm(submitCallback, openValidationError);

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Asignación a proceso de onboarding"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-processCode`}>
                  {"Proceso de onboarding"}
                </InputLabel>
                <Select
                  value={formData.processCode}
                  labelId={`label-processCode`}
                  id={`select-processCode`}
                  onChange={(e) => setFormValue(e.target.value, 'processCode')}
                  margin={"none"}
                >
                  {
                    onboardingProcesses?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default OnboardingAssignmentDialog;