import React from "react";
import { connect } from "react-redux";
import CustomTextField from "@icarius-common/input/textField";
import { templatesOptions, MAX_TEMPLATE_LENGTH, MIN_TEMPLATE_LENGTH } from "@icarius-utils/constants";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { createNewTemplate } from "@icarius-pages/myPeople/actions";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

class TemplateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      templateRol: "",
      labelSwitch: getLocalizedString("templateExclusiveToMe"),
      textValue: "",
    };
  }
  render() {
    const { color, grid, level, switchStatus } = this.props;
    let open = false;
    if (typeof this.props.open !== "undefined") {
      open = this.props.open;
    }
    const { error } = this.state;
    let perfil;
    switch (level) {
      case "C":
        perfil = "COLABORADOR";
        break;
      case "M":
        perfil = "MANAGER";
        break;
      case "E":
        perfil = "EMPLEADOR";
        break;
      default:
        break;
    }

    const CustomFormControl = withStyles({
      root: {
        width: "100%",
      },
    })(FormControl);

    return (
      <Dialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}
        fullWidth={true}>
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {getLocalizedString("createTemplate")}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={this.handleCloseDialog} />
          <DialogContent>
            <div>
              <Typography variant={"subtitle1"} className={"whiteText"}>
                {getLocalizedString("template")}:
              </Typography>
              <CustomTextField
                label={""}
                value={this.state.textValue}
                color={color}
                className={"custom-input"}
                onChange={value => this.handleTextChange(value)}
                maxWidth={true}
              />
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Typography variant={"subtitle1"} className={"whiteText"}>
                {getLocalizedString("visualizationRol")}:
              </Typography>
              <CustomFormControl>
                <Select
                  value={this.state.templateRol}
                  onChange={e => this.handleSelectPlanillaClick(e)}
                  displayEmpty>
                  {templatesOptions.map((item, index) => {
                    if (item.profiles.includes(perfil) === true) {
                      return (
                        <MenuItem key={item.code} value={item.code}>
                          {item.label}
                        </MenuItem>
                      );
                    } else {
                      return <div key={index}></div>;
                    }
                  })}
                </Select>
              </CustomFormControl>
            </div>
            {error ? (
              <Typography variant={"subtitle1"} className={"error-text"}>
                {error}
              </Typography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction
              onClick={this.handleCloseDialog}
              text={getLocalizedString("disagree")}
            />
            <ButtonDialogAction
              onClick={() => this.handleConfirm(grid, switchStatus)}
              isAccept
              text={getLocalizedString("save")}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  }
  handleSelectPlanillaClick = e => {
    this.setState({ templateRol: e.target.value });
  };
  handleTextChange = value => this.setState({ textValue: value });
  handleConfirm = (grid, switchStatus) => {
    const { templateRol, textValue } = this.state;

    const data = {
      column: grid.columnApi.getColumnState(),
      group: grid.columnApi.getColumnGroupState(),
      sort: grid.columnApi.getColumnState(),
      filter: grid.api.getFilterModel(),
      pivot: grid.columnApi.isPivotMode(),
      showCurrentEmployees: switchStatus,
      chart: grid.api.getChartModels().length > 0 ? grid.api.getChartModels()[0] : undefined,
    };

    if (data.chart) {
      delete data.chart.chart;
    }

    try {
      this.validateInputs(textValue, templateRol);
      this.props
        .dispatch(createNewTemplate(templateRol, textValue, data))
        .then(response => {
          if (response.data.status === "ERROR_DUPLICADO") {
            this.setState({ error: getLocalizedErrorString("templateAlreadyExists") });
          } else {
            this.setState({ error: "" });
            this.props.handleSelectAfterCreate(response.data.result.Code, this.props.templates);
            this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("templateCreate"), severity: "success" }));
            this.handleCloseDialog();
          }
        })
        .catch(e => {
          this.setState({ error: getErrorStringFromError(e) });
        });
    } catch (e) {
      this.setState({ error: e });
    }
  };
  validateInputs = (textValue, templateRol) => {
    if (!textValue) throw getLocalizedErrorString("nameNotValid");

    if (textValue.length < MIN_TEMPLATE_LENGTH) throw getLocalizedErrorString("templateMustHaveMinCharacters");

    if (textValue.length > MAX_TEMPLATE_LENGTH) throw getLocalizedErrorString("templateMustHaveMaxCharacters");

    if (templateRol === null || templateRol === "" || !templateRol)
      throw getLocalizedErrorString("mustSelectPermissions");
  };

  handleCloseDialog = () => {
    this.setState({ textValue: "", templateRol: "", error: null });

    this.props.handleCloseDialog();
  };
}

export default connect()(TemplateDialog);
