import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massivePayrollChangeAction } from "@icarius-pages/myPeople/actions";
import { getPayrollList } from "@icarius-pages/myPeople/selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const PayrollDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleAfterSubmit,
    handleClose,
  } = props;

  const [payrolls, setPayrolls] = useState([]);

  const dispatch = useDispatch();
  const payrollList = useSelector(getPayrollList);

  const handleSubmit = () => {
    if (payrolls.length) {
      dispatch(massivePayrollChangeAction({
        codes: employees.map(item => item.code),
        payrolls,
      }))
        .then((resp) => {
          if (resp.status === 200) {
            handleAfterSubmit(resp?.data?.showAlert);
          }
          handleClose();
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos una nómina', severity: "warning" }));
    }
  };

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Habilitación masiva en nóminas"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <div style={{ display: 'flex', margin: 10, marginTop: 0 }}>
            <Typography className="whiteText">
              {'Colaboradores seleccionados:'}
            </Typography>
            <Typography className="whiteText" style={{ marginLeft: 5, fontWeight: 600 }}>
              {employees.length}
            </Typography>
          </div>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-payroll`}>{"Nóminas"}</InputLabel>
              <Select
                multiple
                value={payrolls}
                labelId={`label-payroll`}
                id={`select-payroll`}
                onChange={(e) => setPayrolls(e.target.value)}
                margin={"none"}
              >
                {
                  payrollList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {'Antes de habilitar a los colaboradores seleccionados en las nóminas elegidas, considere que primero se eliminarán todas las habilitaciones en nóminas ya existentes para dichos colaboradores.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default PayrollDialog;
