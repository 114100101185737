import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { getIndividualPayrollChangeAction, updateIndividualPayrollChangeAction } from "@icarius-pages/myPeople/actions";
import { getPayrollList } from "@icarius-pages/myPeople/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const IndividualPayrollDialog = (props) => {

  const { open, loading, code, handleClose } = props;

  const dispatch = useDispatch();
  const payrollList = useSelector(getPayrollList);
  const [payrolls, setPayrolls] = useState([]);

  useEffect(() => {
    dispatch(getIndividualPayrollChangeAction(code))
      .then(resp => {
        if (resp?.data?.data) {
          setPayrolls(resp.data.data);
        }
      })
  }, [dispatch, code])

  const handleSubmit = () => {
    dispatch(updateIndividualPayrollChangeAction({
      code: code,
      payrolls,
    }))
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.data?.showAlert) {
            dispatch(openDialogAction({
              msg: (
                <>
                  Ha modificado la habilitación en nóminas y el colaborador existe en un proceso de cálculo actual. Es necesario recalcular el proceso en forma <b>total</b> si desea omitir a dicho colaborador del proceso.
                  <br />
                  <br />
                  Si lo ha deshabilitado de un tipo de nómina también puede utilizar la opción de <b>eliminar al colaborador</b> del proceso de cálculo sin necesidad de realizar un recálculo total.
                </>
              ),
              title: 'Atención',
              acceptOnly: true
            }));
          }
        }
        handleClose();
      });
  };

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {'Habilitación en nóminas'}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-payroll`}>{"Nóminas"}</InputLabel>
              <Select
                multiple
                value={payrolls}
                labelId={`label-payroll`}
                id={`select-payroll`}
                onChange={(e) => setPayrolls(e.target.value)}
                margin={"none"}
              >
                {
                  payrollList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default IndividualPayrollDialog;
