import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const ConfirmationDialog = (props) => {

  const {
    code,
    open,
    text,
    isJustified,
    handleClose,
    handleConfirm,
  } = props;

  const handleCloseDialog = () => {
    handleClose();
  };

  const handleConfirmAction = () => {
    handleConfirm(code);
    handleClose();
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("atention")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseDialog} />
      <DialogContent>
        <Typography
          style={isJustified ? { textAlign: "justify", textJustify: "inter-word" } : {}}
          className="whiteText" variant="h6" gutterBottom>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction
          onClick={handleCloseDialog}
          text={getLocalizedString("disagree")}
        />
        <ButtonDialogAction
          onClick={handleConfirmAction}
          isAccept
          text={getLocalizedString("agree")}
        />
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmationDialog;