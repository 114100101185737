import NAME from "./constants";

export const getIsLoading = store =>
    store[NAME].isLoading ||
    store[NAME].isLoadingEmployee ||
    store[NAME].isLoadingLocation ||
    store[NAME].isLoadingQueries ||
    store[NAME].isLoadingReceipts ||
    store[NAME].isLoadingManagementDivision ||
    store[NAME].isLoadingTemplates ||
    store[NAME].isLoadingMail;

export const getIsLoadingMail = store => store[NAME].isLoadingMail;
export const getIsLoadingQueries = store => store[NAME].isLoadingQueries;

export const getEmployees = store => store[NAME].employees;
export const getEditableFields = store => store[NAME].editableFields;
export const getFieldsToHide = store => store[NAME].fieldsToHide;
export const getSectionsToHide = store => store[NAME].sectionsToHide;
export const getAssumeEntryDate = store => store[NAME].assumeEntryDate;
export const getAdditionalValidationData = store => store[NAME].additionalValidationData;
export const getPayrollList = store => store[NAME].payrollList;
export const getManagementsList = store => store[NAME].managementsList;

export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;
export const getEmployeeData = store => store[NAME].employeeData;
export const getEmployeeOpinion = store => store[NAME].employeeOpinion;

export const getReceipts = store => store[NAME].receipts;
export const getQueries = store => store[NAME].queries;
export const getKpiData = store => store[NAME].kpiData;

export const getDepartments = store => store[NAME].departments;
export const getSections = store => store[NAME].sections;
export const getPhoto = store => store[NAME].photo;
export const getCounties = store => store[NAME].counties;
export const getProvinces = store => store[NAME].provinces;
export const getRegions = store => store[NAME].regions;

export const getOnboardingProcesses = store => store[NAME].onboardingProcesses;

//Templates
export const getTemplatesRows = store => store[NAME].templates;
export const gettingTemplatesRows = store => store[NAME].gettingTemplates;
export const getTemplateRequest = store => store[NAME].templateRequest;
export const gettingDeleteTemplatesRows = store => store[NAME].deleteTemplateRequest;