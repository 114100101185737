import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch } from "react-redux";
import { createIssuePermissionsBalanceAction } from "@icarius-pages/issuePermissions/actions";
import { formatDateYYYYMMDD } from "@icarius-utils/date";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreatePermissionAccountDialog = (props) => {

  const { open, loading, handleClose, employeeCode } = props;
  const [date, setDate] = useState(null);
  const [credit, setCredit] = useState('');
  const [debit, setDebit] = useState('');
  const [description, setDescription] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (date && (credit || debit) && description) {
      if ((credit && credit <= 0) || (debit && debit <= 0)) {
        dispatch(openSnackbarAction({ msg: 'Crédito y débito, en caso de tener valor, deben ser mayores a 0', severity: "error", duration: 10000 }));
        return;
      }

      dispatch(createIssuePermissionsBalanceAction({
        "Código de empleado": employeeCode,
        "Fecha de movimiento": formatDateYYYYMMDD(date),
        "Descripción": description,
        "Crédito": credit || 0,
        "Débito": debit || 0,
      }))
        .then((res) => {
          if (res?.status === 200) {
            handleClose();
          }
        });
      return;
    }
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cuenta corriente de permisos"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha de movimiento'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={date}
                  onChange={(e) => setDate(e)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required={!debit}
                label={'Crédito'}
                fullWidth
                margin={"none"}
                value={credit}
                type={'number'}
                inputProps={{ min: "0", step: "0.05" }}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setCredit(e.target.value);
                    setDebit('');
                  }
                }}
                onInput={e => {
                  setCredit(e.target.value);
                  setDebit('');
                }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required={!credit}
                label={'Débito'}
                fullWidth
                margin={"none"}
                value={debit}
                type={'number'}
                inputProps={{ min: "0", step: "0.05" }}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setDebit(e.target.value);
                    setCredit('');
                  }
                }}
                onInput={e => {
                  setDebit(e.target.value);
                  setCredit('');
                }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required
                label={'Descripción'}
                fullWidth
                margin={"none"}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreatePermissionAccountDialog;
