import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import Loader from "@icarius-common/loader";
import { downloadCertificateAction } from "@icarius-pages/certificates/actions";
import { getIsLoading, getReceiverList, getRegularCertificates } from "@icarius-pages/certificates/selectors";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const CertificateDialog = (props) => {

  const {
    open,
    code,
    handleClose,
  } = props;

  const [certificate, setCertificate] = useState('');
  const [receiver, setReceiver] = useState('');

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const certificateList = useSelector(getRegularCertificates);
  const receiverList = useSelector(getReceiverList);

  const requiresReceiverSelection = certificateList?.find((item) => item.code === certificate)?.requiresReceiverSelection || false;

  const handleSubmit = () => {
    if (!certificate) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un certificado', severity: 'error' }));
      return;
    }

    if (requiresReceiverSelection && !receiver) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un destinatario', severity: 'error' }));
      return;
    }

    dispatch(downloadCertificateAction(certificate, [code], receiver, true))
      .then(() => handleClose())
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("certificateFullTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <Loader open={isLoading} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={'label-certificate'}>
                  {getLocalizedString("selectTypeOfDocument")}
                </InputLabel>
                <Select
                  value={certificate}
                  labelId={'label-certificate'}
                  id={'select-certificate'}
                  onChange={(e) => {
                    setCertificate(e.target.value);
                    setReceiver("");
                  }}
                >
                  {
                    certificateList.map((item) => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.code}
                        value={item.code}
                      >
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              Boolean(certificate && requiresReceiverSelection) &&
              <Grid item xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-receiver`}>
                    {"Destinatario"}
                  </InputLabel>
                  <Select
                    value={receiver}
                    labelId={`label-receiver`}
                    id={`receiver`}
                    onChange={(e) => setReceiver(e.target.value)}
                  >
                    {
                      receiverList?.map((item) => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("generate")} />
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default CertificateDialog;