import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import NumberFormatCustom from '@icarius-pages/myRequestsManager/components/modalRequests/numberFormatCustom';
import { massiveSalaryChangeAction } from "@icarius-pages/myPeople/actions";

const SalaryDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleAfterSubmit,
    handleClose,
  } = props;

  const [salary, setSalary] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (salary && parseFloat(salary) >= 0) {
      dispatch(massiveSalaryChangeAction({
        codes: employees.map(item => item.code),
        salary,
      }))
        .then((resp) => {
          if (resp.status === 200) {
            handleAfterSubmit(resp?.data?.showAlert);
          }
          handleClose();
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Sueldo/salario es obligatorio y debe ser un valor positivo', severity: "warning" }));
    }
  };

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio masivo de sueldos/salarios"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <div style={{ display: 'flex' }}>
            <Typography className="whiteText">
              {'Colaboradores seleccionados:'}
            </Typography>
            <Typography className="whiteText" style={{ marginLeft: 5, fontWeight: 600 }}>
              {employees.length}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography className="whiteText">
              {'Moneda:'}
            </Typography>
            <Typography className="whiteText" style={{ marginLeft: 5, fontWeight: 600 }}>
              {employees[0].coin}
            </Typography>
          </div>
          <TextField
            style={{ marginTop: 10 }}
            fullWidth
            required
            label={"Nuevo sueldo/salario"}
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
            InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
          />
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default SalaryDialog;
