import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Canvas from "./canvas";
import { withStyles } from "@material-ui/core/styles";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { downloadBlob } from "@icarius-utils/download";
import moment from "moment";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
  },
})(Dialog);
const IMAGE_SIZE = 220;

const gridStyle = { padding: "0px 10px" };

const PhotoGridDialog = (props) => {

  const {
    open,
    employees,
    isLoading,
    handleClose,
  } = props;

  const [canvas, setCanvas] = useState(null);

  if (isLoading) return null;

  const draw = (ctx) => {
    const chunkSize = 3;
    for (let i = 0; i < employees.length; i += chunkSize) {
      const chunk = employees.slice(i, i + chunkSize);
      chunk.forEach((employee, column) => {
        const row = i / chunkSize;
        let img = new Image();
        img.src = employee.image ? IMAGES_ENDPOINT + employee.image : "";
        img.setAttribute('crossorigin', 'anonymous');
        img.onload = function () {
          if (img.height) {

            ctx.arc(
              50 + IMAGE_SIZE * column + 35,
              50 + IMAGE_SIZE * row + 50 * row,
              50,
              0,
              Math.PI * 2,
              true
            );
            ctx.save();
            ctx.beginPath();
            ctx.arc(
              75 + IMAGE_SIZE * column + 35,
              75 + IMAGE_SIZE * row + 50 * row,
              65,
              0,
              Math.PI * 2,
              false
            );
            ctx.clip();
            ctx.drawImage(
              img,
              IMAGE_SIZE * column + 35,
              IMAGE_SIZE * row + 50 * row,
              IMAGE_SIZE - 70,
              IMAGE_SIZE - 70,
            );
            ctx.restore();
            ctx.font = "13px Arial";

            const surnameString = employee.name.slice(0, employee.name.indexOf(",") + 1).trim();
            const surnameTextWidth = ctx.measureText(surnameString).width;
            ctx.fillText(
              surnameString,
              -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - surnameTextWidth / 2,
              IMAGE_SIZE * (1 + row) + 50 * row - 50
            );

            const nameString = employee.name.slice(employee.name.indexOf(",") + 1).trim();
            const nameTextWidth = ctx.measureText(nameString).width;
            ctx.fillText(
              nameString,
              -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - nameTextWidth / 2,
              IMAGE_SIZE * (1 + row) + 20 + 50 * row - 50
            );

            ctx.font = "bold 13px Arial";
            const positionString = employee.position;
            const positionTextWidth = ctx.measureText(positionString).width;
            ctx.fillText(
              positionString,
              -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - positionTextWidth / 2,
              IMAGE_SIZE * (1 + row) + 40 + 50 * row - 50
            );

            ctx.font = "11px Arial";
            const textString = employee.function;
            const textWidth = ctx.measureText(textString).width;
            ctx.fillText(
              textString,
              -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - textWidth / 2,
              IMAGE_SIZE * (1 + row) + 60 + 50 * row - 50
            );

            ctx.font = "11px Arial";
            const managementCenterString = employee.managementCenter;
            const managementCenterTextWidth = ctx.measureText(managementCenterString).width;
            ctx.fillText(
              managementCenterString,
              -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - managementCenterTextWidth / 2,
              IMAGE_SIZE * (1 + row) + 80 + 50 * row - 50
            );
          }
        };

        img.onerror = function () {
          ctx.fillStyle = "#FF0000";
          ctx.arc(
            50 + IMAGE_SIZE * column + 35,
            50 + IMAGE_SIZE * row + 50 * row,
            50,
            0,
            Math.PI * 2,
            true
          );
          ctx.save();
          ctx.beginPath();
          ctx.arc(
            75 + IMAGE_SIZE * column + 35,
            75 + IMAGE_SIZE * row + 50 * row,
            65,
            0,
            Math.PI * 2,
            false
          );
          ctx.fill();
          ctx.clip();
          ctx.restore();
          ctx.fillStyle = "#000000";
          ctx.font = "13px Arial";

          const surnameString = employee.name.slice(0, employee.name.indexOf(",") + 1).trim();
          const surnameTextWidth = ctx.measureText(surnameString).width;
          ctx.fillText(
            surnameString,
            -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - surnameTextWidth / 2,
            IMAGE_SIZE * (1 + row) + 50 * row - 50
          );

          const nameString = employee.name.slice(employee.name.indexOf(",") + 1).trim();
          const nameTextWidth = ctx.measureText(nameString).width;
          ctx.fillText(
            nameString,
            -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - nameTextWidth / 2,
            IMAGE_SIZE * (1 + row) + 20 + 50 * row - 50
          );

          ctx.font = "bold 13px Arial";
          const positionString = employee.position;
          const positionTextWidth = ctx.measureText(positionString).width;
          ctx.fillText(
            positionString,
            -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - positionTextWidth / 2,
            IMAGE_SIZE * (1 + row) + 40 + 50 * row - 50
          );

          ctx.font = "11px Arial";
          const textString = employee.function;
          const textWidth = ctx.measureText(textString).width;
          ctx.fillText(
            textString,
            -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - textWidth / 2,
            IMAGE_SIZE * (1 + row) + 60 + 50 * row - 50
          );

          ctx.font = "11px Arial";
          const managementCenterString = employee.managementCenter;
          const managementCenterTextWidth = ctx.measureText(managementCenterString).width;
          ctx.fillText(
            managementCenterString,
            -(IMAGE_SIZE / 2) + IMAGE_SIZE * (1 + column) - managementCenterTextWidth / 2,
            IMAGE_SIZE * (1 + row) + 80 + 50 * row - 50
          );
        };
      });
    }
  };

  const handlePreview = () => {
    if (canvas) {
      canvas.toBlob((blob) => window.open(URL.createObjectURL(blob), '_blank'));
    }
  }

  const handleDownload = () => {
    if (canvas) {
      canvas.toBlob((blob) => downloadBlob(blob, 'png', `Imagen con colaboradores_${moment().format("YYYYMMDD")}`));
    }
  }

  return (
    <StyledDialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Generar imagen con grupo de personas"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ maxHeight: 'calc(100vh - 200px)', backgroundColor: "#eaeaea" }}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <Canvas draw={draw} setCanvas={setCanvas} height={Math.ceil(employees.length / 3) * 270 + 50} width={675} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handlePreview} isAccept text={"Previsualizar"} />
          <ButtonDialogAction onClick={handleDownload} isAccept text={"Descargar"} />
        </DialogActions>
      </div>
    </StyledDialog>
  );
}

export default PhotoGridDialog;