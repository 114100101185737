import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
    numberComparatorMax2,
    numberFormatterMin2,
    numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myPeople],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myPeople],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myPeople],
        config:
        {
            headerName: "Acceso a ICARIUS",
            field: "HABILITAR EL ACCESO A ICARIUS",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("internalNumber"),
            field: "NRO INTERNO",
            filter: "agTextColumnFilter",
            cellClass: "stringType"
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("fType"),
            field: "TIPO DE FUNCIONARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Obra o Faena',
            field: "OBRA O FAENA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Centro de costo de gestión',
            field: "CENTRO DE COSTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("email"),
            field: "EMAIL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Email personal',
            field: "EMAIL PERSONAL",
            filter: "agTextColumnFilter",
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Modalidad de trabajo',
            field: "MODALIDAD DE TRABAJO",
            filter: "agSetColumnFilter",
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("cellphone"),
            field: "CELULAR",
            filter: "agSetColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["CELULAR"] || getLocalizedString("notAvailable"))) || "";
            },
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("outcomeDate"),
            field: "FECHA DE EGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: "Fecha asignación al cargo",
            field: "FECHA ASIGNACION CARGO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Fecha asignación a la unidad de negocio',
            field: "FECHA ASIGNACION DIVISION",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: "Fecha asignación a la función",
            field: "FECHA ASIGNACION FUNCION",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: "Fecha asignación lugar de trabajo",
            field: "FECHA ASIGNACION LUGAR DE TRABAJO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: "Fecha asignación centro de costo",
            field: "FECHA ASIGNACION CENTRO DE COSTO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: "Fecha de nacimiento",
            field: "FECHA DE NACIMIENTO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: "Fecha asignación al turno",
            field: "FECHA ASIGNACION TURNO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Motivo de término legal',
            field: "MOTIVO TERMINO LEGAL",
            filter: "agSetColumnFilter",
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Motivo de término cálculo',
            field: "MOTIVO TERMINO CALCULO",
            filter: "agSetColumnFilter",
            hide: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("function"),
            field: "FUNCION",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("category"),
            field: "CATEGORIA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("specialty"),
            field: "ESPECIALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("civilState"),
            field: "ESTADO CIVIL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("sex"),
            field: "SEXO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("nationality"),
            field: "NACIONALIDAD",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("comuna"),
            field: "COMUNA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("region"),
            field: "REGION",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "TIPO DE CONTRATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Unidad de negocio',
            field: "DIVISION",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Sección',
            field: "SECCION",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("salaryUnit"),
            field: "UNIDAD SALARIAL",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Tipo de jornada',
            field: "TIPO DE JORNADA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Horas diarias contrato',
            field: "HORAS DIARIAS CONTRATO",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Horas semanales contrato',
            field: "HORAS SEMANALES CONTRATO",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: 'Horas mensuales contrato',
            field: "HORAS MENSUALES CONTRATO",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("coin"),
            field: "MONEDA",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            hide: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("salary"),
            field: "SUELDO BASICO",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            hide: true,
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "SINDICATO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config:
        {
            headerName: getLocalizedString("shift"),
            field: "TURNO",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config:
        {
            headerName: "Tipo de calendario",
            field: "TIPO DE CALENDARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config:
        {
            headerName: "Tipo de control horario",
            field: "TIPO DE CONTROL HORARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myPeople],
        config: {
            headerName: getLocalizedString("contratista"),
            field: "CONTRATISTA",
            filter: "agSetColumnFilter",
            hide: true,
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        },
    },
]