import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  workplaces: [],
  turns: [],
  employees: [],
  shifts: [],
  schedules: [],
  openCalendars: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...initialState, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        workplaces: action.payload.workplaces,
        turns: action.payload.turns,
        employees: action.payload.employees,
        shifts: action.payload.shifts,
        schedules: action.payload.schedules,
        openCalendars: action.payload.openCalendars,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        workplaces: action.payload.workplaces,
        turns: action.payload.turns,
        employees: action.payload.employees,
        shifts: action.payload.shifts,
        schedules: action.payload.schedules,
        openCalendars: action.payload.openCalendars,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
