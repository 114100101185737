import { useState } from "react";
import * as yup from 'yup';
import { formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (additionalContractTypes, callback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'Fecha de término de contrato para el contrato actual') {
        return null;
      }

      if (fieldName === 'Fecha de contratación') {
        return null;
      }

      if (fieldName === 'Fecha término de contrato') {
        return null;
      }

      return "";
    }

    const fieldNames = [
      'Fecha de término de contrato para el contrato actual',
      'Motivo de término del contrato actual',
      'Tipo de funcionario',
      'Tipo de contrato',
      'Código de contrato',
      'Tipo de contratación',
      'Tipo de jornada',
      'Horas diarias contrato',
      'Horas semanales contrato',
      'Horas mensuales contrato',
      'Personal de planta',
      'Contratista',
      'Obra o faena',
      'Fecha de contratación',
      'Motivo de contratación',
      'Fecha término de contrato',
      'Comentarios',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Fecha término de contrato': yup.date().nullable(true)
        .when('Tipo de contratación', {
          is: (value) => value === '2',
          then: yup.date().required()
        }),
      'Obra o faena': yup.string()
        .when('Tipo de contratación', {
          is: (value) => value === '3' || value === '5',
          then: yup.string().notOneOf(['-']).required()
        }),
      'Contratista': yup.string()
        .when('Tipo de funcionario', {
          is: (value) => value === 'SUB',
          then: yup.string().notOneOf(['-']).required()
        }),
      'Fecha de término de contrato para el contrato actual': yup.date().required(),
      'Motivo de término del contrato actual': yup.string().required(),
      'Motivo de contratación': yup.string().required(),
      'Tipo de funcionario': yup.string().required(),
      'Tipo de contrato': yup.string().required(),
      'Tipo de jornada': yup.string().required(),
      'Horas diarias contrato': yup.string().required(),
      'Horas semanales contrato': yup.string().required(),
      'Horas mensuales contrato': yup.string().required(),
      'Fecha de contratación': yup.date().required(),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const getDateFormattedForBackend = (value) => {
    return value instanceof Date ? formatDateYYYYMMDD(value) : ''
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        'Fecha de término de contrato para el contrato actual': getDateFormattedForBackend(formData['Fecha de término de contrato para el contrato actual']),
        'Fecha de contratación': getDateFormattedForBackend(formData['Fecha de contratación']),
        'Fecha término de contrato': getDateFormattedForBackend(formData['Fecha término de contrato']),
      };

      callback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'Tipo de funcionario') {
      if (value === 'SUB') {
        setFormData({
          ...formData,
          [fieldName]: value,
          'Personal de planta': 'No',
        });
        return;
      }
      if (value !== 'SUB') {
        setFormData({
          ...formData,
          [fieldName]: value,
          'Personal de planta': 'Si',
          'Contratista': "-",
        });
        return;
      }
    }

    if (fieldName === 'Tipo de contrato') {
      setFormData({
        ...formData,
        [fieldName]: value,
        'Tipo de contratación': additionalContractTypes.find(item => item.key === value)?.key || '',
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
