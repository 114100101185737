import React from "react";
import { useSelector } from "react-redux";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import EmployeeVacationsDialogContent from "@icarius-pages/vacations/components/employeeVacationsDialog/content";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import {
  getEmployeeMiscData,
  getDateFormat,
  getEmployeeDebitData,
  getEmployeeCreditData,
  getVacationsSchema,
} from "@icarius-pages/vacations/selectors";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const VacationsAccountDialog = (props) => {

  const {
    open,
    handleClose,
    employeeCode,
    employeeName,
  } = props;

  const dateFormat = useSelector(getDateFormat);
  const debitData = useSelector(getEmployeeDebitData);
  const creditData = useSelector(getEmployeeCreditData);
  const miscData = useSelector(getEmployeeMiscData);
  const vacationsSchema = useSelector(getVacationsSchema);

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <EmployeeVacationsDialogContent
        dateFormat={dateFormat}
        debitData={debitData}
        miscData={miscData}
        creditData={creditData}
        employeeCode={employeeCode}
        vacationsSchema={vacationsSchema}
        employeeName={employeeName}
      />
    </StyledDialog>
  );
}

export default VacationsAccountDialog;
