import React, { useState } from "react";
import {
  getAppColor,
  getUserData,
  getIsExportingGrid,
  getManagePermissions,
} from "src/app/selectors";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { getUserDates, getIsLoading as getIsLoadingAnnotations } from "@icarius-pages/annotationsPage/selectors";
import AnnotationsPage from "@icarius-pages/annotationsPage/components/index";
import CommonPage from "@icarius-common/commonPage";
import {
  getIsLoading,
  getPhoto,
  getQueries,
  getKpiData,
  getEditableFields,
} from "../../selectors";
import {
  likeEmployeeAction,
  updateEmployeeInServer,
  createEmployeeInServer,
  getAvailableReceiptsForEmployee,
  uploadProfileImageAction,
  getQueriesGivenLevelAction,
} from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getRegularCertificatesAndCollaboratorsAction } from "@icarius-pages/certificates/actions";
import {
  getIsLoading as getIsLoadingCertif,
} from "@icarius-pages/certificates/selectors";
import ChangeProfileImageDialog from "@icarius-pages/myPersonalData/components/changeProfileImageDialog";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import {
  Folder,
  VisibilityIcon,
  FamilyGroupIcon,
  IndividualPayrollIcon,
} from "@icarius-icons";
import ResultGridDialog from "@icarius-common/automaticProcesses/components/resultGridDialog";
import PickTemplateDialog from "@icarius-pages/employeesProcesses/components/dialogs/pickTemplateDialog";
import { MyPeopleSchedule } from "@icarius-pages/myPeopleSchedule";
import { FamilyGroup } from "@icarius-pages/familyGroup";
import { HistoricalPeriods } from "@icarius-pages/historicalPeriods";
import { DistributionCost } from "@icarius-pages/distributionCost";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { AnnexesPage } from "@icarius-pages/annexesPage";
import { getEmployeeVacationsAction } from "@icarius-pages/vacations/actions";
import { getIsLoading as getIsLoadingVacations } from "@icarius-pages/vacations/selectors";
import Loans from "@icarius-pages/loans/components";
import { getIssuePermissionsBalanceAction } from "@icarius-pages/issuePermissions/actions";
import { getBalanceData, getLoading as getIsLoadingPermissionAccount } from "@icarius-pages/issuePermissions/selectors";
import { IssueSummary } from "@icarius-pages/issueSummary";
import { AssetsDiscountsSummary } from "@icarius-pages/assetsDiscountsSummary";
import SelectProcessDialog from "@icarius-common/automaticProcesses/components/selectProcessDialog";
import { RESET_STATE } from "@icarius-pages/myPeopleSchedule/actionTypes";
import { MedalTable } from "@icarius-common/medalTable";
import { useDispatch, useSelector } from "react-redux";
import useAutomaticProcesses from "@icarius-common/automaticProcesses/useAutomaticProcesses";
import IndividualPayrollDialog from "../dialog/individualPayrollDialog";
import VacationsAccountDialog from "../dialog/vacationsAccountDialog";
import PermissionsAccountDialog from "../dialog/permissionsAccountDialog/permissionsAccountDialog";
import QueriesDialog from "../dialog/queriesDialog";
import UserSummary from "./userSummary";
import UserForm from "./userForm";
import CertificateDialog from "./certificateDialog";
import ReceiptDialog from "./receiptDialog";
import paths from "@icarius-localization/paths";
import { getAnnotationsAction } from "@icarius-pages/annotationsPage/actions";

const UserPage = (props) => {

  const {
    employee,
    isPeopleMaster,
    isDuplicate,
    handleCloseUserPage,
  } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const isLoadingCertif = useSelector(getIsLoadingCertif);
  const isLoadingAnnotations = useSelector(getIsLoadingAnnotations);
  const isLoadingPermissions = useSelector(getIsLoadingPermissionAccount);
  const isLoadingVacations = useSelector(getIsLoadingVacations);

  const color = useSelector(getAppColor);
  const userData = useSelector(getUserData);
  const photo = useSelector(getPhoto);
  const userDates = useSelector(getUserDates);
  const isExportinGrid = useSelector(getIsExportingGrid);
  const permissionAccountData = useSelector(getBalanceData);
  const userManagePermissions = useSelector(getManagePermissions);
  const queries = useSelector(getQueries);
  const kpiData = useSelector(getKpiData);
  const editableFields = useSelector(getEditableFields);

  const [changePhotoIsOpen, setChangePhotoIsOpen] = useState(false);
  const [tempPhoto, setTempPhoto] = useState(null);

  const [showMyPeopleSchedule, setShowMyPeopleSchedule] = useState(false);
  const [showFamilyGroup, setShowFamilyGroup] = useState(false);
  const [payrollDialogIsOpen, setPayrollDialogIsOpen] = useState(false);
  const [historicProcessesIsOpen, setHistoricProcessesIsOpen] = useState(false);
  const [summaryMenuAnchor, setSummaryMenuAnchor] = useState(null);
  const [issueSummaryIsOpen, setIssueSummaryIsOpen] = useState(false);
  const [assetsDiscountsSummaryIsOpen, setAssetsDiscountsSummaryIsOpen] = useState(false);
  const [annotationsIsOpen, setAnnotationsIsOpen] = useState(false);
  const [digitalFolderIsOpen, setDigitalFolderIsOpen] = useState(false);
  const [massiveMenuAnchor, setMassiveMenuAnchor] = useState(null);
  const [payrollMenuAnchor, setPayrollMenuAnchor] = useState(null);
  const [vacationsAccountDialogIsOpen, setVacationsAccountDialogIsOpen] = useState(false);
  const [loansAccountDialogIsOpen, setLoansAccountDialogIsOpen] = useState(false);
  const [permissionsAccountDialogIsOpen, setPermissionsAccountDialogIsOpen] = useState(false);
  const [distributionCostIsOpen, setDistributionCostIsOpen] = useState(false);
  const [queriesDialogIsOpen, setQueriesDialogIsOpen] = useState(false);
  const [medalTableDialogIsOpen, setMedalTableDialogIsOpen] = useState(false);
  const [certificateDialogIsOpen, setCertificateDialogIsOpen] = useState(false);
  const [receiptDialogIsOpen, setReceiptDialogIsOpen] = useState(false);

  const isNewEmployee = !employee;
  const isSameUser = employee?.["CODIGO DE EMPLEADO"] === userData?.code;

  const {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  } = useAutomaticProcesses(!isPeopleMaster);

  const handleOpenVacationsAccount = () => {
    setMassiveMenuAnchor(null);
    dispatch(getEmployeeVacationsAction(userData.level, employee["CODIGO DE EMPLEADO"]))
      .then((res) => {
        if (res?.status === 200) {
          setVacationsAccountDialogIsOpen(true);
        }
      });
  }

  const handleOpenLoansAccount = () => {
    setLoansAccountDialogIsOpen(true);
    setMassiveMenuAnchor(null);
  }

  const handleOpenPermissionAccount = () => {
    setMassiveMenuAnchor(null);
    dispatch(getIssuePermissionsBalanceAction(employee["CODIGO DE EMPLEADO"]))
      .then((resp) => {
        if (resp?.status === 200) {
          setPermissionsAccountDialogIsOpen(true);
        }
      })
  }

  const handleSetTempEmployeePhoto = (data) => {
    setTempPhoto(data);
    dispatch(openSnackbarAction({ msg: getLocalizedString("creationUserPhotoInfo"), severity: "info" }));
  }

  const handleOpenChangePhotoDialog = () => {
    setChangePhotoIsOpen(true);
  };

  const handleCloseChangePhotoDialog = () => {
    setChangePhotoIsOpen(false);
  }

  const handleOpenCertificate = () => {
    dispatch(getRegularCertificatesAndCollaboratorsAction())
      .then((resp) => {
        if (resp?.status === 200) {
          if (
            Boolean(resp?.data?.colaborators?.find((emp) => emp["CODIGO DE EMPLEADO"] === employee["CODIGO DE EMPLEADO"]))
            && Boolean(resp?.data?.certificates?.length)
          ) {
            setCertificateDialogIsOpen(true);
          } else {
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("certificatesNotAvailable"), severity: "error" }));
          }
        }
      })
  }

  const handleOpenReceipt = () => {
    dispatch(getAvailableReceiptsForEmployee(employee["CODIGO DE EMPLEADO"]))
      .then((resp) => {
        if (resp?.status === 200) {
          if (!resp?.data?.receipts?.length) {
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("receiptsNotAvailable"), severity: "error" }));
            return;
          }

          setReceiptDialogIsOpen(true);
        }
      })
  }

  const handleOpenAnnotations = () => {
    dispatch(getAnnotationsAction(employee["CODIGO DE EMPLEADO"]))
      .then((resp) => {
        if (resp?.status === 200) {
          setAnnotationsIsOpen(true);
        }
      })
  }

  const handleOpenQueries = () => {
    dispatch(getQueriesGivenLevelAction(
      employee["CODIGO DE EMPLEADO"],
      (isPeopleMaster ? paths.employeeMaster : paths.myPeople).replace('/', ''),
    ))
      .then((resp) => {
        if (resp?.status === 200) {
          if (!resp?.data?.data?.length && !resp?.data?.kpiData?.length) {
            dispatch(openSnackbarAction({ msg: "No hay consultas o KPI disponibles", severity: "error" }));
            return;
          }

          setQueriesDialogIsOpen(true)
        }
      })
  }

  const handleUpdateEmployee = (employee) => {
    dispatch(updateEmployeeInServer({
      ...employee,
      old_codemp: employee['CODIGO DE EMPLEADO'],
    }));
  }

  const handleCreateEmployee = (employee) => {
    let dataToSend = { ...employee };

    if (isDuplicate) {
      dataToSend = {
        ...dataToSend,
        origin_codemp: employee['CODIGO DE EMPLEADO'],
      }
    }

    dispatch(createEmployeeInServer(dataToSend))
      .then((resp) => {
        if (resp?.status === 200) {
          if (tempPhoto) {
            dispatch(uploadProfileImageAction(tempPhoto, employee["CODIGO DE EMPLEADO"]))
              .then((resp) => {
                if (resp?.status === 200) {
                  handleCloseUserPage();
                }
              });
          } else {
            handleCloseUserPage();
          }
        }
      });
  }

  const handleLikeRequest = (id, like, oldValue) => {
    if (!isSameUser) {
      dispatch(likeEmployeeAction(id, like, oldValue));
    }
  };

  const closeMyPeopleSchedule = () => {
    setShowMyPeopleSchedule(false);
    dispatch({ type: RESET_STATE });
  }

  const executeItem = () => (
    automaticProcessesIsEnabled && !isNewEmployee && !isSameUser && isPeopleMaster &&
    <CustomIconButton
      title={`Calcular${processList.length === 1 ? `: ${processList[0].value}` : ' proceso'}`}
      onClick={() => handleExecute([employee["CODIGO DE EMPLEADO"]])}
      type={"execute"}
    />
  )

  const viewResultItem = () => (
    automaticProcessesIsEnabled && !isNewEmployee && !isSameUser && isPeopleMaster &&
    <CustomIconButton
      title={"Ver resultado"}
      onClick={() => handleViewResult(employee, employee["CODIGO DE EMPLEADO"])}
    >
      <VisibilityIcon />
    </CustomIconButton>
  )

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCloseUserPage}
      type={"goBack"}
    />

  const digitalFolderItem = () => !isNewEmployee && !isSameUser &&
    <CustomIconButton
      title={getLocalizedString("annex")}
      onClick={() => setDigitalFolderIsOpen(true)}
    >
      <Folder />
    </CustomIconButton>

  const annotationsItem = () => !isNewEmployee && !isSameUser &&
    <CustomIconButton
      title={getLocalizedString("annotations")}
      onClick={handleOpenAnnotations}
      type={"annotations"}
    />

  const myPeopleScheduleItem = () => !isNewEmployee && !isSameUser &&
    <CustomIconButton
      title={"Ver calendario"}
      onClick={() => setShowMyPeopleSchedule(true)}
      type={"calendar"}
    />

  const familyGroupItem = () => isPeopleMaster && !isNewEmployee &&
    <CustomIconButton
      title={"Grupo familiar"}
      onClick={() => setShowFamilyGroup(true)}
    >
      <FamilyGroupIcon />
    </CustomIconButton>

  const medalTableItem = () => !isNewEmployee &&
    <CustomIconButton
      title={"Medallero"}
      onClick={() => setMedalTableDialogIsOpen(true)}
      type="medalTable"
    />

  const payrollItem = () => isPeopleMaster && !isNewEmployee &&
    <>
      <CustomIconButton
        title={'Nóminas e históricos'}
        onClick={(e) => setPayrollMenuAnchor(e.currentTarget)}
      >
        <IndividualPayrollIcon />
      </CustomIconButton>
      <StyledHelpMenu
        anchorEl={payrollMenuAnchor}
        isOpen={Boolean(payrollMenuAnchor)}
        onClose={() => setPayrollMenuAnchor(null)}
      >
        {
          !isNewEmployee && !isSameUser && isPeopleMaster &&
          <MenuItemWithIcon
            text={'Distribución de costos'}
            onClick={() => {
              setDistributionCostIsOpen(true);
              setPayrollMenuAnchor(null)
            }}
          />
        }
        <MenuItemWithIcon
          text={"Habilitación en nóminas"}
          onClick={() => {
            setPayrollDialogIsOpen(true);
            setPayrollMenuAnchor(null);
          }}
        />
        <MenuItemWithIcon
          text={'Procesos históricos'}
          onClick={() => {
            setHistoricProcessesIsOpen(true);
            setPayrollMenuAnchor(null);
          }}
        />
      </StyledHelpMenu>
    </>

  const summaryItem = () => !isNewEmployee && !isSameUser && isPeopleMaster &&
    <>
      <CustomIconButton
        title={'Haberes, descuentos e incidencias'}
        onClick={(e) => setSummaryMenuAnchor(e.currentTarget)}
      >
        <ListAltIcon />
      </CustomIconButton>
      <StyledHelpMenu
        anchorEl={summaryMenuAnchor}
        isOpen={Boolean(summaryMenuAnchor)}
        onClose={() => setSummaryMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Resumen de incidencias'}
          onClick={() => {
            setSummaryMenuAnchor(null);
            setIssueSummaryIsOpen(true);
          }}
        />
        <MenuItemWithIcon
          text={'Resumen de haberes y descuentos'}
          onClick={() => {
            setSummaryMenuAnchor(null);
            setAssetsDiscountsSummaryIsOpen(true);
          }}
        />

      </StyledHelpMenu>
    </>

  const accountsItem = () => !isNewEmployee && !isSameUser &&
    <>
      <CustomIconButton
        title={'Cuentas corrientes'}
        onClick={(e) => setMassiveMenuAnchor(e.currentTarget)}
      >
        <ListIcon />
      </CustomIconButton>
      <StyledHelpMenu
        anchorEl={massiveMenuAnchor}
        isOpen={Boolean(massiveMenuAnchor)}
        onClose={() => setMassiveMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Vacaciones'}
          onClick={handleOpenVacationsAccount}
        />
        {
          isPeopleMaster &&
          <MenuItemWithIcon
            text={'Préstamos'}
            onClick={handleOpenLoansAccount}
          />
        }
        {
          userManagePermissions &&
          <MenuItemWithIcon
            text={'Permisos'}
            onClick={handleOpenPermissionAccount}
          />
        }
      </StyledHelpMenu>
    </>

  const customItems = isDuplicate ?
    [goBackItem]
    :
    [
      goBackItem,
      executeItem,
      viewResultItem,
      myPeopleScheduleItem,
      digitalFolderItem,
      annotationsItem,
      familyGroupItem,
      payrollItem,
      accountsItem,
      summaryItem,
      medalTableItem,
    ];

  const hideCommonPage = (
    showMyPeopleSchedule ||
    showFamilyGroup ||
    historicProcessesIsOpen ||
    distributionCostIsOpen ||
    loansAccountDialogIsOpen ||
    digitalFolderIsOpen ||
    issueSummaryIsOpen ||
    assetsDiscountsSummaryIsOpen ||
    annotationsIsOpen
  );

  const isLoadingAnything = (
    isLoading ||
    isLoadingCertif ||
    isLoadingAnnotations ||
    isLoadingPermissions ||
    isLoadingVacations ||
    automaticProcessesIsLoading
  );

  return (
    <>
      {
        certificateDialogIsOpen &&
        <CertificateDialog
          open={certificateDialogIsOpen}
          code={employee["CODIGO DE EMPLEADO"]}
          handleClose={() => setCertificateDialogIsOpen(false)}
        />
      }
      {
        receiptDialogIsOpen &&
        <ReceiptDialog
          open={receiptDialogIsOpen}
          code={employee["CODIGO DE EMPLEADO"]}
          handleClose={() => setReceiptDialogIsOpen(false)}
        />
      }
      {
        showMyPeopleSchedule &&
        <MyPeopleSchedule
          presetCode={employee["CODIGO DE EMPLEADO"]}
          presetHandleGoBack={closeMyPeopleSchedule}
        />
      }
      {
        showFamilyGroup &&
        <FamilyGroup
          code={employee["CODIGO DE EMPLEADO"]}
          name={employee["APELLIDO Y NOMBRE"]}
          handleGoBack={() => setShowFamilyGroup(false)}
        />
      }
      {
        payrollDialogIsOpen &&
        <IndividualPayrollDialog
          open={payrollDialogIsOpen}
          loading={isLoadingAnything}
          code={employee["CODIGO DE EMPLEADO"]}
          handleClose={() => setPayrollDialogIsOpen(false)}
        />
      }
      {
        historicProcessesIsOpen &&
        <HistoricalPeriods
          code={employee["CODIGO DE EMPLEADO"]}
          name={employee["APELLIDO Y NOMBRE"]}
          handleGoBack={() => setHistoricProcessesIsOpen(false)}
        />
      }
      {
        issueSummaryIsOpen &&
        <IssueSummary
          code={employee["CODIGO DE EMPLEADO"]}
          name={employee["APELLIDO Y NOMBRE"]}
          handleGoBack={() => setIssueSummaryIsOpen(false)}
        />
      }
      {
        assetsDiscountsSummaryIsOpen &&
        <AssetsDiscountsSummary
          code={employee["CODIGO DE EMPLEADO"]}
          name={employee["APELLIDO Y NOMBRE"]}
          handleGoBack={() => setAssetsDiscountsSummaryIsOpen(false)}
        />
      }
      {
        distributionCostIsOpen &&
        <DistributionCost
          code={employee["CODIGO DE EMPLEADO"]}
          name={employee["APELLIDO Y NOMBRE"]}
          handleGoBack={() => setDistributionCostIsOpen(false)}
        />
      }
      {
        annotationsIsOpen &&
        <AnnotationsPage
          employee={employee}
          userDates={userDates}
          handleClose={() => setAnnotationsIsOpen(false)}
        />
      }
      {
        medalTableDialogIsOpen &&
        <MedalTable
          userCode={employee["CODIGO DE EMPLEADO"]}
          handleClose={() => setMedalTableDialogIsOpen(false)}
          dialog
        />
      }
      {
        vacationsAccountDialogIsOpen && !isLoadingVacations &&
        <VacationsAccountDialog
          open={vacationsAccountDialogIsOpen}
          employeeCode={employee["CODIGO DE EMPLEADO"]}
          employeeName={employee["APELLIDO Y NOMBRE"]}
          handleClose={() => setVacationsAccountDialogIsOpen(false)}
        />
      }
      {
        loansAccountDialogIsOpen &&
        <Loans
          employeeCode={employee["CODIGO DE EMPLEADO"]}
          employeeName={employee["APELLIDO Y NOMBRE"]}
          handleGoBack={() => setLoansAccountDialogIsOpen(false)}
        />
      }
      {
        permissionsAccountDialogIsOpen && !isLoadingPermissions &&
        <PermissionsAccountDialog
          open={permissionsAccountDialogIsOpen}
          color={color}
          data={permissionAccountData}
          employeeCode={employee["CODIGO DE EMPLEADO"]}
          employeeName={employee["APELLIDO Y NOMBRE"]}
          loading={isLoadingPermissions}
          handleClose={() => setPermissionsAccountDialogIsOpen(false)}
          isCRUD
        />
      }
      {
        digitalFolderIsOpen &&
        <AnnexesPage
          employeeCode={employee["CODIGO DE EMPLEADO"]}
          employeeName={employee["APELLIDO Y NOMBRE"]}
          handleClose={() => setDigitalFolderIsOpen(false)}
        />
      }
      {
        queriesDialogIsOpen &&
        <QueriesDialog
          open={queriesDialogIsOpen}
          queries={queries}
          employeeCode={employee["CODIGO DE EMPLEADO"]}
          kpiData={kpiData}
          handleClose={() => setQueriesDialogIsOpen(false)}
        />
      }
      <div style={{ display: hideCommonPage ? "none" : "block" }}>
        <CommonPage
          title={isPeopleMaster ? getLocalizedString("employeeMaster") : getLocalizedString("myPeopleTableTitle")}
          menuItems={customItems}
          isLoading={isLoadingAnything}
          isNotGridPage
        >
          <div id={"main-content"} style={{ padding: 50, paddingTop: 30 }}>
            <UserSummary
              isNewEmployee={isNewEmployee || isDuplicate}
              isSameUser={isSameUser}
              isPhotoModificable={isPeopleMaster}
              employee={employee}
              photo={photo || employee?.["FOTOGRAFIA"]} // photo es la nueva (ya guardada en el sv) pero que todavia no se setea en employee que es un ojeto viejo
              editableFields={editableFields}
              handleLikeRequest={handleLikeRequest}
              handleOpenCertificate={handleOpenCertificate}
              handleOpenReceipt={handleOpenReceipt}
              handleOpenChangePhotoDialog={handleOpenChangePhotoDialog}
              handleOpenQueries={handleOpenQueries}
            />
            <UserForm
              data={employee}
              isDuplicate={isDuplicate}
              handleCreateEmployee={handleCreateEmployee}
              handleUpdateEmployee={handleUpdateEmployee}
            />
          </div>
          {
            changePhotoIsOpen &&
            <ChangeProfileImageDialog
              open={changePhotoIsOpen}
              isPeopleMaster={isPeopleMaster}
              isNewEmployee={isNewEmployee}
              handleSubmit={handleSetTempEmployeePhoto}
              profileImage={isDuplicate ? null : (photo || employee?.["FOTOGRAFIA"] || null)}
              userCode={employee?.["CODIGO DE EMPLEADO"]}
              handleClose={handleCloseChangePhotoDialog}
            />
          }
          {
            processSelectionDialogIsOpen &&
            <SelectProcessDialog
              open={processSelectionDialogIsOpen}
              title={isExecution ? "Selección de proceso a ejecutar" : "Selección de proceso a consultar"}
              processList={processList}
              handleClose={handleCloseProcessDialogSelection}
              handleExecute={isExecution ? executeProcess : viewResult}
            />
          }
          {
            resultDialogIsOpen && !isLoadingAnything && !isExportinGrid &&
            <ResultGridDialog
              open={resultDialogIsOpen}
              templates={resultData.templates}
              process={resultData.process}
              employeeName={resultData.employee['APELLIDO Y NOMBRE']}
              handleClose={handleCloseResultDialog}
              handleOpenPickTemplateDialog={handleOpenPickTemplateDialog}
              handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
            />
          }
          {
            resultData?.templates?.length > 0 && pickTemplateDialogIsOpen && !isLoadingAnything && !isExportinGrid &&
            <PickTemplateDialog
              open={pickTemplateDialogIsOpen}
              templates={resultData.templates}
              handleConfirm={handleGenerateDocumentsGivenTemplate}
              handleClose={handleClosePickTemplateDialog}
            />
          }
        </CommonPage>
      </div>
    </>
  )
}

export default UserPage;