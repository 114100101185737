import React, { useState, useEffect } from 'react';
import { formatDate } from '@mobiscroll/react';
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getAppColor, getUserData } from 'src/app/selectors';
import { calendarTypeColors } from '@icarius-utils/colors';
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { formatDateHHMM } from '@icarius-utils/date';
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";

const onMediaFallback = event => event.target.src = DefaultImage;

const useGenericSchedule = (schedules, initialShifts, showShortTitle, extrasFilter, employees) => {

  const [isRequest, setIsRequest] = useState(false);
  const [hasAnotherExtra, setHasAnotherExtra] = useState(false);
  const [isExtra, setIsExtra] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [tempShift, setTempShift] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [headerText, setHeader] = useState('');
  const [title, setTitle] = useState('');
  const [shortTitle, setShortTitle] = useState('');
  const [shiftDate, setDates] = useState([]);
  const [extraTime, setExtraTime] = useState();
  const [originalExtraTime, setOriginalExtraTime] = useState();

  const color = useSelector(getAppColor);
  const userData = useSelector(getUserData);
  const dispatch = useDispatch();

  //setear los iniciales, setear el color y el tooltip
  useEffect(() => {
    setShifts(() => initialShifts.map(item => ({ ...item, color: calendarTypeColors[item.type], tooltip: item.title })));
  }, [initialShifts, extrasFilter])

  // cambiar el tooltip a usar segun el tipo de title
  useEffect(() => {
    setShifts((prev) => prev.map(item => ({ ...item, tooltip: showShortTitle ? (item.shortTitle || item.title) : item.title })));
  }, [showShortTitle])

  const renderScheduleEventContent = (args) => {
    const { original } = args;
    return showShortTitle ? (original.shortTitle || original.title) : original.title;
  }

  const getNewEventToSave = (acceptsRequest) => {
    const start = new Date(shiftDate[0]);
    const end = new Date(shiftDate[1]);

    if (isExtra) {
      return {
        id: tempShift.id,
        title: `${formatDateHHMM(extraTime)} hrs Ext. autorizadas`,
        tooltip: `${formatDateHHMM(extraTime)} hrs Ext. autorizadas`,
        start: start,
        end: end,
        resource: tempShift.resource,
        type: 'extraAuthorized',
        color: calendarTypeColors.extraAuthorized,
        timeAlreadyAuth: formatDateHHMM(extraTime),
      };
    }

    if (isRequest) {
      return {
        id: tempShift.id,
        title: title,
        shortTitle: shortTitle,
        tooltip: showShortTitle ? shortTitle : title,
        start: start,
        end: end,
        resource: tempShift.resource,
        type: acceptsRequest ? 'requestAccepted' : 'requestRejected',
        color: calendarTypeColors.requestAccepted,
        scheduleId: schedule,
      };
    }

    return {
      id: tempShift.id,
      title: title,
      start: start,
      end: end,
      resource: tempShift.resource,
      type: 'exception',
      color: calendarTypeColors.exception,
      scheduleId: schedule,
      shortTitle: shortTitle,
      tooltip: showShortTitle ? shortTitle : title,
    };
  }

  const saveEvent = (acceptsRequest) => {
    //si no es extra ni request (es exception) y no tiene schedule, cancelar
    if (!isExtra && !isRequest && !schedule) return;

    const newEvent = getNewEventToSave(acceptsRequest);

    if (acceptsRequest) {
      dispatch(openSnackbarAction({ msg: 'Solicitud aprobada, recuerde guardar los cambios', severity: "info" }));
    }

    if (isEdit) {
      // update the event in the list
      const index = shifts.findIndex(x => x.id === tempShift.id);;
      const newEventList = [...shifts];

      newEventList.splice(index, 1, newEvent);
      setShifts(newEventList);
    } else {
      // add the new event to the list
      setShifts([...shifts, newEvent]);
    }
    // close the popup
    setPopUpIsOpen(false);
  };

  const onEventClick = (args) => {
    const event = args.event;

    if (event.type === 'holiday' || event.type === 'absence') return;

    if (event.type === 'extraAuthorized' && event.resource === userData.code) {
      dispatch(openSnackbarAction({ msg: 'Usted no puede editar sus propias horas extras', severity: "warning" }));
      return false;
    }

    if (event.type === 'extraUnauthorized') {
      dispatch(openSnackbarAction({ msg: 'Haga doble click en espacio en blanco para crear autorización sobre estas horas extra', severity: "info" }));
      return;
    }

    //si intento editar un dia libre o schedule, y tengo para el mismo dia un exception, impedir editar
    if (event.type === 'free' || event.type === 'scheduled') {
      const alreadyHasException = shifts.some(shift => {
        return (
          shift.type === 'exception' &&
          shift.resource === args.event.resource &&
          (
            new Date(shift.start).setHours(0, 0, 0, 0) === new Date(args.event.start).setHours(0, 0, 0, 0) ||
            new Date(shift.end).setHours(0, 0, 0, 0) === new Date(args.event.start).setHours(0, 0, 0, 0)
          )
        );
      })

      if (alreadyHasException) {
        dispatch(openSnackbarAction({ msg: 'Ya existe una excepción para este dia', severity: "warning" }));
        return;
      }
    }

    const title = event.type.includes('extra') ? 'Editar horas extra autorizadas' : event.type.includes('request') ? 'Solicitud de horario' : 'Editar horario';

    if (formatDate('DD/MM/YYYY', new Date(event.start)) === formatDate('DD/MM/YYYY', new Date(event.end))) {
      setHeader(
        `<div>${title} </div><div class="employee-shifts-day"> 
        ${formatDate('DDDD', new Date(event.start))}, ${formatDate('DD MMMM YYYY', new Date(event.start))}</div>`
      );
    } else {
      setHeader(
        `<div>${title} </div><div class="employee-shifts-day"> 
        ${formatDate('DDDD', new Date(event.start))}, ${formatDate('DD MMMM YYYY', new Date(event.start))} al 
        ${formatDate('DDDD', new Date(event.end))}, ${formatDate('DD MMMM YYYY', new Date(event.end))}</div>`
      );
    }

    setEdit(true);
    setTempShift({ ...event });
    loadPopupForm(event);
    setPopUpIsOpen(true);
  };

  const onClose = () => {
    if (!isEdit) {
      // refresh the list, if add popup was canceled, to remove the temporary event
      setShifts([...shifts]);
    }
    setPopUpIsOpen(false);
  };

  // la funcion que se ejecuta cuando se abre el modal para CREATE
  const extendDefaultEvent = (args) => {
    const d = args.start;
    const start = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    const end = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    return {
      title: 'Horas extra',
      start: start,
      end: end,
      resource: args.resource,
      type: 'extraAuthorized', //siempre el crear es extra autorizado
      color: calendarTypeColors.extraAuthorized,
    };
  };

  // se ejecuta antes de crear y retorna bool indicando si se puede crear
  const onEventCreate = (args) => {
    if (args.event.resource === userData.code) {
      dispatch(openSnackbarAction({ msg: 'Usted no puede autorizarse sus propias horas extras', severity: "warning" }));
      return false;
    }

    // si ya hay un extraAuthorized, impedir crear otro
    const alreadyHasExtraAuthorized = shifts.some(shift => {
      return (
        shift.type === 'extraAuthorized' &&
        shift.resource === args.event.resource &&
        (
          new Date(shift.start).setHours(0, 0, 0, 0) === new Date(args.event.start).setHours(0, 0, 0, 0) ||
          new Date(shift.end).setHours(0, 0, 0, 0) === new Date(args.event.start).setHours(0, 0, 0, 0)
        )
      );
    })

    if (alreadyHasExtraAuthorized) {
      dispatch(openSnackbarAction({ msg: 'Ya existe una autorización para este dia', severity: "warning" }));
      return false;
    }

    // si no tiene otro auth, y si no tiene horas disponibles, impedir crear
    const employeeTime = employees.find(person => person.id === args.event.resource)?.maxAuth;
    if (!employeeTime || employeeTime === '00:00') {
      dispatch(openSnackbarAction({ msg: 'El colaborador no tiene horas disponibles', severity: "warning" }));
      return false;
    }

    return true;
  };

  // cuando se creó el evento
  const onEventCreated = (args) => {
    const event = args.event;
    setHeader(
      '<div>Autorizar horas extra</div><div class="employee-shifts-day">' + formatDate('DDDD', new Date(event.start)) +
      ',' + formatDate('DD MMMM YYYY', new Date(event.start)) + '</div>'
    );

    setIsExtra(true); //siempre el crear crea un extra
    setHasAnotherExtra(false);
    setEdit(false);
    setTempShift(event);
    loadPopupForm(event);
    setPopUpIsOpen(true);
  };

  // cuando se abre el modal
  const loadPopupForm = (event) => {
    setIsExtra(event.type === 'extraAuthorized' || event.type === 'extraUnauthorized');
    setIsRequest(event.type.includes('request'));

    let newExtraTime = new Date(0, 0, 0, 0, 0, 0, 0);
    let newOriginalTime;
    if (event.timeAlreadyAuth) {
      newExtraTime = new Date(0, 0, 0, event.timeAlreadyAuth.slice(0, 2), event.timeAlreadyAuth.slice(3), 0, 0);
    }

    //obtener el tiempo maximo a autorizar
    const unauthFromSameDay = shifts.find(shift => {
      return (
        shift.type === 'extraUnauthorized' &&
        shift.resource === event.resource &&
        (
          new Date(shift.start).setHours(0, 0, 0, 0) === new Date(event.start).setHours(0, 0, 0, 0) ||
          new Date(shift.end).setHours(0, 0, 0, 0) === new Date(event.start).setHours(0, 0, 0, 0)
        )
      );
    })

    if (unauthFromSameDay) {
      newOriginalTime = new Date(
        0, 0, 0,
        newExtraTime.getHours() + parseInt(unauthFromSameDay.timeToAuth.slice(0, 2)),
        newExtraTime.getMinutes() + parseInt(unauthFromSameDay.timeToAuth.slice(3)),
        0, 0
      );
    } else {
      //setearlo desde el empleado
      const employeeTime = employees.find(person => person.id === event.resource)?.maxAuth || '';
      newOriginalTime = new Date(0, 0, 0, employeeTime.slice(0, 2), employeeTime.slice(3), 0, 0);
    }

    setExtraTime(newExtraTime);
    setOriginalExtraTime(newOriginalTime);
    setHasAnotherExtra(Boolean(unauthFromSameDay));
    setTitle(event.title);
    setShortTitle(event.shortTitle);
    setDates([event.start, event.end]);
    setSchedule(event.scheduleId);
  };

  const getPopupButtons = () => {
    if (isRequest) {
      return [
        {
          handler: () => {
            onClose();
          },
          text: 'Cancelar',
          cssClass: 'mbsc-popup-button-secondary'
        },
        {
          handler: () => {
            saveEvent(false);
          },
          text: 'Rechazar',
          cssClass: 'mbsc-popup-button-secondary'
        },
        {
          handler: () => {
            saveEvent(true);
          },
          keyCode: 'enter',
          text: 'Aceptar',
          cssClass: 'mbsc-popup-button-primary'
        }
      ];
    }

    if (isEdit) {
      return [
        'cancel',
        {
          handler: () => {
            saveEvent();
          },
          keyCode: 'enter',
          text: 'Guardar',
          cssClass: 'mbsc-popup-button-primary',
        }
      ];
    }

    return [
      'cancel',
      {
        handler: () => {
          saveEvent();
        },
        keyCode: 'enter',
        text: 'Agregar',
        cssClass: 'mbsc-popup-button-primary'
      }
    ];
  };

  const renderMyResource = (resource) => {
    if (!resource.value) {
      return <div className="employee-shifts-cont" />
    }

    return (
      <Tooltip title={resource.title || 'Sin turno asignado'}>
        <div className="employee-shifts-cont">
          <div className="employee-shifts-name" style={{ minHeight: 34 }}>{resource.value}</div>
          {
            resource.img ?
              <img
                className="employee-shifts-avatar"
                src={IMAGES_ENDPOINT + resource.img}
                alt="Avatar"
                style={{ borderRadius: '50%' }}
                onError={onMediaFallback}
              />
              :
              <div
                className="employee-shifts-avatar"
                style={{ width: 40, height: 40, background: color, borderRadius: '50%' }}
              />
          }
        </div>
      </Tooltip>
    );
  }

  const handleTimeChange = (newTime) => {
    //validar que no sea mayor al original
    const maxTime = new Date(0, 0, 0, 23, 59, 0, 0);
    const newTimeToSet = new Date(0, 0, 0, newTime.getHours(), newTime.getMinutes(), 0, 0);

    if (newTimeToSet > maxTime) {
      dispatch(openSnackbarAction({ msg: 'No puede superar las 23:59hs', severity: "warning" }));
      return;
    }

    setExtraTime(newTime);
  }

  const handleDateChange = (args) => {
    const schedule = schedules.find(item => item.key === args.value);

    const [scheduleStartHours, scheduleStartMinutes] = (schedule?.start || '00:00').split(':');
    const [scheduleEndHours, scheduleEndMinutes] = (schedule?.end || '23:59').split(':');

    const newStart = new Date(shiftDate[0]);
    newStart.setHours(scheduleStartHours, scheduleStartMinutes, 0, 0);

    const newEnd = new Date(shiftDate[0]);
    newEnd.setHours(scheduleEndHours, scheduleEndMinutes, 0, 0);

    const scheduleHasNoTime = (!schedule || (schedule && schedule.start === "00:00" && schedule.end === "00:00"));

    // si el end del schedule es menor al start, es porque termina al otro dia
    if (!scheduleHasNoTime && newEnd <= newStart) {
      newEnd.setDate(newEnd.getDate() + 1);
    }

    setDates([newStart, newEnd]);
    setTitle(args.valueText);
    setShortTitle(schedule?.shortTitle || '');
    setSchedule(args.value);
  };

  const scheduleFunctions = {
    onEventClick,
    onEventCreate,
    onEventCreated,
    getPopupButtons,
    onClose,
    extendDefaultEvent,
    renderMyResource,
    handleDateChange,
    renderScheduleEventContent,
    handleTimeChange,
  };

  const scheduleData = {
    shifts,
    schedule,
    popUpIsOpen,
    isEdit,
    headerText,
    shiftDate,
    isExtra,
    extraTime,
    originalExtraTime,
    isRequest,
    hasAnotherExtra,
    tempShift,
  };

  return {
    scheduleFunctions,
    scheduleData,
  }
}

export default useGenericSchedule;