import * as actionTypes from "./actionTypes";
import {
  getMyPeopleScheduleAPI,
  createMyPeopleScheduleAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import moment from "moment";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  let duration = 5000;
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_MAX_WEEK_HOURS":
      errorString = "El cambio de horario no está permitido porque la suma de las horas de todos los horarios programados para la semana, supera la cantidad pactada contractualmente con el colaborador";
      duration = 15000;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: duration }));
}

export const getMyPeopleScheduleAction = (employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getMyPeopleScheduleAPI(false ? `?employeeCode=${employeeCode}` : "");
    let workplaces = response?.data?.data.workplaces;
    let turns = response?.data?.data.turns;
    let employees = response?.data?.data.employees;
    let shifts = response?.data?.data.shifts;
    let schedules = response?.data?.data.schedules;
    let openCalendars = response?.data?.data.openCalendars;
    let status = response?.data?.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let hasFreeDay = false;
    const {
      myEmployeesCodes, getScheduleData, dateFormatUpperCase, getScheduleExceptionsData,
      getScheduleHolidaysData, getScheduleRequestsData, getScheduleColaborators
    } = response?.data?.data.scheduleData;

    getScheduleColaborators.forEach(colaborator => {
      const { resource, shift } = colaborator;
      if (myEmployeesCodes.includes(resource)) {
        // Voy a filtrar los turnos del colaborador
        const colaboratorData = getScheduleData.filter(el => el["shift"] === shift);
        colaboratorData.forEach(data => {
          if (!(data["type"] === "holiday" && getScheduleHolidaysData.length > 0)) {
            const hasException = getScheduleExceptionsData.find(el => el["Fecha"] === data["Fecha"] && el["resource"] === resource);
            const hasPendingRequest = getScheduleRequestsData.find(el => el["Fecha"] === data["Fecha"] && el["resource"] === resource);
            if (hasPendingRequest) {
              // Tengo que mandar el free, ademas de el otro objeto
              if (data["type"] === "free") {
                hasFreeDay = true;
              }

              shifts.push(formatEventForCalendar(resource, data, dateFormatUpperCase, true));
              shifts.push(formatEventForCalendar(resource, { ...hasPendingRequest, type: "request" }, dateFormatUpperCase, true));
            }

            if (hasException) {
              // Tengo que mandar el free, ademas de el otro objeto
              if (!hasFreeDay && data["type"] === "free") {
                shifts.push(formatEventForCalendar(resource, data, dateFormatUpperCase, true));
              }
              shifts.push(formatEventForCalendar(resource, { ...hasException, type: "exception" }, dateFormatUpperCase, true));
            }

            if (!hasPendingRequest && !hasException) {
              shifts.push(formatEventForCalendar(resource, data, dateFormatUpperCase, true));
            }
          }
        })
      }
    })


    const holidayArray = shifts.filter(el => el["type"] === "holiday");
    const nonHolidayArray = shifts.filter(el => el["type"] !== "holiday");
    shifts = [...nonHolidayArray, ...holidayArray];

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { workplaces, turns, employees, shifts, schedules, openCalendars },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};


const formatEventForCalendar = (resource, oldData, dateFormatUpperCase, isMyPeopleSchedule = false) => {
  let data = JSON.parse(JSON.stringify(oldData));
  let momentData = moment(data["Fecha"], dateFormatUpperCase);
  const startDate = formatStartDate(data, momentData);
  data.start = startDate.toISOString(true);

  const endDate = formatEndDate(data, startDate);
  data.end = endDate.toISOString(true);

  delete data["Fecha"];
  delete data["Hora de entrada"];
  delete data["Hora de salida"];
  delete data["Horas totales"];

  if (isMyPeopleSchedule) {
    delete data["tooltipText"];
    data.start = data.start.slice(0, -6);
    data.end = data.end.slice(0, -6);
  } else {
    if ((data["Hora de entrada"] < data["Hora de salida"]) ||
      (data["Hora de entrada"] === 0 && data["Hora de salida"] === 0)) {
      data.allDay = true;
    }
  }
  data.resource = resource;

  return data;
}


const formatStartDate = (data, momentData) => {
  const hours = Math.floor(parseInt(data["Hora de entrada"]) / 100);
  const minutes = parseInt(data["Hora de entrada"]) % 100;
  if (hours === 0 && minutes === 0) {
    momentData = momentData.startOf("day");
  } else {
    momentData.set('hour', hours);
    momentData.set('minutes', minutes);
  }

  return momentData;
}

const formatEndDate = (data, momentData) => {
  const addHours = Math.floor(parseInt(data["Horas totales"]) / 100);
  const addMinutes = parseInt(data["Horas totales"]) % 100;
  if (addHours === 0 && addMinutes === 0) {
    momentData = momentData.endOf('day');
  } else {
    momentData.add(addHours, 'hours');
    momentData.add(addMinutes, 'minutes');
  }

  return momentData;
}

export const createMyPeopleScheduleAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createMyPeopleScheduleAPI(dataToSend);
    let workplaces = response?.data?.data.workplaces;
    let turns = response?.data?.data.turns;
    let employees = response?.data?.data.employees;
    let shifts = response?.data?.data.shifts;
    let schedules = response?.data?.data.schedules;
    let openCalendars = response?.data?.data.openCalendars;
    let status = response?.data?.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let hasFreeDay = false;
    const {
      myEmployeesCodes, getScheduleData, dateFormatUpperCase, getScheduleExceptionsData,
      getScheduleHolidaysData, getScheduleRequestsData, getScheduleColaborators
    } = response?.data?.data.scheduleData;

    getScheduleColaborators.forEach(colaborator => {
      const { resource, shift } = colaborator;
      if (myEmployeesCodes.includes(resource)) {
        // Voy a filtrar los turnos del colaborador
        const colaboratorData = getScheduleData.filter(el => el["shift"] === shift);
        colaboratorData.forEach(data => {
          if (!(data["type"] === "holiday" && getScheduleHolidaysData.length > 0)) {

            const hasException = getScheduleExceptionsData.find(el => el["Fecha"] === data["Fecha"] && el["resource"] === resource);
            const hasPendingRequest = getScheduleRequestsData.find(el => el["Fecha"] === data["Fecha"] && el["resource"] === resource);
            if (hasPendingRequest) {
              // Tengo que mandar el free, ademas de el otro objeto
              if (data["type"] === "free") {
                hasFreeDay = true;
              }

              shifts.push(formatEventForCalendar(resource, data, dateFormatUpperCase, true));
              shifts.push(formatEventForCalendar(resource, { ...hasPendingRequest, type: "request" }, dateFormatUpperCase, true));
            }

            if (hasException) {
              // Tengo que mandar el free, ademas de el otro objeto
              if (!hasFreeDay && data["type"] === "free") {
                shifts.push(formatEventForCalendar(resource, data, dateFormatUpperCase, true));
              }
              shifts.push(formatEventForCalendar(resource, { ...hasException, type: "exception" }, dateFormatUpperCase, true));
            }

            if (!hasPendingRequest && !hasException) {
              shifts.push(formatEventForCalendar(resource, data, dateFormatUpperCase, true));
            }
          }
        })
      }
    })


    const holidayArray = shifts.filter(el => el["type"] === "holiday");
    const nonHolidayArray = shifts.filter(el => el["type"] !== "holiday");
    shifts = [...nonHolidayArray, ...holidayArray];

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { workplaces, turns, employees, shifts, schedules, openCalendars },
    });

    dispatch(openSnackbarAction({ msg: 'Horarios actualizados con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};