import React, { useState, useMemo, useEffect, useRef } from "react";
import CommonPage from "@icarius-common/commonPage";
import Schedule from "./schedule";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import {
  Typography,
  Checkbox,
  Switch,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import useGenericSchedule from './useGenericSchedule';
import useMyPeopleSchedule from './useMyPeopleSchedule';
import FilterDialog from "./filterDialog";
import { useHistory, useLocation } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { calendarTypeColors } from "@icarius-utils/colors";
import { createDateFromDDMMYYYY, createDateFromYYYYMMDDHyphen } from "@icarius-utils/date";

const typesStrings = {
  holiday: 'Feriados',
  free: 'Libres',
  scheduled: 'Horarios programados',
  exception: 'Excepciones',
  absence: 'Inasistencias',
  request: 'Solicitudes',
  extraAuthorized: 'Hrs. extras autorizadas',
}

const MyPeopleSchedule = (props) => {
  // presetCode y presetHandleGoBack son para myPeople/maestro de empleados, que tienen a esta pantalla embebida y no se redirige mediante history
  const { presetCode, presetHandleGoBack } = props;

  const location = useLocation();
  const history = useHistory();

  const locationState = useRef(location?.state);
  const hasSetUserFromLocation = useRef(false);

  const [filterDialogIsOpen, setFilterDialogIsOpen] = useState(false);
  const [scheduleFilter, setScheduleFilter] = useState('');
  const [workplaceFilter, setWorkplaceFilter] = useState('');
  const [turnFilter, setTurnFilter] = useState('');
  const [extrasFilter, setExtrasFilter] = useState('');
  const [requestFilter, setRequestFilter] = useState('');
  const [userFilter, setUserFilter] = useState(null);
  const [showShortTitle, setShowShortTitle] = useState(true);
  const [showWeek, setShowWeek] = useState(true);

  const {
    handleSave,
    isLoading,
    workplaces,
    turns,
    employees,
    shifts,
    schedules,
    openCalendars,
  } = useMyPeopleSchedule(presetCode);

  const {
    scheduleFunctions,
    scheduleData,
  } = useGenericSchedule(schedules, shifts, showShortTitle, extrasFilter, employees);


  // para cuando viene el codigo de empleado por history
  useEffect(() => {
    if (employees?.length && locationState.current && !Boolean(hasSetUserFromLocation.current)) {
      const employee = employees.find(item => item.id === locationState.current.code);
      setUserFilter(employee || null);
      setRequestFilter(locationState.current?.setRequestFilter ? openCalendars[0].key : '');

      history.push({
        pathname: paths.myPeopleSchedule,
        state: null,
      });

      hasSetUserFromLocation.current = true;
    }
  }, [employees, history, openCalendars])

  // para cuando viene el codigo de empleado (presetCode) por prop y no por history
  useEffect(() => {
    if (employees?.length && presetCode && !Boolean(hasSetUserFromLocation.current)) {
      const employee = employees.find(item => item.id === presetCode);
      setUserFilter(employee || null);

      hasSetUserFromLocation.current = true;
    }
  }, [employees, presetCode])

  const filteredEmployees = useMemo(() => {
    return [...employees].filter(person => {
      if (userFilter && person.id !== userFilter.id) return false;
      if (workplaceFilter && person.workplace !== workplaceFilter) return false;
      if (turnFilter && person.turn !== turnFilter) return false;

      // filtrar los que tengan algun extra
      if (extrasFilter) {
        const calendarSelected = openCalendars.find(calendar => calendar.key === extrasFilter);
        const shiftsFromPerson = scheduleData.shifts.filter(shift => shift.resource === person.id);
        const hasExtraInCalendar = shiftsFromPerson.some(shift => {
          if (shift.type === 'extraAuthorized' || shift.type === 'extraUnauthorized') {
            const fromShift = typeof shift.start === 'object' ? shift.start : createDateFromYYYYMMDDHyphen(shift.start);
            const fromCalendar = createDateFromDDMMYYYY(calendarSelected.from);

            return (fromShift >= fromCalendar);
          }
          return false;
        });
        if (!hasExtraInCalendar) return false;
      }

      // filtrar los que tengan algun request
      if (requestFilter) {
        const calendarSelected = openCalendars.find(calendar => calendar.key === requestFilter);
        const shiftsFromPerson = scheduleData.shifts.filter(shift => shift.resource === person.id);
        const hasRequestInCalendar = shiftsFromPerson.some(shift => {
          if (shift.type === 'request' || shift.type === 'requestAccepted') {
            const fromShift = typeof shift.start === 'object' ? shift.start : createDateFromYYYYMMDDHyphen(shift.start);
            const fromCalendar = createDateFromDDMMYYYY(calendarSelected.from);

            return (fromShift >= fromCalendar);
          }
          return false;
        });
        if (!hasRequestInCalendar) return false;
      }

      // filtrar los que tengan algun schedule
      if (scheduleFilter) {
        const shiftsFromPerson = scheduleData.shifts.filter(shift => shift.resource === person.id);
        const hasSchedule = shiftsFromPerson.some(shift => shift.scheduleId === scheduleFilter);
        if (!hasSchedule) return false;
      }

      return true;
    })
  }, [employees, workplaceFilter, turnFilter, userFilter, extrasFilter, requestFilter, scheduleFilter, scheduleData.shifts, openCalendars]);

  const goBackButton = () => (
    (Boolean(locationState?.current?.from) || Boolean(presetHandleGoBack)) &&
    <CustomIconButton
      title={'Volver'}
      type={'goBack'}
      onClick={() => Boolean(presetHandleGoBack) ? presetHandleGoBack() : history.push(locationState.current.from)}
    />
  )

  const filterButton = () => (
    <CustomIconButton
      title={'Filtrar'}
      type={'filter'}
      onClick={() => setFilterDialogIsOpen(true)}
    />
  )

  const saveButton = () => (
    <CustomIconButton
      title={'Guardar'}
      type={'save'}
      onClick={() => handleSave(scheduleData.shifts)}
    />
  )

  const titleCheckItem = () => (
    <div
      style={{ width: 175, marginRight: 10, marginTop: 12, display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => setShowShortTitle(!Boolean(showShortTitle))}
    >
      <Checkbox checked={showShortTitle} />
      <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
        {'Horario abreviado'}
      </Typography>
    </div>
  )

  const changeViewItem = () => (
    <div style={{ width: 205, marginTop: 12 }}>
      <FormControlLabel
        control={
          <Switch
            checked={showWeek}
            onChange={() => setShowWeek(prev => !prev)}
            value={showWeek}
            color="primary"
          />
        }
        label={'Mostrar semana'}
        labelPlacement="start"
        style={{ marginLeft: 32 }}
      />
    </div>
  )

  const typesSelectItem = () => (
    <FormControl style={{ width: '200px', marginLeft: 10, marginRight: 10 }}>
      <InputLabel id={`label`}>{'Tipos'}</InputLabel>
      <Select
        value={''}
        labelId={`label`}
        id={`select`}
        margin={"none"}
      >
        {
          ['holiday', 'free', 'scheduled', 'exception', 'absence', 'request', 'extraAuthorized'].map(item => (
            <MenuItem
              className={"whiteText"}
              key={item}
              value={item}
            >
              <div style={{ marginRight: 10, height: 10, width: 10, borderRadius: '50%', background: calendarTypeColors[item] }} />
              {typesStrings[item]}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )

  const dateToUse = useMemo(() => {
    if (extrasFilter) {
      const selectedCalendar = openCalendars.find(calendar => calendar.key === extrasFilter);
      if (selectedCalendar && selectedCalendar.minDateExtra) {
        return createDateFromDDMMYYYY(selectedCalendar.minDateExtra);
      }
      return '';
    }

    if (requestFilter) {
      const selectedCalendar = openCalendars.find(calendar => calendar.key === requestFilter);
      if (selectedCalendar && selectedCalendar.minDateChange) {
        return createDateFromDDMMYYYY(selectedCalendar.minDateChange);
      }
      return '';
    }

    if (locationState.current?.date) return createDateFromDDMMYYYY(locationState.current?.date);
    return '';
  }, [extrasFilter, requestFilter, openCalendars])

  return (
    <CommonPage
      title={`Gestión de horarios - Colaboradores: ${filteredEmployees.length}`}
      isLoading={isLoading}
      menuItems={[goBackButton, changeViewItem, titleCheckItem, filterButton, typesSelectItem, saveButton]}
      isNotGridPage
    >
      <Schedule
        staff={filteredEmployees}
        schedules={schedules}
        scheduleFunctions={scheduleFunctions}
        scheduleData={scheduleData}
        showWeek={showWeek}
        scheduleFilter={scheduleFilter}
        initialShifts={shifts}
        date={dateToUse}
        key={dateToUse}
      />
      {
        filterDialogIsOpen &&
        <FilterDialog
          open={filterDialogIsOpen}
          workplaceFilter={workplaceFilter}
          setWorkplaceFilter={setWorkplaceFilter}
          turnFilter={turnFilter}
          setTurnFilter={setTurnFilter}
          extrasFilter={extrasFilter}
          setExtrasFilter={setExtrasFilter}
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          requestFilter={requestFilter}
          setRequestFilter={setRequestFilter}
          scheduleFilter={scheduleFilter}
          setScheduleFilter={setScheduleFilter}
          workplaces={workplaces}
          employees={employees}
          turns={turns}
          schedules={schedules}
          openCalendars={openCalendars}
          handleClose={() => setFilterDialogIsOpen(false)}
        />
      }
    </CommonPage>
  )
}

export default MyPeopleSchedule;