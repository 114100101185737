import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveWorkOrChoreChangeAction } from "@icarius-pages/myPeople/actions";
import { getEditableFields } from "../../../selectors";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const formatArray = (arr) => arr.map(item => ({ key: item.code || item.id, value: item.value || 'Sin valor' }));

const WorkOrChoreDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleAfterSubmit,
    handleClose,
  } = props;
  const dispatch = useDispatch();

  const submitCallback = (dataToSend) => {
    dispatch(massiveWorkOrChoreChangeAction({
      codes: employees.map(item => item.code),
      ...dataToSend,
    }))
      .then((resp) => {
        if (resp.status === 200) {
          handleAfterSubmit(resp?.data?.showAlert);
        }
        handleClose();
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const editableFields = useSelector(getEditableFields);
  const workArray = formatArray(editableFields.find(item => item.name === 'OBRA O FAENA').options);

  const { formData, setFormValue, submit } = useHandleForm(submitCallback, openValidationError);

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio masivo de obra o faena"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Obra o faena`}>
                  {"Obra o faena"}
                </InputLabel>
                <Select
                  value={formData.work}
                  labelId={`label-Obra o faena`}
                  id={`select-Obra o faena`}
                  onChange={(e) => setFormValue(e.target.value, 'work')}
                  margin={"none"}
                >
                  {
                    workArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {`¿Está seguro que quiere cambiar la obra o faena para ${employees.length} colaborador/es?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default WorkOrChoreDialog;
