import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveContractAction } from "@icarius-pages/myPeople/actions";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import Loader from "@icarius-common/loader";
import { getAdditionalValidationData, getEditableFields } from "../../../selectors";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const formatArray = (arr) => arr.map(item => ({ key: item.code || item.id, value: item.value || 'Sin valor' }));
const removeNoValue = (arr) => arr.filter(item => item.key !== "-" && item.key !== "");

const ContractDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleAfterSubmit,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const submitCallback = (dataToSend) => {
    dispatch(massiveContractAction({
      codes: employees.map(item => item.code),
      ...dataToSend,
    }))
      .then((resp) => {
        if (resp.status === 200) {
          handleAfterSubmit(resp?.data?.showAlert);
        }
        handleClose();
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const editableFields = useSelector(getEditableFields);
  const additionalValidationData = useSelector(getAdditionalValidationData);
  const additionalContractTypes = formatArray(additionalValidationData.contract_types);
  const functionaryTypeArray = removeNoValue(formatArray(editableFields.find(item => item.name === 'TIPO DE FUNCIONARIO').options));
  const contractTypeArray = removeNoValue(formatArray(editableFields.find(item => item.name === 'TIPO DE CONTRATO').options));
  const workdayTypeArray = removeNoValue(formatArray(editableFields.find(item => item.name === 'TIPO DE JORNADA').options));
  const contractorTypeArray = formatArray(editableFields.find(item => item.name === 'CONTRATISTA').options);
  const workArray = formatArray(editableFields.find(item => item.name === 'OBRA O FAENA').options);
  const motiveFireArray = removeNoValue(formatArray(editableFields.find(item => item.name === 'MOTIVO TERMINO LEGAL').options));
  const motiveHireArray = removeNoValue(formatArray(editableFields.find(item => item.name === 'MOTIVO DE CONTRATACION').options));

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(additionalContractTypes, submitCallback, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio masivo de contratos"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container style={{ maxHeight: '64vh', overflow: 'auto' }}>
            <Grid container item alignItems="center" xs={12}>
              <Typography className="whiteText" style={{ fontSize: '1.5rem', fontWeight: 500, padding: "0px 10px" }}>
                {'Contrato actual'}
              </Typography>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha de término de contrato para el contrato actual'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  clearLabel='Limpiar'
                  clearable
                  invalidDateMessage=''
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha de término de contrato para el contrato actual']}
                  onChange={(e) => setFormValue(e, 'Fecha de término de contrato para el contrato actual')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Motivo de término del contrato actual`}>{"Motivo de término del contrato actual"}</InputLabel>
                <Select
                  value={formData['Motivo de término del contrato actual']}
                  labelId={`label-Motivo de término del contrato actual`}
                  id={`select-Motivo de término del contrato actual`}
                  onChange={(e) => setFormValue(e.target.value, 'Motivo de término del contrato actual')}
                  margin={"none"}
                >
                  {
                    motiveFireArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12}>
              <Typography className="whiteText" style={{ fontSize: '0.95rem', padding: "0px 10px", marginBottom: 20 }}>
                {'Si el cambio se aplica en colaboradores sin contrato existente (nuevas contrataciones), los dos datos de arriba serán ignorados'}
              </Typography>
            </Grid>
            <Grid container item alignItems="center" xs={12}>
              <Typography className="whiteText" style={{ fontSize: '1.5rem', fontWeight: 500, padding: "0px 10px", marginTop: 10 }}>
                {'Nuevo contrato'}
              </Typography>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Tipo de funcionario`}>{"Tipo de funcionario"}</InputLabel>
                <Select
                  value={formData['Tipo de funcionario']}
                  labelId={`label-Tipo de funcionario`}
                  id={`select-Tipo de funcionario`}
                  onChange={(e) => setFormValue(e.target.value, 'Tipo de funcionario')}
                  margin={"none"}
                >
                  {
                    functionaryTypeArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Tipo de contrato`}>{"Tipo de contrato"}</InputLabel>
                <Select
                  value={formData['Tipo de contrato']}
                  labelId={`label-Tipo de contrato`}
                  id={`select-Tipo de contrato`}
                  onChange={(e) => setFormValue(e.target.value, 'Tipo de contrato')}
                  margin={"none"}
                >
                  {
                    contractTypeArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Código de contrato'}
                value={formData['Código de contrato']}
                onChange={(e) => setFormValue(e.target.value, "Código de contrato")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-Tipo de contratación`}>{"Tipo de contratación"}</InputLabel>
                <Select
                  disabled
                  value={formData['Tipo de contratación']}
                  labelId={`label-Tipo de contratación`}
                  id={`select-Tipo de contratación`}
                  onChange={(e) => setFormValue(e.target.value, 'Tipo de contratación')}
                  margin={"none"}
                >
                  {
                    additionalContractTypes.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Tipo de jornada`}>{"Tipo de jornada"}</InputLabel>
                <Select
                  value={formData['Tipo de jornada']}
                  labelId={`label-Tipo de jornada`}
                  id={`select-Tipo de jornada`}
                  onChange={(e) => setFormValue(e.target.value, 'Tipo de jornada')}
                  margin={"none"}
                >
                  {
                    workdayTypeArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label='Horas diarias contrato'
                type={'number'}
                inputProps={{ min: "1", step: "0.05" }}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setFormValue(e.target.value, 'Horas diarias contrato');
                  }
                }}
                onInput={e => {
                  e.target.value = e.target.value.toString().slice(0, 511);
                  setFormValue(e.target.value, 'Horas diarias contrato');
                }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label='Horas semanales contrato'
                type={'number'}
                inputProps={{ min: "1", step: "0.05" }}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setFormValue(e.target.value, 'Horas semanales contrato');
                  }
                }}
                onInput={e => {
                  e.target.value = e.target.value.toString().slice(0, 511);
                  setFormValue(e.target.value, 'Horas semanales contrato');
                }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label='Horas mensuales contrato'
                type={'number'}
                inputProps={{ min: "1", step: "0.05" }}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setFormValue(e.target.value, 'Horas mensuales contrato');
                  }
                }}
                onInput={e => {
                  e.target.value = e.target.value.toString().slice(0, 511);
                  setFormValue(e.target.value, 'Horas mensuales contrato');
                }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                disabled
                fullWidth
                margin={"none"}
                label={'Personal de planta'}
                value={formData['Personal de planta']}
                onChange={(e) => setFormValue(e.target.value, "Personal de planta")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel
                  required={formData['Tipo de funcionario'] === 'SUB'}
                  id={`label-Contratista`}
                >
                  {"Contratista"}
                </InputLabel>
                <Select
                  disabled={formData['Tipo de funcionario'] !== 'SUB'}
                  value={formData['Contratista']}
                  labelId={`label-Contratista`}
                  id={`select-Contratista`}
                  onChange={(e) => setFormValue(e.target.value, 'Contratista')}
                  margin={"none"}
                >
                  {
                    contractorTypeArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel
                  required={formData['Tipo de contratación'] === '3' || formData['Tipo de contratación'] === '5'}
                  id={`label-Obra o faena`}
                >
                  {"Obra o faena"}
                </InputLabel>
                <Select
                  value={formData['Obra o faena']}
                  labelId={`label-Obra o faena`}
                  id={`select-Obra o faena`}
                  onChange={(e) => setFormValue(e.target.value, 'Obra o faena')}
                  margin={"none"}
                >
                  {
                    workArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha de contratación'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  clearLabel='Limpiar'
                  clearable
                  invalidDateMessage=''
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha de contratación']}
                  onChange={(e) => setFormValue(e, 'Fecha de contratación')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Motivo de contratación`}>{"Motivo de contratación"}</InputLabel>
                <Select
                  value={formData['Motivo de contratación']}
                  labelId={`label-Motivo de contratación`}
                  id={`select-Motivo de contratación`}
                  onChange={(e) => setFormValue(e.target.value, 'Motivo de contratación')}
                  margin={"none"}
                >
                  {
                    motiveHireArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  required={formData['Tipo de contratación'] === '2'}
                  fullWidth
                  label={'Fecha término de contrato'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  clearLabel='Limpiar'
                  clearable
                  invalidDateMessage=''
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData['Fecha término de contrato']}
                  onChange={(e) => setFormValue(e, 'Fecha término de contrato')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Comentarios'}
                value={formData['Comentarios']}
                onChange={(e) => setFormValue(e.target.value, "Comentarios")}
              />
            </Grid>
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {`¿Está seguro que quiere cambiar los datos contractuales para ${employees.length} colaborador/es?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ContractDialog;
