import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveStructureChangeAction } from "@icarius-pages/myPeople/actions";
import { getManagementsList } from "@icarius-pages/myPeople/selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const StructureDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    fieldsToHide,
    handleAfterSubmit,
    handleClose,
  } = props;

  const [management, setManagement] = useState('');
  const [department, setDepartment] = useState('');
  const [section, setSection] = useState('');

  const dispatch = useDispatch();
  const managementsList = useSelector(getManagementsList);

  const handleSubmit = () => {
    if (management) {
      dispatch(massiveStructureChangeAction({
        codes: employees.map(item => item.code),
        management,
        department,
        section,
      }))
        .then((resp) => {
          if (resp.status === 200) {
            handleAfterSubmit(resp?.data?.showAlert);
          }
          handleClose();
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar una gerencia', severity: "warning" }));
    }
  };

  const managementSelected = managementsList.find(item => item.key === management) || [];
  const departmentSelected = managementSelected?.departments?.find(item => item.key === department) || [];

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio masivo de estructura departamental"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <div style={{ display: 'flex', margin: 10, marginTop: 0 }}>
            <Typography className="whiteText">
              {'Colaboradores seleccionados:'}
            </Typography>
            <Typography className="whiteText" style={{ marginLeft: 5, fontWeight: 600 }}>
              {employees.length}
            </Typography>
          </div>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-1`}>{"Gerencia"}</InputLabel>
              <Select
                value={management}
                labelId={`label-1`}
                id={`select-1`}
                onChange={(e) => {
                  setManagement(e.target.value);
                  setDepartment('');
                  setSection('');
                }}
                margin={"none"}
              >
                {
                  managementsList.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-2`}>{"Departamento"}</InputLabel>
              <Select
                value={department}
                labelId={`label-2`}
                id={`select-2`}
                onChange={(e) => {
                  setDepartment(e.target.value);
                  setSection('');
                }}
                margin={"none"}
              >
                {
                  managementSelected?.departments?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {
            !fieldsToHide.includes("SECCION") &&
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-3`}>{"Sección"}</InputLabel>
              <Select
                value={section}
                labelId={`label-3`}
                id={`select-3`}
                onChange={(e) => setSection(e.target.value)}
                margin={"none"}
              >
                {
                  departmentSelected?.sections?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          }
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default StructureDialog;
