import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import paths from "@icarius-localization/paths";
import { getEmployeeData } from "../selectors";
import { RESET_PEOPLE_ROWS, RESET_EMPLOYEE_DATA, RESET_PHOTO, SET_EMPLOYEE_OPINION } from "../actionTypes";
import GridScreen from "./gridScreen";
import UserPage from "./userPage";

const MyPeople = ({ match }) => {

  const [selectedEmployee, setSelectedEmployee] = useState(null); // es el seleccionado con los datos de la grilla
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [userPageIsOpen, setUserPageIsOpen] = useState(false);

  const dispatch = useDispatch();
  const peopleMasterEmployeeData = useSelector(getEmployeeData);
  const isPeopleMaster = match.url === paths.employeeMaster;

  useEffect(() => {
    dispatch({ type: RESET_PEOPLE_ROWS });
    dispatch({ type: RESET_EMPLOYEE_DATA });
  }, [dispatch])

  const handleOpenUserPage = (employee) => {
    setSelectedEmployee(employee); // si es null es nuevo usuario
    if (employee) {
      dispatch({
        type: SET_EMPLOYEE_OPINION,
        payload: { opinion: employee.OPINION },
      });
    }
    setUserPageIsOpen(true);
  }

  const handleOpenDuplicateUserPage = (employee) => {
    setIsDuplicate(true);
    setSelectedEmployee(employee);
    setUserPageIsOpen(true);
  }

  const handleCloseUserPage = () => {
    dispatch({ type: RESET_PEOPLE_ROWS });
    dispatch({ type: RESET_EMPLOYEE_DATA });
    dispatch({ type: RESET_PHOTO });
    setSelectedEmployee(null);
    setUserPageIsOpen(false);
    setIsDuplicate(false);
  }

  const employeeToUse = useMemo(() => {
    if (!selectedEmployee && !peopleMasterEmployeeData) return null; // si no tiene ninguno de los dos, es para crear usuario
    const auxEmployee = { ...(peopleMasterEmployeeData || selectedEmployee) };

    if (isDuplicate) {
      delete auxEmployee['FOTOGRAFIA'];
      delete auxEmployee['CODIGO DE EMPLEADO'];
      delete auxEmployee['APELLIDO MATERNO'];
      delete auxEmployee['APELLIDO PATERNO'];
      delete auxEmployee['PRIMER NOMBRE'];
      delete auxEmployee['SEGUNDO NOMBRE'];
      delete auxEmployee['APELLIDO Y NOMBRE'];
      delete auxEmployee['NRO INTERNO'];
      delete auxEmployee['NRO DE EMPLEADO'];
      delete auxEmployee['CELULAR'];
      delete auxEmployee['TELEFONO PARTICULAR'];
      delete auxEmployee['FECHA DE NACIMIENTO'];
      delete auxEmployee['DIRECCION'];
      delete auxEmployee['EMAIL'];
      delete auxEmployee['LINKEDIN'];
      delete auxEmployee['NRO DE PASAPORTE'];
      delete auxEmployee['EXPIRACION DEL PASAPORTE'];
      delete auxEmployee['CODIGO DE CONTRATO'];
      delete auxEmployee['CUENTA BANCARIA'];
      delete auxEmployee['MESES ADICIONAL VACACIONES'];
      delete auxEmployee['FECHA ADICIONAL VACACIONES'];
      delete auxEmployee['FECHA AFILIACION PREVISIONAL'];
      delete auxEmployee['VALOR COTIZADO EN OTRO PAIS'];
      delete auxEmployee['CODIGO DE TARJETA'];
      delete auxEmployee['EDAD'];
    }

    return auxEmployee;
  }, [isDuplicate, peopleMasterEmployeeData, selectedEmployee])

  return (
    <>
      {
        userPageIsOpen
          ?
          <UserPage
            employee={employeeToUse}
            isPeopleMaster={isPeopleMaster}
            isDuplicate={isDuplicate}
            handleCloseUserPage={handleCloseUserPage}
          />
          :
          <GridScreen
            isPeopleMaster={isPeopleMaster}
            handleOpenUserPage={handleOpenUserPage}
            handleOpenDuplicateUserPage={handleOpenDuplicateUserPage}
          />
      }
    </>
  );
}

export default MyPeople;