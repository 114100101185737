import { fieldTypes, TABS } from "@icarius-utils/constants";
import { getLocalizedString } from "@icarius-localization/strings";

const NAME = "MY_PEOPLE_PAGE";

export const myPeopleAndPeopleMasterFields = [
    {
        name: "CODIGO DE EMPLEADO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("employeeCode"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "ESTADO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("state"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "APELLIDO PATERNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("fatherLastName"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "APELLIDO MATERNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("motherLastName"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "PRIMER NOMBRE",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("firstName"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "SEGUNDO NOMBRE",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("secondName"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "APELLIDO Y NOMBRE",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("namesAndLastnames"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "SEXO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("sex"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "ESTADO CIVIL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("civilState"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "FECHA DE NACIMIENTO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bornDate"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "EDAD",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("age"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "NACIONALIDAD",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("nationality"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "EMAIL",
        readOnlyType: fieldTypes.email,
        label: getLocalizedString("email"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "EMAIL PERSONAL",
        readOnlyType: fieldTypes.email,
        label: 'Email personal',
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "CELULAR",
        readOnlyType: fieldTypes.phone,
        label: getLocalizedString("cellphone"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "LINKEDIN",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("linkedin"),
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: false,
    },   
    {
        name: "HABILITAR EL ACCESO A ICARIUS",
        readOnlyType: fieldTypes.text,
        label: 'Acceso a ICARIUS habilitado',
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "NRO INTERNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("internalNumber"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "DIRECCION",
        readOnlyType: fieldTypes.address,
        label: getLocalizedString("address"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "PAIS",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("country"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "REGION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("region"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "PROVINCIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("provicia"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "COMUNA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("comuna"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "CODIGO POSTAL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("postalCode"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "TELEFONO OFICINA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("officePhone"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "TELEFONO PARTICULAR",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("particularPhone"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "GRUPO SANGUINEO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bloodType"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "DONANTE",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("donante"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "NRO DE PASAPORTE",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("passportNumber"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "EXPIRACION DEL PASAPORTE",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("expirationPassport"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "FECHA DE INGRESO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("incomeDate"),
        tab: TABS.personal,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "COMENTARIOS Y OBSERVACIONES",
        readOnlyType: fieldTypes.text,
        label: 'Comentarios y observaciones',
        tab: TABS.personal,
        column: 0,
        hideInMyPeople: true,
    },
    /////////Contractuales 
    {
        name: "TIPO DE FUNCIONARIO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("fType"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "TIPO DE CONTRATO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contractType"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "CODIGO DE CONTRATO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contractCode"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "TIPO DE CONTRATACION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contratacionType"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "CANTIDAD DE CONTRATACIONES",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contractAmount"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "TIPO DE JORNADA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("jornadaType"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "HORAS DIARIAS CONTRATO",
        readOnlyType: fieldTypes.number,
        label: getLocalizedString("hoursPerDay"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "HORAS SEMANALES CONTRATO",
        readOnlyType: fieldTypes.number,
        label: 'Horas semanales contrato',
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "HORAS MENSUALES CONTRATO",
        readOnlyType: fieldTypes.number,
        label: getLocalizedString("hoursPerMonth"),
        tab: TABS.contract,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "PERSONAL DE PLANTA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("plantPersonnel"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "CONTRATISTA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contratista"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "OBRA O FAENA",
        readOnlyType: fieldTypes.text,
        label: "Obra o faena",
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "FECHA DE ANTIGUEDAD",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("antiquityDate"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "FECHA DE INGRESO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("incomeDate"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "MOTIVO DE CONTRATACION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contractMotive"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "FECHA DE EGRESO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contractEndDate"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "MOTIVO TERMINO LEGAL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("legalMotive"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "MOTIVO TERMINO CALCULO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("calculationMotive"),
        tab: TABS.contract,
        column: 1,
        hideInMyPeople: true,
    },

    //Remunerativos
    {
        name: "UNIDAD SALARIAL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("salaryUnit"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "SUELDO BASICO",
        readOnlyType: fieldTypes.number,
        label: getLocalizedString("salary"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "MONEDA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("coin"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "FORMA DE PAGO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("paymentMethod"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "BANCO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bank"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "CUENTA BANCARIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bankAccount"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "TIPO DE CUENTA BANCARIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bankAccountType"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "JUBILADO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("retired"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "TIPO DE TRABAJADOR",
        readOnlyType: fieldTypes.text,
        label: 'Tipo de trabajador',
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "TRAMO DE ASIGNACION FAMILIAR",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("familyAllowanceSection"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "REBAJA ZONA EXTREMA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("extremeZoneReduction"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "PORCENTAJE ZONA EXTREMA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("extremeZonePercentage"),
        tab: TABS.remuneration,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "INSTITUCION DE PREVISION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("previsionInstitution"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "FECHA AFILIACION PREVISIONAL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("affiliationDate"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "INSTITUCION DE SALUD",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("healthInstitution"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "SINDICATO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("laborUnion"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "TABLA DE VACACIONES",
        readOnlyType: fieldTypes.text,
        label: 'Tabla de vacaciones',
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "MESES ADICIONAL VACACIONES",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("additionalMonthVacation"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "FECHA ADICIONAL VACACIONES",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("additionalVacationDate"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "DIAS ADICIONALES VACACIONES",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("additionalDaysVacation"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "SUELDO EMPRESARIAL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("businessSalary"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "TRABAJO PESADO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("heavyWork"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "IMPONE EN OTRO PAIS",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("taxOtherCountry"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "VALOR COTIZADO EN OTRO PAIS",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("valueOtherCountry"),
        tab: TABS.remuneration,
        column: 1,
        hideInMyPeople: true,
    },
    //Ubicacion  
    {
        name: "GERENCIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("management"),
        tab: TABS.location,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "DEPARTAMENTO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("department"),
        tab: TABS.location,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "SECCION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("section"),
        tab: TABS.location,
        column: 0,
        hideInMyPeople: false,
    },
    //

    {
        name: "LUGAR DE TRABAJO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("jobLocation"),
        tab: TABS.location,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "FECHA ASIGNACION LUGAR DE TRABAJO",
        readOnlyType: fieldTypes.text,
        label: "Fecha asignación lugar de trabajo",
        tab: TABS.location,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "DIVISION",
        readOnlyType: fieldTypes.text,
        label: "Unidad de negocio",
        tab: TABS.location,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "FECHA ASIGNACION DIVISION",
        readOnlyType: fieldTypes.text,
        label: "Fecha asignación a unidad",
        tab: TABS.location,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "MODALIDAD DE TRABAJO",
        readOnlyType: fieldTypes.text,
        label: 'Modalidad de trabajo',
        tab: TABS.location,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "CENTRO DE GESTIÓN",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("managementCenter"),
        tab: TABS.location,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "CARGO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("position"),
        tab: TABS.location,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "FECHA ASIGNACION CARGO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("positionDate"),
        tab: TABS.location,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "FUNCION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("function"),
        tab: TABS.location,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "FECHA ASIGNACION FUNCION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("functionDate"),
        tab: TABS.location,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "CENTRO DE COSTO",
        readOnlyType: fieldTypes.text,
        label: 'Centro de costo de gestión',
        tab: TABS.location,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "FECHA ASIGNACION CENTRO DE COSTO",
        readOnlyType: fieldTypes.text,
        label: 'Fecha asignación centro de costo',
        tab: TABS.location,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "CATEGORIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("category"),
        tab: TABS.location,
        column: 1,
        hideInMyPeople: false,
    },
    {
        name: "ESPECIALIDAD",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("specialty"),
        tab: TABS.location,
        column: 1,
        hideInMyPeople: false,
    },

    ////TIEMPO
    {
        name: "TURNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("shift"),
        tab: TABS.time,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "FECHA ASIGNACION TURNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("shiftDate"),
        tab: TABS.time,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "TIPO DE CALENDARIO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("calendarType"),
        tab: TABS.time,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "TIPO DE CONTROL HORARIO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("scheduleControlType"),
        tab: TABS.time,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "CODIGO DE TARJETA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("cardCode"),
        tab: TABS.time,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "HORARIO",
        readOnlyType: fieldTypes.text,
        label: "Horario programado para hoy",
        tab: TABS.time,
        column: 0,
        hideInMyPeople: false,
    },
    {
        name: "HORAS EXTRAS LIBERADAS",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("freedExtraHours"),
        tab: TABS.time,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "PUEDE MARCAR DESDE MOVILES",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("canMarkMobile"),
        tab: TABS.time,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "PUEDE MARCAR DESDE ICARIUS",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("canMarkICARIUS"),
        tab: TABS.time,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "MARCAR CON AVISO PROGRAMADO",
        readOnlyType: fieldTypes.text,
        label: 'Marcar con aviso programado',
        tab: TABS.time,
        column: 1,
        hideInMyPeople: true,
    },
    //
    {
        name: "CLASIFICACION INE",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("ineClassif"),
        tab: TABS.specialClassification,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "CAPACIDADES ESPECIALES",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("specialCapabilities"),
        tab: TABS.specialClassification,
        column: 0,
        hideInMyPeople: true,
    },
    {
        name: "CODIGO DE JORNADA",
        readOnlyType: fieldTypes.text,
        label: 'Código de jornada',
        tab: TABS.specialClassification,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "SUBSIDIO TRABAJADOR JOVEN",
        readOnlyType: fieldTypes.text,
        label: 'Subsidio trabajador joven',
        tab: TABS.specialClassification,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "INDEMNIZACION A TODO EVENTO",
        readOnlyType: fieldTypes.text,
        label: 'Indemnización a todo evento',
        tab: TABS.specialClassification,
        column: 1,
        hideInMyPeople: true,
    },
    {
        name: "TASA INDEM",
        readOnlyType: fieldTypes.text,
        label: 'Tasa idem. a todo evento',
        tab: TABS.specialClassification,
        column: 1,
        hideInMyPeople: true,
    },
];

export const CREATE_DEFAULT_VALUES = [
    {
        name: "ESTADO",
        value: "V",
    },
    {
        name: "SEXO",
        value: "M",
    },
    {
        name: "HABILITAR EL ACCESO A ICARIUS",
        value: "Y",
    },
    {
        name: "TIPO DE FUNCIONARIO",
        value: "-",
    },
    {
        name: "TIPO DE CONTRATO",
        value: "-",
    },
    {
        name: "TIPO DE JORNADA",
        value: "-",
    },
    {
        name: "HORAS DIARIAS CONTRATO",
        value: 0,
    },
    {
        name: "HORAS SEMANALES CONTRATO",
        value: 0,
    },
    {
        name: "HORAS MENSUALES CONTRATO",
        value: 0,
    },
    {
        name: "CONTRATISTA",
        value: "",
    },
    {
        name: "MOTIVO DE CONTRATACION",
        value: "-",
    },
    {
        name: "FECHA DE EGRESO",
        value: null,
    },
    {
        name: "MOTIVO TERMINO LEGAL",
        value: "-",
    },
    {
        name: "MOTIVO TERMINO CALCULO",
        value: "-",
    },
    {
        name: "OBRA O FAENA",
        value: "",
    },
    {
        name: "CODIGO DE CONTRATO",
        value: "",
    },
    {
        name: "UNIDAD SALARIAL",
        value: "-",
    },
    {
        name: "SUELDO BASICO",
        value: 0,
    },
    {
        name: "MONEDA",
        value: "-",
    },
    {
        name: "FORMA DE PAGO",
        value: "-",
    },
    {
        name: "BANCO",
        value: "-",
    },
    {
        name: "CUENTA BANCARIA",
        value: "",
    },
    {
        name: "TIPO DE CUENTA BANCARIA",
        value: "-",
    },
    {
        name: "JUBILADO",
        value: "N",
    },
    {
        name: "TIPO DE TRABAJADOR",
        value: "0",
    },
    {
        name: "TRAMO DE ASIGNACION FAMILIAR",
        value: "S",
    },
    {
        name: "REBAJA ZONA EXTREMA",
        value: "N",
    },
    {
        name: "PORCENTAJE ZONA EXTREMA",
        value: 0,
    },
    {
        name: "INSTITUCION DE SALUD",
        value: "-",
    },
    {
        name: "FECHA AFILIACION PREVISIONAL",
        value: null,
    },
    {
        name: "SINDICATO",
        value: "-",
    },
    {
        name: "TABLA DE VACACIONES",
        value: "1",
    },
    {
        name: "MESES ADICIONAL VACACIONES",
        value: 0,
    },
    {
        name: "DIAS ADICIONALES VACACIONES",
        value: 0,
    },
    {
        name: "FECHA ADICIONAL VACACIONES",
        value: null,
    },
    {
        name: "SUELDO EMPRESARIAL",
        value: "N",
    },
    {
        name: "TRABAJO PESADO",
        value: "-",
    },
    {
        name: "IMPONE EN OTRO PAIS",
        value: "N",
    },
    {
        name: "VALOR COTIZADO EN OTRO PAIS",
        value: 0,
    },
    {
        name: "TURNO",
        value: "-"
    },
    {
        name: "FECHA ASIGNACION TURNO",
        value: null
    },
    {
        name: "TIPO DE CALENDARIO",
        value: "-"
    },
    {
        name: "TIPO DE CONTROL HORARIO",
        value: "-"
    },
    {
        name: "CODIGO DE TARJETA",
        value: ""
    },
    {
        name: "HORAS EXTRAS LIBERADAS",
        value: "N"
    },
    {
        name: "CAPACIDADES ESPECIALES",
        value: "0"
    },
    {
        name: "PUEDE MARCAR DESDE MOVILES",
        value: "N"
    },
    {
        name: "PUEDE MARCAR DESDE ICARIUS",
        value: "Y"
    },
    {
        name: "MARCAR CON AVISO PROGRAMADO",
        value: "N"
    },
    {
        name: "CLASIFICACION INE",
        value: "-"
    },
    {
        name: "CODIGO DE JORNADA",
        value: "101"
    },
    {
        name: "SUBSIDIO TRABAJADOR JOVEN",
        value: "0"
    },
    {
        name: "INDEMNIZACION A TODO EVENTO",
        value: "0"
    },
    {
        name: "TASA INDEM",
        value: 0
    },
    {
        name: "INSTITUCION DE PREVISION",
        value: "-",
    },
    {
        name: "GRUPO SANGUINEO",
        value: "-",
    },
    {
        name: "DONANTE",
        value: "N",
    },
]

export default NAME;