import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMyPeopleScheduleAction, getMyPeopleScheduleAction } from '../actions';
import {
  getIsLoading,
  getWorkplaces,
  getTurns,
  getEmployees,
  getShifts,
  getSchedules,
  getOpenCalendars,
} from '../selectors';
import { formatDateYYYYMMDD } from '@icarius-utils/date';

const useMyPeopleSchedule = (employeeCode) => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyPeopleScheduleAction(employeeCode));
  }, [dispatch, employeeCode])

  const handleSave = (data) => {
    const dataToSend = data
      .filter(item => item.type === 'exception' || item.type === 'extraAuthorized' || item.type.includes('request'))
      .map(item => {
        return ({
          resource: item.resource,
          scheduleId: item.scheduleId,
          start: formatDateYYYYMMDD(item.start),
          time: item.timeAlreadyAuth,
          title: item.title,
          type: item.type,
        })
      });

    dispatch(createMyPeopleScheduleAction({ data: dataToSend }));
  }

  const isLoading = useSelector(getIsLoading);
  const workplaces = useSelector(getWorkplaces);
  const turns = useSelector(getTurns);
  const employees = useSelector(getEmployees);
  const shifts = useSelector(getShifts);
  const schedules = useSelector(getSchedules);
  const openCalendars = useSelector(getOpenCalendars);

  return {
    handleSave,
    isLoading,
    workplaces,
    turns,
    employees,
    shifts,
    schedules,
    openCalendars,
  }
}

export default useMyPeopleSchedule;