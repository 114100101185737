import React, { useMemo } from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  Eventcalendar,
  setOptions,
  Popup,
  Select,
  Datepicker,
  localeEs,
} from '@mobiscroll/react';
import { Typography } from '@material-ui/core';
import { formatDateHHMM } from '@icarius-utils/date';

setOptions({
  locale: localeEs,
  theme: 'material',
  themeVariant: 'light'
});

const responsivePopup = {
  medium: {
    display: 'center',
    width: 400,
    fullScreen: false,
    touchUi: false,
    showOverlay: false
  }
};

const Schedule = (props) => {

  const {
    staff,
    schedules,
    scheduleFunctions,
    scheduleData,
    showWeek,
    scheduleFilter,
    initialShifts,
    date,
  } = props;

  const schedulesFilteredFormatted = useMemo(() => {
    // encontrar el original del GET
    const originalShift = initialShifts.find(item => {
      return (
        item?.resource === scheduleData.tempShift?.resource &&
        item?.start === scheduleData.tempShift?.start &&
        item?.type === scheduleData.tempShift?.type
      )
    });

    // encontrar el schedule del original del GET
    const originalSchedule = schedules.find(item => item.key === originalShift?.scheduleId);

    //filtrar los del mismo grupo (si no tiene grupo, todos) y formatear
    return schedules
      .filter(item => {
        if (item.key === 'LIBRE') return true;
        if (originalSchedule?.group) return item.group === originalSchedule.group;
        return true;
      })
      .map(item => ({ value: item.key, text: item.value }));
  }, [schedules, scheduleData.tempShift, initialShifts])

  const viewSettings = useMemo(() => {
    return ({
      timeline: {
        type: showWeek ? 'week' : 'month',
        eventList: true,
      }
    })
  }, [showWeek])

  const getRemainingTime = () => {
    if (!scheduleData.extraTime) return formatDateHHMM(new Date(scheduleData.originalExtraTime));

    const start = new Date(scheduleData.originalExtraTime);
    const newHours = start.getHours() - scheduleData.extraTime.getHours();
    const newMinutes = start.getMinutes() - scheduleData.extraTime.getMinutes();
    return formatDateHHMM(new Date(0, 0, 0, newHours, newMinutes));
  }

  // shifts sin los requestRejected
  const shiftsToUse = useMemo(() => {
    if (!scheduleData.shifts) return [];
    let aux = scheduleData.shifts.filter(shift => shift.type !== 'requestRejected');

    if (!scheduleFilter) return aux;

    return aux.filter(item => item?.scheduleId === scheduleFilter);
  }, [scheduleData.shifts, scheduleFilter])

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Typography className="whiteText" noWrap style={{ margin: 10, marginTop: 0 }}>
        Click en un horario para cambiarlo. Doble click en área en blanco de una fecha para autorizar horas extras
      </Typography>
      <Eventcalendar
        view={viewSettings}
        resources={staff}
        data={shiftsToUse}
        extendDefaultEvent={scheduleFunctions.extendDefaultEvent}
        onEventClick={scheduleFunctions.onEventClick}
        onEventCreate={scheduleFunctions.onEventCreate}
        onEventCreated={scheduleFunctions.onEventCreated}
        onEventDeleted={scheduleFunctions.onEventDeleted}
        renderResource={scheduleFunctions.renderMyResource}
        renderScheduleEventContent={scheduleFunctions.renderScheduleEventContent}
        dragToCreate={false}
        dragToResize={false}
        dragToMove={false}
        clickToCreate={true}
        cssClass="md-employee-shifts"
        height={'calc(100vh - 225px)'}
        dayNamesShort={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
        defaultSelectedDate={date || new Date()}
      />
      {
        scheduleData.popUpIsOpen &&
        <Popup
          isOpen={scheduleData.popUpIsOpen}
          headerText={scheduleData.headerText}
          onClose={scheduleFunctions.onClose}
          buttons={scheduleFunctions.getPopupButtons()}
          responsive={responsivePopup}
          fullScreen={true}
          contentPadding={false}
          display="bottom"
          cssClass="employee-shifts-popup"
        >
          <div className="mbsc-form-group">
            {
              scheduleData.isExtra &&
              <>
                <Typography className='mbsc-material mbsc-textfield-wrapper'>
                  {`${scheduleData.hasAnotherExtra ? "Horas extra realizadas por autorizar" : "Máximo horas extra para preautorizar"}: ${formatDateHHMM(scheduleData.originalExtraTime) || '-'}`}
                </Typography>
                <Datepicker
                  label='Horas extras que autoriza'
                  inputTyping={false}
                  controls={['time']}
                  onChange={(e) => scheduleFunctions.handleTimeChange(e.value)}
                  value={scheduleData.extraTime}
                  valid={[{
                    start: new Date(0, 0, 0, 0, 0, 0, 0),
                    end: new Date(0, 0, 0,23, 59, 0, 0),
                  }]}
                />
                {
                  scheduleData.hasAnotherExtra &&
                  <Typography className='mbsc-material mbsc-textfield-wrapper'>
                    {`Horas extras sin autorizar: ${getRemainingTime()}`}
                  </Typography>
                }
              </>
            }
            {
              scheduleData.isRequest &&
              <Typography className='mbsc-material mbsc-textfield-wrapper'>
                {'¿Acepta la solicitud de cambio de horario?'}
              </Typography>
            }
            {
              !scheduleData.isExtra && !scheduleData.isRequest &&
              <Select
                data={schedulesFilteredFormatted}
                label="Seleccionar horario"
                onChange={(args) => scheduleFunctions.handleDateChange(args)}
                value={scheduleData.schedule}
              />
            }
          </div>
        </Popup>
      }
    </div>
  )
}

export default Schedule;