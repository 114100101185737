import React from "react";

const Canvas = ({ draw, setCanvas, height, width }) => {
    const canvas = React.useRef();

    React.useEffect(() => {
        const context = canvas.current.getContext("2d");
        draw(context);
        setCanvas(canvas.current);
    });

    return <canvas ref={canvas} height={height} width={width} />;
};

export default Canvas;
