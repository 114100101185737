import React from "react";
import {
    Grid,
    Typography,
} from "@material-ui/core";

const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12}>
                <Typography className="whiteText" style={{ fontSize: 28, width: '100%', textTransform: 'uppercase' }}>
                    {title}
                </Typography>
                <Grid container item xs={12} style={{ paddingTop: 25, paddingBottom: 30 }} spacing={2}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepContainer;