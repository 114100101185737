import React from "react";
import { connect } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { createUpdateTemplate } from "@icarius-pages/myPeople/actions";
import { CloseIcon } from "@icarius-icons";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable"; 
import DialogTransition from "@icarius-common/dialogTransition";

class UpdateDialog extends React.Component {
  render() {
    const { template, grid, switchStatus } = this.props;

    let open = false;
    if (typeof this.props.open !== "undefined") {
      open = this.props.open;
    }
    return (
      <Dialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}>
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {getLocalizedString("atention")}                
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={this.handleCloseDialog} />
          <DialogContent>
            <Typography variant={"h6"} className={"whiteText"}>
              {getLocalizedString("confirmUpdate")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction
              onClick={this.handleCloseDialog}
              text={getLocalizedString("disagree")}
            />
            <ButtonDialogAction
              onClick={() => this.handleConfirm(template, grid, switchStatus)}
              isAccept
              text={getLocalizedString("agree")}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  handleConfirm = (template, grid, switchStatus) => {
    let copia = { ...template };

    const data = {
      column: grid.columnApi.getColumnState(),
      group: grid.columnApi.getColumnGroupState(),
      filter: grid.api.getFilterModel(),
      pivot: grid.columnApi.isPivotMode(),
      showCurrentEmployees: switchStatus,
      chart: grid.api.getChartModels().length > 0 ? grid.api.getChartModels()[0] : undefined,
    };

    if(data.chart){
      delete data.chart.chart;
    }
    
    copia.U_columnstate = data.column;
    copia.U_groupstate = data.group;
    copia.U_filterstate = data.filter;
    copia.U_pivotstate = data.pivot;
    copia.U_selstate = data.showCurrentEmployees;
    copia.U_graphicstate = data.chart;
    try {
      this.props.dispatch(createUpdateTemplate(copia)).then(() => {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("templateUpdate"), severity: "success" }));
        this.handleCloseDialog();
      });      
    } catch (e) {
      this.setState({ error: e });
    }
  };

  handleCloseDialog = () => {
    this.props.handleCloseDialog();
  };
}

export default connect()(UpdateDialog);
