import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { useSelector, useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import Loader from "@icarius-common/loader";
import { downloadReceiptAction } from "@icarius-pages/receipts/actions";
import { getIsLoading, getReceipts } from "@icarius-pages/myPeople/selectors";
import { getIsLoading as getIsDownloading } from "@icarius-pages/receipts/selectors";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const ReceiptDialog = (props) => {

  const [receipt, setReceipt] = useState('');
  const [processes, setProcesses] = useState([]);

  const {
    open,
    code,
    handleClose,
  } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const isDownloading = useSelector(getIsDownloading);
  const receiptList = useSelector(getReceipts);

  const handleGenerate = () => {
    if (!receipt || !processes.length) {
      dispatch(openSnackbarAction({ msg: 'Todos los campos son obligatorios', severity: "error" }));
      return;
    }

    Promise.all(processes.map(item => {
      return dispatch(downloadReceiptAction(receipt, item, [code], true))
    })).then(handleClose)
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("receiptsShortTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading || isDownloading} />
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-receipt`}>{'Tipo de documento'}</InputLabel>
                <Select
                  value={receipt}
                  labelId={`label-receipt`}
                  id={`select-receipt`}
                  onChange={(e) => {
                    setReceipt(e.target.value);
                    setProcesses([]);
                  }}
                  margin={"none"}
                >
                  {
                    receiptList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              receipt &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-processes`}>{'Procesos de cálculo'}</InputLabel>
                  <Select
                    value={processes}
                    multiple
                    labelId={`label-processes`}
                    id={`select-processes`}
                    onChange={(e) => setProcesses(e.target.value)}
                    margin={"none"}
                  >
                    {
                      receiptList.find(item => item.key === receipt)?.calculation_processes?.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleGenerate} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ReceiptDialog;