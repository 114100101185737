import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getCounties, getProvinces } from "@icarius-pages/myPeople/selectors";
import DynamicInput from "@icarius-common/dynamicInputs";
import StepContainer from "./stepContainer";
import { TABS } from "@icarius-utils/constants";

const Personal = (props) => {
    const {
        editableFields,
        formData,
        setFormValue,
        getIsRequired,
        fieldsToUse,
        fieldsWithError,
        fieldsToHide,
    } = props;

    const sectionFields = fieldsToUse.filter((item) => {
        if (item.tab === TABS.personal) return editableFields ? true : !item.hideInMyPeople;
        return false;
    });

    const provinces = useSelector(getProvinces);
    const counties = useSelector(getCounties);

    // si tengo editableFields (peopleMaster) busco la opción segun la key que tengo. Si no tengo editableFields (myPeople), tomo el dato directo.
    const countryToUse = editableFields?.find(item => item.name === "PAIS")?.options?.find(pais => pais.id === formData["PAIS"])?.value || formData["PAIS"];
    const provinceToUse = provinces?.find(item => item.id === formData["PROVINCIA"])?.value || formData["PROVINCIA"];
    const countyToUse = counties?.find(item => item.id === formData["COMUNA"])?.value || formData["COMUNA"];

    const addressHref = "https://www.google.com/maps/search/?api=1&query=" +
        formData["DIRECCION"] +
        (countyToUse ? "," + countyToUse : "") +
        (provinceToUse ? "," + provinceToUse : "") +
        (countryToUse ? "," + countryToUse : "");

    const commentField = sectionFields?.find((field) => field.name === "COMENTARIOS Y OBSERVACIONES");

    return (
        <StepContainer title={'Personales'}>
            {
                [0, 1].map((column) => {
                    const fieldsByColumn = sectionFields.filter((item) => item.column === column);

                    return (
                        <Grid item xs={12} md={6} key={column}>
                            {
                                fieldsByColumn.map((item) => {
                                    const fieldConfig = editableFields?.find((field) => field.name === item.name);

                                    // se renderiza aparte
                                    if (item.name === "COMENTARIOS Y OBSERVACIONES") return null;
                                    if (fieldsToHide.includes(item.name)) return null;

                                    return (
                                        <Grid
                                            key={item.name}
                                            container item alignItems="center" xs={12}
                                            style={{ paddingBottom: 15 }}
                                        >
                                            <DynamicInput
                                                fieldConfig={fieldConfig}
                                                label={item.label}
                                                value={formData[item.name]}
                                                required={getIsRequired(item.name)}
                                                error={Boolean(fieldsWithError?.includes(item.name))}
                                                readOnlyType={item.readOnlyType}
                                                addressHref={item.name === "DIRECCION" ? addressHref : null}
                                                handleChange={setFormValue}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    )
                })
            }
            {
                Boolean(commentField) &&
                <Grid container item xs={12} style={{ paddingTop: 0 }}>
                    <DynamicInput
                        fieldConfig={editableFields?.find((field) => field.name === commentField.name)}
                        label={commentField.label}
                        value={formData[commentField.name]}
                        required={getIsRequired(commentField.name)}
                        error={Boolean(fieldsWithError?.includes(commentField.name))}
                        readOnlyType={commentField.readOnlyType}
                        handleChange={setFormValue}
                    />
                </Grid>
            }
        </StepContainer>
    )
}

export default Personal;