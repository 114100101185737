import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massivePositionChangeAction, massivePositionRecomposeChangeAction } from "@icarius-pages/myPeople/actions";
import { getEditableFields } from "../../../selectors";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import useHandleForm from "./useHandleForm";
import { openDialogAction } from "@icarius-common/dialog/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const formatArray = (arr) => arr.filter(item => Boolean(item.id)).map(item => ({ key: item.code || item.id, value: item.value }));

const PositionDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleAfterSubmit,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const editableFields = useSelector(getEditableFields);
  const positionArray = formatArray(editableFields.find(item => item.name === 'CARGO').options);

  const submitCallback = (dataToSend) => {
    dispatch(massivePositionChangeAction({
      codes: employees.map(item => item.code),
      oldPosition: positionArray.find(item => item.value === employees[0].position)?.key,
      ...dataToSend,
    }))
      .then((resp) => {
        if (resp.status === 200) {
          handleAfterSubmit(resp?.data?.showAlert);
        } else {
          // ver si el error es de NO_POSITIONS y blablabla
          if (resp.data?.status === "NO_POSITIONS") {
            const msgContent = (
              <>
                <Typography className="whiteText">
                  {`El cargo destino no tiene los puestos libres suficientes para aplicar el cambio masivo. Solo dispone de ${resp?.data?.data.amountInUse} puestos libres y se requieren ${resp?.data?.data.amountNeeded}.`}
                </Typography>
                <Typography className="whiteText" style={{ marginTop: 20, marginBottom: 10 }}>
                  {'Si pulsa CANCELAR, no se hará la modificación y deberá reasignar para el cargo destino la cantidad de puestos desde la función “Estructura de cargos” para dejar los puestos libres que son necesarios.'}
                </Typography>
                <Typography className="whiteText">
                  {'Si pulsa REDIMENSIONAR PUESTOS Y MODIFICAR se aplicará el cambio de cargos y para ambos cargos (Origen y Destino) se reasignará la cantidad de puestos en forma automática y quedarán con el total de sus puestos ocupados.'}
                </Typography>
              </>
            );

            dispatch(openDialogAction({
              title: 'Atención',
              msg: msgContent,
              onConfirm: () => {
                dispatch(massivePositionRecomposeChangeAction({
                  codes: employees.map(item => item.code),
                  oldPosition: positionArray.find(item => item.value === employees[0].position)?.key,
                  ...dataToSend,
                }))
                  .then((resp) => {
                    if (resp.status === 200) {
                      handleAfterSubmit(resp?.data?.showAlert);
                    }
                  })
              },
              onCancel: handleClose,
              acceptText: 'Redimensionar puestos y modificar',
            }))
          }
        }
        handleClose();
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { formData, setFormValue, submit } = useHandleForm(submitCallback, openValidationError);

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio masivo de cargo"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Cargo`}>
                  {"Cargo"}
                </InputLabel>
                <Select
                  value={formData.position}
                  labelId={`label-Cargo`}
                  id={`select-Cargo`}
                  onChange={(e) => setFormValue(e.target.value, 'position')}
                  margin={"none"}
                >
                  {
                    positionArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  required
                  label={'Fecha asignación cargo'}
                  value={formData.date}
                  onChange={(e) => setFormValue(e, 'date')}
                  fullWidth
                  minDate={new Date()}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  minDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {`¿Está seguro que quiere cambiar el cargo para ${employees.length} colaborador/es?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default PositionDialog;
