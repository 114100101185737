import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveTerminationAction } from "@icarius-pages/myPeople/actions";
import { getEditableFields } from "../../selectors";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { formatDateYYYYMMDD } from "@icarius-utils/date";

const gridStyle = { height: 75, padding: "0px 10px" };

const TerminationDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleAfterSubmit,
    handleClose,
  } = props;

  const [endDate, setEndDate] = useState(new Date());
  const [legalMotive, setLegalMotive] = useState('');
  const [calcMotive, setCalcMotive] = useState('');

  const editableFields = useSelector(getEditableFields);
  const legalMotiveArray = editableFields.find(item => item.name === 'MOTIVO TERMINO LEGAL').options.map(item => ({ key: item.id, value: item.value }));
  const calcMotiveArray = editableFields.find(item => item.name === 'MOTIVO TERMINO CALCULO').options.map(item => ({ key: item.id, value: item.value }));

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (endDate && legalMotive && calcMotive) {
      dispatch(massiveTerminationAction({
        codes: employees.map(item => item.code),
        endContract: formatDateYYYYMMDD(endDate),
        legalMotive,
        calcMotive,
      }))
        .then((resp) => {
          if (resp.status === 200) {
            handleAfterSubmit(resp?.data?.showAlert);
          }
          handleClose();
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Todos los campos son obligatorios', severity: "warning" }));
    }
  };

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Desvinculaciones masivas"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha término de contrato'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=''
                  format="dd/MM/yyyy"
                  margin="normal"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-legalMotive`}>{"Motivo de termino legal"}</InputLabel>
                <Select
                  value={legalMotive}
                  labelId={`label-legalMotive`}
                  id={`select-legalMotive`}
                  onChange={(e) => setLegalMotive(e.target.value)}
                  margin={"none"}
                >
                  {
                    legalMotiveArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-calcMotive`}>{"Motivo de termino calculo"}</InputLabel>
                <Select
                  value={calcMotive}
                  labelId={`label-calcMotive`}
                  id={`select-calcMotive`}
                  onChange={(e) => setCalcMotive(e.target.value)}
                  margin={"none"}
                >
                  {
                    calcMotiveArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {`¿Está seguro que quiere asignar los datos de desvinculación para ${employees.length} colaborador/es?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default TerminationDialog;
