import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import NineBoxIndicator from "@icarius-common/nineBoxIndicator";
import Likes from "@icarius-common/likes";
import QR from "@icarius-common/qr";
import ProfileImg from "@icarius-common/profileImg";
import { getEmployeeOpinion } from "../../selectors";

const CustomButton = withStyles({
    root: {
        color: "var(--mainText) !important",
        textTransform: "none",
        backgroundColor: "var(--secondaryBackgroundColor)",
        width: "100%",
    }
})(Button);

const UserSummary = (props) => {

    const {
        isNewEmployee,
        isPhotoModificable,
        isSameUser,
        photo,
        employee,
        handleOpenCertificate,
        handleOpenReceipt,
        handleLikeRequest,
        handleOpenQueries,
        handleOpenChangePhotoDialog,
    } = props;

    const color = useSelector(getAppColor);
    const societySelected = useSelector(getSocietySelected);
    const employeeOpinion = useSelector(getEmployeeOpinion);

    const qr = employee &&
        "BEGIN:VCARD\n" +
        "VERSION:3.0\n" +
        "N:" + employee["APELLIDO Y NOMBRE"] + "\n" +
        "ORG:" + societySelected.name + "\n" +
        "TITLE:" + employee["CARGO"] + "\n" +
        "TEL:" + employee["CELULAR"] + "\n" +
        "EMAIL:" + employee["EMAIL"] + "\n" +
        "END:VCARD\n"

    return (
        <Grid container item xs={12} alignItems="center" spacing={2}>
            <Grid container alignItems="center" justify="center" item sm={12} md={2}>
                <div className={"photo-container"}>
                    <ProfileImg
                        image={photo}
                        size={100}
                        editable={isPhotoModificable}
                        handleChange={handleOpenChangePhotoDialog}
                    />
                </div>
            </Grid>
            {
                !isNewEmployee &&
                <Grid container direction="column" item sm={12} md={8}>
                    <Typography
                        style={{
                            fontWeight: 200,
                            fontSize: '20px',
                            lineHeight: "28px",
                        }}
                        className={"whiteText"}
                        variant="subtitle1"
                    >
                        {employee["APELLIDO Y NOMBRE"]}
                    </Typography>
                    {
                        employee["MANAGER"] && employee["MANAGER"] !== "-" &&
                        <Typography
                            style={{
                                color: color,
                                fontWeight: 400,
                                fontSize: '20px',
                                lineHeight: "28px",
                            }}
                        >
                            {"Manager: " + employee["MANAGER"]}
                        </Typography>
                    }
                    {
                        employee["ESTADO"] &&
                        <Typography
                            className={"whiteText"}
                            style={{
                                fontWeight: 200,
                                fontSize: '20px',
                                lineHeight: "28px",
                            }}
                        >
                            {employee["ESTADO"] === "Vigente" ? getLocalizedString("vigente") : getLocalizedString("noVigente")}
                        </Typography>
                    }
                    {
                        Boolean(employee["nine_box"]) &&
                        <div style={{ paddingTop: 10 }}>
                            <NineBoxIndicator data={employee["nine_box"]} />
                        </div>
                    }
                    <div style={{ paddingTop: 10 }}>
                        <Likes
                            active={employee["ESTADO"] === "Vigente"}
                            value={employeeOpinion}
                            handleLike={(like, oldValue) => handleLikeRequest(employee["CODIGO DE EMPLEADO"], like, oldValue)}
                        />
                    </div>
                </Grid>
            }
            {
                !isNewEmployee &&
                <Grid container item sm={12} md={2} justify="center" alignItems="center">
                    <QR value={qr} hideLabel />
                </Grid>
            }
            <Grid container item xs={12} justify="center" alignItems="center" spacing={2}>
                {
                    (!isSameUser && !isNewEmployee) &&
                    <>
                        <Grid container item xs={12} md={4} justify="center">
                            <CustomButton
                                onClick={handleOpenCertificate}
                                variant={"contained"}
                            >
                                {getLocalizedString("certificates")}
                            </CustomButton>
                        </Grid>
                        <Grid container item xs={12} md={4} justify="center">
                            <CustomButton
                                onClick={handleOpenReceipt}
                                variant={"contained"}
                            >
                                {getLocalizedString("receipts")}
                            </CustomButton>
                        </Grid>
                    </>
                }
                {
                    !isNewEmployee &&
                    <Grid container item xs={12} md={isSameUser ? 12 : 4} justify="center">
                        <CustomButton
                            onClick={handleOpenQueries}
                            variant={"contained"}
                        >
                            {'Consultas'}
                        </CustomButton>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default UserSummary;