import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const CREATE = NAME + "/CREATE";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";