import React, { useState } from "react";
import { DialogTitle, Dialog, DialogContent, Typography } from "@material-ui/core";
import { decimalToTime } from "@icarius-utils/date";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CreatePermissionAccountDialog from "./createPermissionAccountDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteIssuePermissionsBalanceAction } from "@icarius-pages/issuePermissions/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";
import { useDispatch } from "react-redux";
import CommonPage from "@icarius-common/commonPage";

const PermissionsAccountDialog = (props) => {

  const {
    open,
    data,
    dateFormat,
    locale,
    employeeCode,
    employeeName,
    loading,
    handleClose,
  } = props;

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);

  const dispatch = useDispatch();

  const getCreditLabel = (value) => {
    const parsedValue = parseFloat(value);
    if (parsedValue === 0) { return "0,00 / 00:00 horas y minutos" }

    const time = decimalToTime(parsedValue);
    const formattedValue = formatNumberOrReturnUndefined(parsedValue, 2, 2);

    return `${formattedValue} / ${time} horas y minutos`;
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el registro?",
      onConfirm: () => handleDelete(selectedRows[0]),
    }));
  }

  const handleDelete = (selectedRow) => {
    dispatch(deleteIssuePermissionsBalanceAction({ internalCode: selectedRow.internalCode }));
  }

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => setCreateDialogIsOpen(true)}
      type={"add"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  return (
    <>
      <Dialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}>
        <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
          {`Cuenta corriente de permisos en horas${employeeName ? ` - ${employeeName}` : ''}`}
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Typography className="whiteText" style={{ marginBottom: 10 }}>
            {`Saldo actual: ${data.length ? getCreditLabel(data[0]['Saldo al movimiento']) : getCreditLabel(0)} (Crédito)`}
          </Typography>
          <CommonPage
            menuItems={[createButton, deleteButton]}
            gridTitle={"Cuenta corriente de permisos en horas"}
            columnDefPage={'myRequestsViewBalance'}
            rowData={data}
            dateFormat={dateFormat}
            locale={locale}
            gridHeight={'65vh'}
            ignoreProcessesbyPath
            hasSelectAll
            hasHelp
            hasExpand
          />
        </DialogContent>
      </Dialog>
      {
        createDialogIsOpen &&
        <CreatePermissionAccountDialog
          open={createDialogIsOpen}
          loading={loading}
          handleClose={() => setCreateDialogIsOpen(false)}
          employeeCode={employeeCode}
        />
      }
    </>
  );
}

export default PermissionsAccountDialog;