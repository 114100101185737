import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveUploadMyPeoplePhotosAction } from "@icarius-pages/myPeople/actions";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";

const UploadFilesDialog = (props) => {

  const {
    open,
    isLoading,
    handleClose,
    handleAfterSubmit,
  } = props;

  const [files, setFiles] = useState(null);
  const [failedDocuments, setFailedDocuments] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setFailedDocuments(null);
    if (dataIsValid()) {
      dispatch(massiveUploadMyPeoplePhotosAction(files))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(openSnackbarAction({ msg: getLocalizedString("documentUploadedOK"), severity: "success" }));
            handleAfterSubmit(resp?.data?.showAlert);
            handleClose();
          } else {
            setFailedDocuments(resp.data.invalidFiles)
          }
        });
    } else {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos una imagen', severity: "warning" }));
    }
  };

  const dataIsValid = () => {
    if (files === null) return false;
    return true;
  };

  const handleDeleteFileFromList = (file) => {
    const newFileArray = [...files];
    const indexToDelete = newFileArray.indexOf(file);
    if (indexToDelete > -1) {
      newFileArray.splice(indexToDelete, 1);
      setFiles(newFileArray.length === 0 ? null : newFileArray);
    }
  }

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Carga masiva de fotografías"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <FileInput
            acceptedFormats={['.jpg', '.bmp', '.png', '.jpeg']}
            extraLabel={'Los archivos deben comenzar con el código del empleado o número interno seguido de guión bajo (Ej. 12345_nombre_archivo.jpg)'}
            handleChange={(files) => setFiles(files)}
            preSubmitCallback={() => setFailedDocuments(null)}
            multiple
          />
          <FileList
            files={files}
            filesWithError={failedDocuments}
            handleClick={handleDeleteFileFromList}
            multiple
          />
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("uploadButtonText")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default UploadFilesDialog;
