import * as actionTypes from "./actionTypes";
import { likeEmployee } from "@icarius-utils/like";

const initialState = {
  isLoading: false,
  isLoadingEmployee: false,
  isLoadingLocation: false,
  isLoadingQueries: false,
  isLoadingReceipts: false,
  isLoadingManagementDivision: false,
  isLoadingTemplates: false,
  isLoadingMail: false,

  employees: [],
  editableFields: [],
  managementsList: [],
  payrollList: [],
  sectionsToHide: [],
  fieldsToHide: [],
  assumeEntryDate: false,
  additionalValidationData: {},

  employeeOpinion: null, // es una copia del OPINION que tiene en employees, para poder mostrar la version actualizada una vez seleccionado el empleado
  employeeData: null,
  departments: [],
  sections: [],
  counties: [],
  provinces: [],
  regions: [],
  receipts: [],

  onboardingProcesses: [],

  locale: "es",
  dateFormat: "yyyy/mm/dd",

  templates: [],

  photo: null,

  queries: [],
  kpiData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_PEOPLE_ROWS:
      return { ...state, employees: [] }
    case actionTypes.RESET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: null,
        employeeOpinion: null,
      }

    case actionTypes.GET_PEOPLE_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_PEOPLE_ROWS_FULFILLED: {
      return {
        ...state,
        isLoading: false,
        employees: action.payload.employees,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
        totalEmployeesCount: action.payload.totalCount,
        editableFields: action.payload.editableFields,
        additionalValidationData: action.payload.additionalValidationData,
        payrollList: action.payload.payrollList,
        managementsList: action.payload.managementsList,
        sectionsToHide: action.payload.sectionsToHide || [],
        fieldsToHide: action.payload.fieldsToHide || [],
        assumeEntryDate: action.payload.assumeEntryDate || false,
      };
    }
    case actionTypes.GET_PEOPLE_ROWS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_EMPLOYEE_DATA: {
      return { ...state, isLoadingEmployee: true };
    }
    case actionTypes.GET_EMPLOYEE_DATA_FULFILLED: {
      return {
        ...state,
        isLoadingEmployee: false,
        employeeData: action.payload.colaborator,
      };
    }
    case actionTypes.GET_EMPLOYEE_DATA_REJECTED: {
      return { ...state, isLoadingEmployee: false };
    }
    case actionTypes.SET_EMPLOYEE_OPINION:
      return {
        ...state,
        employeeOpinion: action.payload.opinion,
      };

    case actionTypes.CREATE_EMPLOYEE:
      return { ...state, isLoadingEmployee: true };
    case actionTypes.CREATE_EMPLOYEE_FULFILLED:
      let auxArr = [...state.employees];
      auxArr.push(action.payload.employee);
      return {
        ...state,
        isLoadingEmployee: false,
        employees: auxArr,
      };
    case actionTypes.CREATE_EMPLOYEE_REJECTED:
      return { ...state, isLoadingEmployee: false };

    case actionTypes.UPDATE_EMPLOYEE:
      return { ...state, isLoadingEmployee: true };
    case actionTypes.UPDATE_EMPLOYEE_FULFILLED:
      let updateAuxArr = [...state.employees].filter(field => field["CODIGO DE EMPLEADO"] !== action.payload.employee["CODIGO DE EMPLEADO"]);
      updateAuxArr.unshift(action.payload.employee);
      return { ...state, isLoadingEmployee: false, employees: updateAuxArr };
    case actionTypes.UPDATE_EMPLOYEE_REJECTED:
      return { ...state, isLoadingEmployee: false };

    case actionTypes.RESET_PHOTO:
      return { ...state, photo: null };
    case actionTypes.MODIFY_PHOTO:
      return { ...state, isLoadingEmployee: true };
    case actionTypes.MODIFY_PHOTO_FULFILLED:
      return {
        ...state,
        isLoadingEmployee: false,
        photo: action.payload.url,
      };
    case actionTypes.MODIFY_PHOTO_REJECTED:
      return { ...state, isLoadingEmployee: false };

    case actionTypes.GET_COUNTIES_BY_PROVINCE:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_COUNTIES_BY_PROVINCE_FULFILLED: {
      return {
        ...state,
        isLoadingLocation: false,
        counties: action.payload,
      };
    }
    case actionTypes.GET_COUNTIES_BY_PROVINCE_REJECTED: {
      return {
        ...state,
        isLoadingLocation: false,
        counties: [],
      };
    }

    case actionTypes.GET_PROVINCES_BY_REGION:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_PROVINCES_BY_REGION_FULFILLED: {
      return {
        ...state,
        isLoadingLocation: false,
        provinces: action.payload,
        counties: [],
      };
    }
    case actionTypes.GET_PROVINCES_BY_REGION_REJECTED: {
      return {
        ...state,
        isLoadingLocation: false,
        provinces: [],
        counties: [],
      };
    }

    case actionTypes.GET_REGIONS_BY_COUNTRY:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_REGIONS_BY_COUNTRY_FULFILLED: {
      return {
        ...state,
        isLoadingLocation: false,
        regions: action.payload,
        provinces: [],
        counties: [],
      };
    }
    case actionTypes.GET_REGIONS_BY_COUNTRY_REJECTED: {
      return {
        ...state,
        isLoadingLocation: false,
        regions: [],
        provinces: [],
        counties: [],
      };
    }

    case actionTypes.GET_QUERIES:
      return { ...state, isLoadingQueries: true };
    case actionTypes.GET_QUERIES_FULFILLED:
      return {
        ...state,
        isLoadingQueries: false,
        queries: action.payload.data,
        kpiData: action.payload.kpiData,
      };
    case actionTypes.GET_QUERIES_REJECTED:
      return { ...state, isLoadingQueries: false };

    case actionTypes.GET_RECEIPTS_EMPLOYEE:
      return { ...state, isLoadingReceipts: true };
    case actionTypes.GET_RECEIPTS_EMPLOYEE_FULFILLED:
      return {
        ...state,
        isLoadingReceipts: false,
        receipts: action.payload.receipts,
      };
    case actionTypes.GET_RECEIPTS_EMPLOYEE_REJECTED:
      return { ...state, isLoadingReceipts: false };

    case actionTypes.GET_LOCATION:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_LOCATION_FULFILLED:
      return {
        ...state,
        isLoadingLocation: false,
        counties: action.payload.counties,
        provinces: action.payload.provinces,
        regions: action.payload.regions,
      };
    case actionTypes.GET_LOCATION_REJECTED:
      return { ...state, isLoadingLocation: false };

    case actionTypes.GET_MANAGEMENT_DIVISION:
      return { ...state, isLoadingManagementDivision: true };
    case actionTypes.GET_MANAGEMENT_DIVISION_FULFILLED:
      return {
        ...state,
        isLoadingManagementDivision: false,
        departments: action.payload.departments,
        sections: action.payload.sections,
      };
    case actionTypes.GET_MANAGEMENT_DIVISION_REJECTED:
      return { ...state, isLoadingManagementDivision: false };

    case actionTypes.GET_DEPARTMENTS_BY_MANAGEMENT:
      return { ...state, isLoadingManagementDivision: true };
    case actionTypes.GET_DEPARTMENTS_BY_MANAGEMENT_FULFILLED: {
      return {
        ...state,
        isLoadingManagementDivision: false,
        departments: action.payload,
        sections: [],
      };
    }
    case actionTypes.GET_DEPARTMENTS_BY_MANAGEMENT_REJECTED: {
      return {
        ...state,
        isLoadingManagementDivision: false,
        departments: [],
        sections: [],
      };
    }

    case actionTypes.GET_SECTIONS_BY_DEPARTMENT:
      return { ...state, isLoadingManagementDivision: true };
    case actionTypes.GET_SECTIONS_BY_DEPARTMENT_FULFILLED: {
      return {
        ...state,
        isLoadingManagementDivision: false,
        sections: action.payload,
      };
    }
    case actionTypes.GET_SECTIONS_BY_DEPARTMENT_REJECTED: {
      return {
        ...state,
        isLoadingManagementDivision: false,
        sections: [],
      };
    }

    case actionTypes.LIKE_REQUEST: {
      const likeEmployeeData = likeEmployee(state.employees, action.payload.id, action.payload.like, "CODIGO DE EMPLEADO");

      return {
        ...state,
        employees: likeEmployeeData.updatedEmployees,
        employeeOpinion: likeEmployeeData.opinion,
        isLoading: true,
      };
    }
    case actionTypes.LIKE_REQUEST_END:
      return { ...state, isLoading: false };

    case actionTypes.SEND_MAIL: {
      return { ...state, isLoadingMail: true };
    }
    case actionTypes.SEND_MAIL_FULFILLED: {
      return { ...state, isLoadingMail: false };
    }
    case actionTypes.SEND_MAIL_REJECTED: {
      return { ...state, isLoadingMail: false };
    }

    case actionTypes.MASSIVE_CHANGE:
      return { ...state, isLoading: true };
    case actionTypes.MASSIVE_CHANGE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.MASSIVE_CHANGE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPLOAD:
      return { ...state, isLoading: true };
    case actionTypes.UPLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.UPLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD_FAMILY_GROUP:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FAMILY_GROUP_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_FAMILY_GROUP_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPLOAD_FAMILY_GROUP:
      return { ...state, isLoading: true };
    case actionTypes.UPLOAD_FAMILY_GROUP_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.UPLOAD_FAMILY_GROUP_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_ONBOARDING_ASSIGNMENT:
      return { ...state, isLoading: true };
    case actionTypes.GET_ONBOARDING_ASSIGNMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        onboardingProcesses: action.payload.onboardingProcesses,
      };
    case actionTypes.GET_ONBOARDING_ASSIGNMENT_REJECTED:
      return { ...state, isLoading: false };

    // TEMPLATES
    case actionTypes.GET_TEMPLATES:
      return { ...state, isLoadingTemplates: true };
    case actionTypes.GET_TEMPLATES_FULFILLED:
      return {
        ...state,
        templates: action.payload.templates,
        isLoadingTemplates: false,
      };
    case actionTypes.GET_TEMPLATES_REJECTED:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.CREATE_TEMPLATE_REQUEST: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.CREATE_TEMPLATE_REQUEST_END:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.DELETE_TEMPLATE_REQUEST: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.DELETE_TEMPLATE_REQUEST_END:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.ADD_TEMPLATE: {
      let arr2 = state.templates.length > 0 ? [...state.templates] : [];
      arr2.push(action.payload);
      return { ...state, templates: arr2 };
    }
    case actionTypes.UPDATE_TEMPLATE: {
      let modifiedTemplates = [...state.templates].map((item, index) => {
        if (item.Code === action.payload.Code) {
          item.U_columnstate = JSON.stringify(action.payload.U_columnstate);
          item.U_filterstate = JSON.stringify(action.payload.U_filterstate);
          item.U_sortstate = JSON.stringify(action.payload.U_sortstate);
          item.U_groupstate = JSON.stringify(action.payload.U_groupstate);
          item.U_pivotstate = JSON.stringify(action.payload.U_pivotstate);
          item.U_selstate = JSON.stringify(action.payload.U_selstate);
          item.U_graphicstate = JSON.stringify(action.payload.U_graphicstate);
        }
        return item;
      });

      return { ...state, templates: modifiedTemplates };
    }
    case actionTypes.REMOVE_TEMPLATE: {
      let arrFinal = [...state.templates].filter(field => field.Code !== action.payload);
      return { ...state, templates: arrFinal };
    }

    default:
      return state;
  }
}