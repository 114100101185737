import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDateFromDDMMYYYY, formatDateYYYYMMDD, dateIsAfterToday } from "@icarius-utils/date";
import {
  getProvinceByRegion,
  getRegionByCountry,
  getCountiesByProvince,
  getDepartmentsByManagement,
  getSectionsByDepartment,
} from "@icarius-pages/myPeople/actions";
import { CREATE_DEFAULT_VALUES, myPeopleAndPeopleMasterFields } from "@icarius-pages/myPeople/constants";
import { getAdditionalValidationData } from "@icarius-pages/myPeople/selectors";
import { getCountry } from "src/app/selectors";
import { getRutIsValid } from "@icarius-utils/types";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { TABS } from "@icarius-utils/constants";

const getNewAge = (birthDate) => {
  if (dateIsAfterToday(birthDate)) return "";
  if (!birthDate || !(birthDate instanceof Date && !isNaN(birthDate))) return "";
  let diff_ms = Date.now() - birthDate.getTime();
  let age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, editableFields, isDuplicate, assumeEntryDate) => {

  const dispatch = useDispatch();
  const validationData = useSelector(getAdditionalValidationData);
  const country = useSelector(getCountry);

  const isCreate = !Boolean(data);

  const INTEGER_FIELDS = editableFields?.filter((item) => item.type === "integer").map((item) => item.name);
  const FLOAT_FIELDS = editableFields?.filter((item) => item.type === "float").map((item) => item.name);
  const DATE_FIELDS = editableFields?.filter((item) => item.type === "date").map((item) => item.name);
  const OPTIONS_FIELDS = editableFields?.filter((item) => item.type === "options").map((item) => item.name);
  const REQUIRED_FIELDS = editableFields?.filter((item) => item.required).map((item) => item.name);
  const EXCLUDE_CONTRATIST_FIELDS = editableFields?.filter((item) => item.exclude_contratist).map((item) => item.name);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      const fieldConfig = editableFields?.find((item) => item.name === fieldName);
      const fieldValue = Boolean(fieldConfig?.is_user_field) ? data?.user_fields?.[fieldName] : data?.[fieldName];

      if (fieldName === "PERSONAL DE PLANTA") return fieldValue || "No";

      // si no hay editableFields es readOnly (o sea MyPeople) y uso el valor que recibo tal cual está
      if (!editableFields?.length) return fieldValue;

      if ((isCreate || isDuplicate)) {
        const defaultValue = CREATE_DEFAULT_VALUES.find((item) => item.name === fieldName);
        if (defaultValue) return defaultValue.value;
      }

      if (DATE_FIELDS.includes(fieldName)) {
        if (!fieldValue) return null;
        return createDateFromDDMMYYYY(fieldValue);
      }

      if (INTEGER_FIELDS.includes(fieldName)) {
        if (fieldValue === null || fieldValue === undefined || fieldValue === "" || fieldValue === 0) return "";
        return fieldValue;
      }

      if (FLOAT_FIELDS.includes(fieldName)) {
        if (fieldValue === null || fieldValue === undefined || fieldValue === "" || fieldValue === 0) return "";
        return fieldValue;
      }

      if (OPTIONS_FIELDS.includes(fieldName)) {
        if (isCreate && fieldName === "PAIS") return country;

        // si no tiene ningun dato, devolver vacio
        if (!fieldValue) {
          // si no tiene value, fijarme si la opcion vacio es con "-" o "" y devolver esa
          if (fieldConfig?.options?.some((item) => item.id === "-")) return "-";
          return "";
        }

        // si es solo lectura, dejar el value, no el id
        if (!fieldConfig?.editable) return fieldValue;

        // si el value es "-" retornar eso
        if (fieldValue === "-") return "-";

        return fieldConfig?.options?.find((item) => item.value === fieldValue)?.id || "";
      }

      if (fieldValue) return fieldValue;
      return "";
    }

    const fieldNames = [
      ...myPeopleAndPeopleMasterFields,
      ...(editableFields?.filter((item) => item.is_user_field) || []), // agregar los userFields
    ]
      .filter((item) => editableFields ? true : !item.hideInMyPeople) // si no tiene editableFields, mostrar los que no se ocultan en myPeople
      .map((item) => item.name);

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(() => createInitialData());
  const [fieldsWithError, setFieldsWithError] = useState([]);

  const validateFields = (fieldsToValidate) => {
    if (editableFields) {
      const editables = editableFields.filter((item) => fieldsToValidate.includes(item.name) && item.editable);
      let isValid = true;
      const auxFieldsWithErrors = [];

      editables.forEach((editableField) => {
        let value = formData[editableField.name];
        // si no tiene valor
        if ((!value && value !== 0) || value?.length === 0 || /^\s+$/.test(value)) {
          if (getIsRequired(editableField.name)) {
            auxFieldsWithErrors.push(editableField.name)
            isValid = false;
          }
        } else {
          //si tiene valor, pregunto por min y max segun el type
          switch (editableField.type) {
            case "text":
              if (editableField.max && value.length > editableField.max) {
                auxFieldsWithErrors.push(editableField.name)
                isValid = false;
              }

              break;
            case "integer":
            case "float":
              if (editableField.max && value > editableField.max) {
                auxFieldsWithErrors.push(editableField.name)
                isValid = false;
              }

              if (editableField.min !== null && value < editableField.min) {
                auxFieldsWithErrors.push(editableField.name)
                isValid = false;
              }
              break;

            case "date":
              if (!(value instanceof Date && !isNaN(value))) {
                auxFieldsWithErrors.push(editableField.name)
                isValid = false;
              } else {
                if (editableField.min) {
                  if (value < new Date(formatDateYYYYMMDD(editableField.min))) {
                    auxFieldsWithErrors.push(editableField.name)
                    isValid = false;
                  }
                }

                if (editableField.max) {
                  if (value > new Date(formatDateYYYYMMDD(editableField.max))) {
                    auxFieldsWithErrors.push(editableField.name)
                    isValid = false;
                  }
                }
              }
              break;
            case "options":
              const optionOk = editableField?.options?.find((item) => item.id === value);
              if (!optionOk) {
                auxFieldsWithErrors.push(editableField.name)
                isValid = false;
              }
              break;
            default: break;
          }
        }
      });

      setFieldsWithError(auxFieldsWithErrors);
      return isValid;
    }

    return true;
  };

  const getIsRequired = (fieldName) => {
    if (!editableFields?.length) return false; // es readOnly

    if (REQUIRED_FIELDS.includes(fieldName)) {
      if (EXCLUDE_CONTRATIST_FIELDS.includes(fieldName)) {
        if (!formData["CONTRATISTA"] || formData["CONTRATISTA"] === '-') return true;
        return false;
      }
      return true;
    }

    // si FORMA DE PAGO ES "D" (DEPOSITO), BANCO, CUENTA BANCARIA y TIPO DE CUENTA BANCARIA son required
    if (["BANCO", "CUENTA BANCARIA", "TIPO DE CUENTA BANCARIA"].includes(fieldName) && formData["FORMA DE PAGO"] === "D") return true;

    // si es FECHA DE EGRESO (Fecha termino contrato), y TIPO DE CONTRATO en temporary_contract_codes, es OBLIGATORIO
    if (fieldName === "FECHA DE EGRESO" && validationData?.temporary_contract_codes?.includes(formData["TIPO DE CONTRATO"])) return true;

    // si tiene cargo, fecha asignacion cargo es required
    if (fieldName === "FECHA ASIGNACION CARGO" && formData["CARGO"]) return true;

    // si tiene institucion de prevision, fecha afiliacion previsional es required
    if (fieldName === "FECHA AFILIACION PREVISIONAL" && formData["INSTITUCION DE PREVISION"] && formData["INSTITUCION DE PREVISION"] !== "-") return true;

    // si MESES ADICIONAL VACACIONES tiene valor, FECHA ADICIONAL VACACIONES es OBLIGATORIO
    if (fieldName === "FECHA ADICIONAL VACACIONES" && formData["MESES ADICIONAL VACACIONES"] && formData["MESES ADICIONAL VACACIONES"] !== "0") return true;

    //caso extra, si NO es required, pero es PORCENTAJE ZONA EXTREMA, y REBAJA ZONA EXTREMA es Y, es required
    if (fieldName === "PORCENTAJE ZONA EXTREMA" && formData["REBAJA ZONA EXTREMA"] === "Y") return true;

    //caso extra, si NO es required, pero es VALOR COTIZADO EN OTRO PAIS, y IMPONE EN OTRO PAIS es Y, es required
    if (fieldName === "VALOR COTIZADO EN OTRO PAIS" && formData["IMPONE EN OTRO PAIS"] === "Y") return true;

    // si tiene TURNO, FECHA ASIGNACION TURNO, TIPO DE CONTROL HORARIO, TIPO DE CALENDARIO, y TURNO tiene valor, es required.
    if (["FECHA ASIGNACION TURNO", "TIPO DE CONTROL HORARIO", "TIPO DE CALENDARIO"].includes(fieldName) && formData["TURNO"] && formData["TURNO"] !== "-") return true;

    return false;
  }

  const stepIsValid = (step) => {
    const stepFields = (() => {
      if (step === TABS.others) return editableFields.filter((item) => item.is_user_field)
      return myPeopleAndPeopleMasterFields.filter((item) => item.tab === step);
    })();

    const fieldsAreValid = validateFields(stepFields.map((item) => item.name))

    if (!fieldsAreValid) {
      invalidDataCallback();
      return false;
    }

    if (step === TABS.personal) {
      if (country === 'CL') {
        if (!getRutIsValid(formData['CODIGO DE EMPLEADO'])) {
          dispatch(openSnackbarAction({ msg: 'El RUT ingresado no es válido', severity: "error" }));
          return false;
        }
      }
    }

    return true;
  }

  const dataIsValid = () => {
    // valido todos de nuevo
    return (
      stepIsValid(TABS.personal) &&
      stepIsValid(TABS.location) &&
      stepIsValid(TABS.contract) &&
      stepIsValid(TABS.remuneration) &&
      stepIsValid(TABS.time) &&
      stepIsValid(TABS.specialClassification) &&
      stepIsValid(TABS.others)
    );
  }

  const getEmptyValue = (type) => {
    switch (type) {
      case "text": return "";
      case "integer": return 0;
      case "float": return 0;
      case "options": return "";
      case "date": return null;
      default: return "";
    }
  }

  const submit = () => {
    if (dataIsValid()) {
      let hasUserDefinedFields = false;
      let dataToSend = {};
      let user_defined_fields = {};

      editableFields.forEach((item) => {

        //chequear si el elemento es user_field, si lo es, se lo agrega a un objeto aparte (user_defined_fields) y luego se lo agrega a submittedData, 
        //sino, se lo agrega a submittedData directamente

        if (item.is_user_field) {
          hasUserDefinedFields = true;
          user_defined_fields[item.name] = (formData[item.name] || formData[item.name] === 0) ? formData[item.name] : getEmptyValue(item.type);

          // si es fecha formatearlo
          if (item.type === "date" && user_defined_fields[item.name]) {
            user_defined_fields[item.name] = formatDateYYYYMMDD(user_defined_fields[item.name]);
          }
        } else {
          dataToSend[item.name] = (formData[item.name] || formData[item.name] === 0) ? formData[item.name] : getEmptyValue(item.type);

          // si es fecha formatearlo
          if (item.type === "date" && dataToSend[item.name]) {
            dataToSend[item.name] = formatDateYYYYMMDD(dataToSend[item.name]);
          }
        }
      });

      //si no hay user fields, el objeto directamente no se agrega
      if (hasUserDefinedFields) {
        dataToSend["user_defined_fields"] = user_defined_fields;
      }

      // no esta en editableFields asi que lo tengo que poner aca
      dataToSend["PERSONAL DE PLANTA"] = formData["PERSONAL DE PLANTA"];

      // dato hardcodeado...
      dataToSend["PUEDE CAPTURAR GEOPOSICION"] = "N";

      (isCreate || isDuplicate) ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (["APELLIDO PATERNO", "APELLIDO MATERNO", "PRIMER NOMBRE", "SEGUNDO NOMBRE"].includes(fieldName)) {
      const fatherSurname = fieldName === "APELLIDO PATERNO" ? value : formData["APELLIDO PATERNO"];
      const motherSurname = fieldName === "APELLIDO MATERNO" ? value : formData["APELLIDO MATERNO"];
      const firstName = fieldName === "PRIMER NOMBRE" ? value : formData["PRIMER NOMBRE"];
      const secondName = fieldName === "SEGUNDO NOMBRE" ? value : formData["SEGUNDO NOMBRE"];

      setFormData({
        ...formData,
        [fieldName]: value,
        "APELLIDO Y NOMBRE": `${fatherSurname}${motherSurname ? ` ${motherSurname}` : ''}, ${firstName}${secondName ? ` ${secondName}` : ''}`,
      })
      return;
    }

    if ("TIPO DE FUNCIONARIO" === fieldName) {
      setFormData({
        ...formData,
        [fieldName]: value,
        "PERSONAL DE PLANTA": validationData?.permanent_servant_ids?.includes(value) ? "Si" : "No",
      })
      return;
    }

    if ("TIPO DE CONTRATO" === fieldName) {
      setFormData({
        ...formData,
        [fieldName]: value,
        "TIPO DE CONTRATACION": validationData?.contract_types?.find(item => item.code === value)?.value || "-",
      })
      return;
    }

    if ("FORMA DE PAGO" === fieldName && value !== 'D') {
      setFormData({
        ...formData,
        [fieldName]: value,
        "BANCO": "",
        "CUENTA BANCARIA": "",
        "TIPO DE CUENTA BANCARIA": "",
      })
      return;
    }

    if ("REBAJA ZONA EXTREMA" === fieldName && value === 'N') {
      setFormData({
        ...formData,
        [fieldName]: value,
        "PORCENTAJE ZONA EXTREMA": 0,
      })
      return;
    }

    if ("IMPONE EN OTRO PAIS" === fieldName && value === 'N') {
      setFormData({
        ...formData,
        [fieldName]: value,
        "VALOR COTIZADO EN OTRO PAIS": 0,
      })
      return;
    }

    if ("FECHA DE NACIMIENTO" === fieldName) {
      setFormData({
        ...formData,
        [fieldName]: value,
        "EDAD": getNewAge(value),
      })
      return;
    }

    if ("FECHA DE INGRESO" === fieldName) {
      if (isCreate && assumeEntryDate) {
        setFormData({
          ...formData,
          [fieldName]: value,
          "FECHA DE ANTIGUEDAD": value,
          "FECHA ASIGNACION CARGO": value,
          "FECHA ASIGNACION FUNCION": value,
          "FECHA ASIGNACION TURNO": value,
          "FECHA ASIGNACION LUGAR DE TRABAJO": value,
          "FECHA ASIGNACION DIVISION": value,
          "FECHA ASIGNACION CENTRO DE COSTO": value,
        })
        return;
      }

      // si no tiene fecha de antiguedad, setearle la fecha de ingreso
      let newSeniorityDate = formData["FECHA DE ANTIGUEDAD"];

      // si tiene fecha invalida, seteo la de ingreso
      if (!(newSeniorityDate instanceof Date && !isNaN(newSeniorityDate))) {
        newSeniorityDate = value;
      }

      setFormData({
        ...formData,
        [fieldName]: value,
        "FECHA DE ANTIGUEDAD": newSeniorityDate,
      })
      return;
    }

    if ("PAIS" === fieldName) {
      if (value === "AR") dispatch(getProvinceByRegion(value));
      if (value !== "AR") dispatch(getRegionByCountry(value));

      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
        "PROVINCIA": "",
        "REGION": "",
      })
      return;
    }

    if ("REGION" === fieldName) {
      if (value) dispatch(getProvinceByRegion(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
        "PROVINCIA": "",
      })
      return;
    }

    if ("PROVINCIA" === fieldName) {
      if (value) dispatch(getCountiesByProvince(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
      })
      return;
    }

    if ("GERENCIA" === fieldName) {
      if (value) dispatch(getDepartmentsByManagement(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "SECCION": "",
        "DEPARTAMENTO": "",
      })
      return;
    }

    if ("DEPARTAMENTO" === fieldName) {
      if (value) dispatch(getSectionsByDepartment(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "SECCION": "",
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepIsValid,
    getIsRequired,
    fieldsWithError,
  };
}

export default useHandleForm;