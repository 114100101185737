import React from "react";
import { Grid } from "@material-ui/core";
import DynamicInput from "@icarius-common/dynamicInputs";
import StepContainer from "./stepContainer";

const Others = (props) => {
    const {
        editableFields,
        formData,
        setFormValue,
        getIsRequired,
        fieldsWithError,
    } = props;

    const userFields = editableFields?.filter((item) => item.is_user_field);

    return (
        <StepContainer title={'Otros'}>
            {
                userFields?.map((fieldConfig) => {
                    return (
                        <Grid
                            container item alignItems="center" xs={12} md={6}
                            key={fieldConfig.name}
                        >
                            <DynamicInput
                                fieldConfig={fieldConfig}
                                label={fieldConfig.name}
                                value={formData[fieldConfig.name]}
                                required={getIsRequired(fieldConfig.name)}
                                error={Boolean(fieldsWithError?.includes(fieldConfig.name))}
                                handleChange={setFormValue}
                            />
                        </Grid>
                    )
                })
            }
        </StepContainer>
    )
}

export default Others;