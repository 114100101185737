import { useEffect, useRef, useState } from "react";
import {
  getTemplateRows,
  createNewTemplateWithJSON,
} from "../../actions";
import {
  getTemplatesRows,
} from "../../selectors";
import { getLoginResponse } from "@icarius-pages/login/selectors";
import { tooltipOptions, setChartType } from "@icarius-table/utils";
import { getLocalizedString, getLocalizedErrorString, } from "@icarius-localization/strings";
import { templatesOptions } from "@icarius-utils/constants";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { exportIcariusFile, importIcariusFile } from "@icarius-utils/exportImportIcarius";
import { useDispatch, useSelector } from "react-redux";

const useTemplates = () => {

  const [template, setTemplate] = useState("");
  const [templateSeleccionado, setTemplateSeleccionado] = useState(null);
  const [showOnlyCurrentEmployees, setShowOnlyCurrentEmployees] = useState(true);
  const [templateDialogIsOpen, setTemplateDialogIsOpen] = useState(false);
  const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false);
  const [createTemplateDialogIsOpen, setCreateTemplateDialogIsOpen] = useState(false);

  const currentChartRef = useRef(null);

  const dispatch = useDispatch();
  const templates = useSelector(getTemplatesRows);
  const loginResponse = useSelector(getLoginResponse);

  let user = loginResponse.user || {};

  useEffect(() => {
    dispatch(getTemplateRows());
  }, [dispatch])

  const handleOpenTemplateDialog = () => setTemplateDialogIsOpen(true);
  const handleCloseTemplateDialog = () => setTemplateDialogIsOpen(false);
  const handleOpenUpdateDialog = () => setUpdateDialogIsOpen(true);
  const handleCloseUpdateDialog = () => setUpdateDialogIsOpen(false);
  const handleOpenCreateTemplateDialog = () => setCreateTemplateDialogIsOpen(true);
  const handleCloseCreateTemplateDialog = () => setCreateTemplateDialogIsOpen(false);

  const handleImportTemplates = (gridRef) => {
    importIcariusFile(dispatch, (parsedObject) => {
      uploadTemplateToServer(parsedObject, gridRef);
    });
  }

  const getRole = (role) => {
    const foundItem = templatesOptions.find((t) => t.code === role);
    if (foundItem) return foundItem.label;
    return "";
  }

  const uploadTemplateToServer = (parsedObject, gridRef) => {
    dispatch(createNewTemplateWithJSON(parsedObject))
      .then(response => {
        if (response.data.status === "ERROR_DUPLICADO") {
          dispatch(openSnackbarAction({ msg: getLocalizedErrorString("templateAlreadyExists"), severity: "error" }));
        } else {
          dispatch(getTemplateRows());

          handleSelectAfterCreate(response.data.result.Code, templates);
          handleSelectPlanillaClick({ target: { value: response.data.result.Code } }, templates, gridRef);
          dispatch(openSnackbarAction({ msg: getLocalizedString("templateImport"), severity: "success" }));
        }
      })
      .catch(() => {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      });
  }

  const handleExportTemplates = () => {
    exportIcariusFile(dispatch, templateSeleccionado, templateSeleccionado["U_template"]);
  }

  const handleSelectPlanillaClick = (e, templates, gridRef) => {
    const tipoPlantilla = templates.find(o => o.Code === e.target.value);

    clearChart(gridRef);

    if (tipoPlantilla) {
      setTemplate(e.target.value);

      handleSwitchClickVariable(tipoPlantilla.U_selstate, gridRef);
      setTemplateSeleccionado(tipoPlantilla);

      gridRef.columnApi.resetColumnState();
      gridRef.columnApi.setColumnState(JSON.parse(tipoPlantilla.U_columnstate));
      gridRef.columnApi.setColumnGroupState(
        JSON.parse(tipoPlantilla.U_groupstate)
      );
      gridRef.columnApi.applyColumnState({
        state: JSON.parse(tipoPlantilla.U_columnstate),
        applyOrder: true,
      });
      gridRef.api.setFilterModel(JSON.parse(tipoPlantilla.U_filterstate));
      gridRef.columnApi.setPivotMode(JSON.parse(tipoPlantilla.U_pivotstate));
      gridRef.api.onFilterChanged();
      gridRef.api.onSortChanged();

      if (tipoPlantilla.U_graphicstate) {
        let chartModel = JSON.parse(tipoPlantilla.U_graphicstate);
        chartModel.cellRange.rowEndIndex = 0;
        chartModel.cellRange.rowEndIndex = gridRef.api.getDisplayedRowCount() || chartModel.cellRange.rowEndIndex;

        let optionsFromDB = chartModel.chartOptions;
        let createRangeChartParams = {
          cellRange: chartModel.cellRange,
          chartType: chartModel.chartType,
          chartPalette: chartModel.chartPalette,
          processChartOptions: function (params) {
            let options = optionsFromDB;
            setChartType(params.type);
            options.seriesDefaults.tooltip.renderer = tooltipOptions;

            return options;
          }
        };
        currentChartRef.current = gridRef.api.createRangeChart(createRangeChartParams);
      }
    } else {
      setTemplate("");
      handleSwitchClickVariable(true, gridRef);
      setTemplateSeleccionado(null);
      gridRef.columnApi.resetColumnState();
      gridRef.columnApi.resetColumnGroupState();
      gridRef.columnApi.setPivotMode(false);
      gridRef.api.setFilterModel(null);
      gridRef.columnApi.applyColumnState({
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null,
          rowGroup: null,
          pivot: null,
          pinned: null
        }
      });
      if (currentChartRef.current) {
        currentChartRef.current.destroyChart();
        currentChartRef.current = null;
      }

      if (showOnlyCurrentEmployees === true) {
        // Get a reference to the name filter instance
        let filterInstance = gridRef.api.getFilterInstance("PERSONAL DE PLANTA");

        // Set the model for the filter
        if (filterInstance) {
          filterInstance.setModel({
            type: "set",
            values: ["Si"],
          });
        }

        // Get a reference to the name filter instance
        let filterInstanceVigencia = gridRef.api.getFilterInstance("ESTADO");

        // Set the model for the filter
        if (filterInstanceVigencia) {
          filterInstanceVigencia.setModel({
            type: "set",
            values: ["Vigente"],
          });
        }
      }
      gridRef.api.onFilterChanged();
      gridRef.api.onSortChanged();
    }
  };

  const clearChart = (gridRef) => {
    if (currentChartRef.current) {
      currentChartRef.current.destroyChart();
      currentChartRef.current = null;
    }

    gridRef.api.chartService.activeCharts.forEach(item => item.destroyChart());

    gridRef.columnApi.resetColumnState();
    gridRef.columnApi.resetColumnGroupState();
    gridRef.columnApi.setPivotMode(false);
    gridRef.api.setFilterModel(null);
    gridRef.columnApi.applyColumnState({
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null,
        rowGroup: null,
        pivot: null,
        pinned: null
      }
    });
  }

  const handleSelectAfterCreate = (code, templates) => {
    const tipoPlantilla = (templates || []).find(o => o.Code === code);

    if (tipoPlantilla) {
      setTemplateSeleccionado(tipoPlantilla);
      setTemplate(tipoPlantilla.Code);
    }
  };

  const handleConfirmDelete = (gridRef) => {
    setTemplate("");
    handleSwitchClickVariable(true, gridRef);
    setTemplateSeleccionado(null);
    gridRef.columnApi.resetColumnState();
    gridRef.columnApi.resetColumnGroupState();
    gridRef.columnApi.setPivotMode(false);
    gridRef.api.setFilterModel(null);
    gridRef.columnApi.applyColumnState({
      defaultState: {
        state: null,
        // important to say 'null' as undefined means 'do nothing'
        sort: null,
        rowGroup: null,
        pivot: null,
        pinned: null
      }
    });
    clearChart(gridRef);
    if (showOnlyCurrentEmployees === true) {
      // Get a reference to the name filter instance
      let filterInstance = gridRef.api.getFilterInstance("PERSONAL DE PLANTA");

      // Set the model for the filter
      if (filterInstance) {
        filterInstance.setModel({
          type: "set",
          values: ["Si"],
        });
      }

      // Get a reference to the name filter instance
      let filterInstanceVigencia = gridRef.api.getFilterInstance("ESTADO");

      // Set the model for the filter
      if (filterInstanceVigencia) {
        filterInstanceVigencia.setModel({
          type: "set",
          values: ["Vigente"],
        });
      }
    }
    gridRef.api.onFilterChanged();
    gridRef.api.onSortChanged();
  };

  const handleClickDeleteTemplate = () => {
    handleOpenTemplateDialog();
  };

  const handeClickUpdateTemplate = () => {
    handleOpenUpdateDialog();
  };

  const handleSwitchClickVariable = (value, gridRef) => {
    if (value === true) {
      // setLabelSwitch(getLocalizedString("activeAndPlantPersonnel"));

      // Get a reference to the name filter instance
      let filterInstance = gridRef.api.getFilterInstance("PERSONAL DE PLANTA");

      // Set the model for the filter
      if (filterInstance) {
        filterInstance.setModel({
          type: "set",
          values: ["Si"],
        });
      }

      // Get a reference to the name filter instance
      let filterInstanceVigencia = gridRef.api.getFilterInstance("ESTADO");

      // Set the model for the filter
      if (filterInstanceVigencia) {
        filterInstanceVigencia.setModel({
          type: "set",
          values: ["Vigente"],
        });
      }

      // Get grid to run filter operation again
      gridRef.api.onFilterChanged();
    } else {
      // setLabelSwitch(getLocalizedString("everyone"));
      // Get a reference to the name filter instance
      let filterInstance = gridRef.api.getFilterInstance("PERSONAL DE PLANTA");

      // Set the model for the filter
      if (filterInstance) {
        filterInstance.setModel(null);
      }

      // Get a reference to the name filter instance
      let filterInstanceVigencia = gridRef.api.getFilterInstance("ESTADO");

      if (filterInstanceVigencia) {
        filterInstanceVigencia.setModel(null);
      }

      // Get grid to run filter operation again
      gridRef.api.onFilterChanged();
    }
    setShowOnlyCurrentEmployees(value);
  };

  const state = {
    template,
    templateSeleccionado,
    showOnlyCurrentEmployees,
    templateDialogIsOpen,
    updateDialogIsOpen,
    createTemplateDialogIsOpen,
  };

  const handlers = {
    handleSwitchClickVariable,
    handeClickUpdateTemplate,
    handleClickDeleteTemplate,
    handleConfirmDelete,
    handleSelectAfterCreate,
    clearChart,
    handleSelectPlanillaClick,
    handleExportTemplates,
    uploadTemplateToServer,
    getRole,
    handleImportTemplates,
    handleCloseCreateTemplateDialog,
    handleOpenCreateTemplateDialog,
    handleCloseUpdateDialog,
    handleOpenUpdateDialog,
    handleCloseTemplateDialog,
    handleOpenTemplateDialog,
  };

  return {
    user,
    templates,
    state,
    handlers,
  }
}

export default useTemplates;