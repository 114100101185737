import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveMarkingSystemChangeAction } from "@icarius-pages/myPeople/actions";
import { getEditableFields } from "../../selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const addNoChangeOption = (array) => {
  return [{ key: "-", value: 'No aplicar cambios' }, ...array];
}

const MarkingSystemDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    fieldsToHide,
    handleAfterSubmit,
    handleClose,
  } = props;

  const [mobileMark, setMobileMark] = useState('-');
  const [icariusMark, setIcariusMark] = useState('-');
  const [markWithAlert, setMarkWithAlert] = useState('-');

  const editableFields = useSelector(getEditableFields);

  const mobileArray = addNoChangeOption(editableFields.find(item => item.name === 'PUEDE MARCAR DESDE MOVILES').options.map((item) => ({ key: item.id, value: item.value })));

  const icariusArray = addNoChangeOption(editableFields.find(item => item.name === 'PUEDE MARCAR DESDE ICARIUS').options.map((item) => ({ key: item.id, value: item.value })));

  const markArray = addNoChangeOption(editableFields.find(item => item.name === 'MARCAR CON AVISO PROGRAMADO').options.map((item) => ({ key: item.id, value: item.value })));

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(massiveMarkingSystemChangeAction({
      codes: employees.map(item => item.code),
      mobileMark,
      icariusMark,
      captureGeoposition: 'N',
      markWithAlert,
    }))
      .then((resp) => {
        if (resp.status === 200) {
          handleAfterSubmit(resp?.data?.showAlert);
        }
        handleClose();
      });
  };


  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio sistema de marcaje"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-mobile`}>{"Puede marcar desde móviles"}</InputLabel>
                <Select
                  value={mobileMark}
                  labelId={`label-mobile`}
                  id={`select-mobile`}
                  onChange={(e) => setMobileMark(e.target.value)}
                  margin={"none"}
                >
                  {
                    mobileArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-ICARIUS`}>{"Puede marcar desde ICARIUS"}</InputLabel>
                <Select
                  value={icariusMark}
                  labelId={`label-ICARIUS`}
                  id={`select-ICARIUS`}
                  onChange={(e) => setIcariusMark(e.target.value)}
                  margin={"none"}
                >
                  {
                    icariusArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              !fieldsToHide.includes("MARCAR CON AVISO PROGRAMADO") &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-mark`}>{"Marcar con aviso programado"}</InputLabel>
                  <Select
                    value={markWithAlert}
                    labelId={`label-mark`}
                    id={`select-mark`}
                    onChange={(e) => setMarkWithAlert(e.target.value)}
                    margin={"none"}
                  >
                    {
                      markArray.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {`¿Está seguro que quiere cambiar los datos del tipo de marcaje para ${employees.length} colaborador/es?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default MarkingSystemDialog;
