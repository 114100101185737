import React from "react";
import { Grid } from "@material-ui/core";
import DynamicInput from "@icarius-common/dynamicInputs";
import { TABS } from "@icarius-utils/constants";
import StepContainer from "./stepContainer";

const Location = (props) => {
    const {
        editableFields,
        formData,
        setFormValue,
        getIsRequired,
        fieldsToUse,
        fieldsWithError,
        fieldsToHide,
    } = props;

    const sectionFields = fieldsToUse.filter((item) => {
        if (item.tab === TABS.location) return editableFields ? true : !item.hideInMyPeople;
        return false;
    });

    return (
        <StepContainer title={'Ubicación y datos organizacionales'}>
            {
                [0, 1].map((column) => {
                    const fieldsByColumn = sectionFields.filter((item) => item.column === column);

                    return (
                        <Grid item xs={12} md={6} key={column}>
                            {
                                fieldsByColumn.map((item) => {
                                    const fieldConfig = editableFields?.find((field) => field.name === item.name);

                                    if (fieldsToHide.includes(item.name)) return null;

                                    return (
                                        <Grid
                                            key={item.name}
                                            container item alignItems="center" xs={12}
                                            style={{ paddingBottom: 15 }}
                                        >
                                            <DynamicInput
                                                fieldConfig={fieldConfig}
                                                label={item.label}
                                                value={formData[item.name]}
                                                required={getIsRequired(item.name)}
                                                error={Boolean(fieldsWithError?.includes(item.name))}
                                                readOnlyType={item.readOnlyType}
                                                handleChange={setFormValue}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    )
                })
            }
        </StepContainer>
    )
}

export default Location;