import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import { massiveWorkingDayAction } from "@icarius-pages/myPeople/actions";
import { getEditableFields } from "../../selectors";
import Loader from "@icarius-common/loader";

const gridStyle = { margin: 10 };

const WorkingDayDialog = (props) => {

  const {
    open,
    isLoading,
    employees,
    handleAfterSubmit,
    handleClose
  } = props;

  const [type, setType] = useState('');
  const [hoursDay, setHoursDay] = useState('');
  const [hoursWeek, setHoursWeek] = useState('');
  const [hoursMonth, setHoursMonth] = useState('');

  const editableFields = useSelector(getEditableFields);
  const typesArray = editableFields
    .find(item => item.name === 'TIPO DE JORNADA').options
    .map(item => ({ key: item.id, value: item.value }))
    .filter(item => item.key !== '-');

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (type && hoursDay && hoursMonth) {
      if (hoursDay >= 0 && hoursMonth >= 0) {
        dispatch(massiveWorkingDayAction({
          codes: employees.map(item => item.code),
          type,
          hoursDay,
          hoursWeek,
          hoursMonth,
        }))
          .then((resp) => {
            if (resp.status === 200) {
              handleAfterSubmit(resp?.data?.showAlert);
            }
            handleClose();
          });
      } else {
        dispatch(openSnackbarAction({ msg: 'Los campos numéricos deben tener valores positivos', severity: "warning" }));
        return;
      }
    } else {
      dispatch(openSnackbarAction({ msg: 'Todos los campos son obligatorios', severity: "warning" }));
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Cambio de jornada laboral"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de jornada"}</InputLabel>
                <Select
                  value={type}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setType(e.target.value)}
                  margin={"none"}
                >
                  {
                    typesArray.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required
                label={'Horas diarias contrato'}
                fullWidth
                value={hoursDay}
                type={'number'}
                inputProps={{ min: "1", step: "0.05" }}
                margin={"none"}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setHoursDay(e.target.value);
                  }
                }}
                onInput={e => {
                  setHoursDay(e.target.value);
                }}
                helperText={'Debe ser número positivo'}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required
                label={'Horas semanales contrato'}
                fullWidth
                value={hoursWeek}
                type={'number'}
                inputProps={{ min: "1", step: "0.05" }}
                margin={"none"}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setHoursWeek(e.target.value);
                  }
                }}
                onInput={e => {
                  setHoursWeek(e.target.value);
                }}
                helperText={'Debe ser número positivo'}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required
                label={'Horas mensuales contrato'}
                fullWidth
                value={hoursMonth}
                type={'number'}
                inputProps={{ min: "1", step: "0.05" }}
                margin={"none"}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    setHoursMonth(e.target.value);
                  }
                }}
                onInput={e => {
                  setHoursMonth(e.target.value);
                }}
                helperText={'Debe ser número positivo'}
              />
            </Grid>
          </Grid>
          <Typography className="whiteText" style={{ margin: 10, fontWeight: 500 }}>
            {`¿Está seguro que quiere cambiar los datos de la jornada laboral para ${employees.length} colaborador/es?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default WorkingDayDialog;
